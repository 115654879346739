import { Box, Header, ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";

import TakeAction from "../components/TakeAction";
import HowWeCalculate from "../components/HowWeCalculate";
import RiskDetailHeader from "../components/RiskDetailHeader";
import GeographyDescription from "../components/GeographyDescription";
import RiskDetailComponent from "../components/RiskDetail";
import Map from "../components/Map";
import { ComparisonSwitcher } from "../components/ComparisonSwitcher";
import GlossaryComponent from "../components/GlossaryComponent";
import AppFooter from "../components/AppFooter";
import BoundaryLegend from "../components/BoundaryLegend";
import { Screen } from "../models";
import { RiskParams } from "../hooks";
import { makeResponsiveValue } from "../utils";
import { horizontalPadding } from "../constants";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  font-size: 1.5rem !important;
  border-bottom: 1px solid #e9e5e2;
  z-index: 20;
`;

const DetailScreen = ({
  screen,
  geography,
  detailGeography,
  detailGeographyId,
  countyGeographies,
  stateGeography,
  geographyLevel,
  ranks,

  // Comparison-related params
  effectiveComparisonLevel,
  comparisonCountyName,
  comparisonStateName,
  mapStyleStateAbbrev,

  // Map params
  showPopulatedAreaMask,

  // Risk factor data params
  riskReductionZone,
  riskToHomes,
  vulnerablePopulations,
  wildfireLikelihood
}: RiskParams) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <ScreenContainer>
            {"resource" in geography ? (
              <>
                <StyledHeader
                  align={size === "small" ? "start" : undefined}
                  direction={size === "small" ? "column" : "row"}
                  wrap={true}
                  gap="none"
                  pad={makeResponsiveValue(size, [
                    { left: horizontalPadding, right: horizontalPadding, top: "10px", bottom: "0" },
                    {
                      left: horizontalPadding,
                      right: horizontalPadding,
                      top: "10px",
                      bottom: "small"
                    },
                    { left: "0px", right: "0px", top: "small", bottom: "small" }
                  ])}
                >
                  <Box className="set-width-content-container">
                    <Box
                      direction={size === "small" ? "column" : "row"}
                      fill={true}
                      justify="between"
                    >
                      <GeographyDescription
                        countyDescNames={
                          countyGeographies ? countyGeographies.map(c => c.name_short) : []
                        }
                        stateDescName={stateGeography ? stateGeography.name : ""}
                        geographyLevel={geographyLevel}
                        detailPlaceId={detailGeographyId}
                        detailPlaceName={detailGeography ? detailGeography.name_short : ""}
                      />

                      <Box
                        direction="row"
                        gap="medium"
                        pad={
                          size === "small"
                            ? { top: "medium", bottom: "medium" }
                            : { top: "xsmall", bottom: "xsmall" }
                        }
                      >
                        {(screen === Screen.WildfireLikelihood ||
                          screen === Screen.RiskToHomes) && (
                          <Box align="start" justify="center">
                            <ComparisonSwitcher
                              comparisonLevel={effectiveComparisonLevel}
                              geographyLevel={geographyLevel}
                              comparisonCountyName={comparisonCountyName}
                              comparisonStateName={comparisonStateName}
                            />
                          </Box>
                        )}
                        <Box align="end" justify="center">
                          <GlossaryComponent displayIcon={true} lightBackground={true} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </StyledHeader>
                <Box
                  className="restrict-growth-content-container"
                  margin={size !== "large" ? "0px" : "0 auto"}
                  pad={
                    size === "large"
                      ? { left: "0px", right: "0px" }
                      : { left: horizontalPadding, right: horizontalPadding }
                  }
                >
                  <Box height={{ min: size === "small" ? "auto" : "140px" }}>
                    <RiskDetailHeader
                      geography={detailGeography ? detailGeography.name_short : ""}
                      detailGeographyId={detailGeographyId}
                      vulnerablePopulations={vulnerablePopulations}
                      screen={screen}
                      geographyLevel={geographyLevel}
                      comparisonCountyName={comparisonCountyName}
                      comparisonStateName={comparisonStateName}
                      comparisonLevel={effectiveComparisonLevel}
                      rrz={riskReductionZone}
                      ranks={ranks}
                    />
                  </Box>
                  {detailGeography && (
                    <Box
                      width="100%"
                      background="white"
                      style={{
                        gridColumn: size === "large" ? "span 2" : ""
                      }}
                    >
                      <Box height={{ min: "530px" }}>
                        <Map
                          geographyLevel={geographyLevel}
                          detailPlaceId={detailGeographyId}
                          geography={detailGeography}
                          mapStyleStateAbbrev={mapStyleStateAbbrev}
                          stateGeography={stateGeography}
                          screen={screen}
                          showPopulatedAreaMask={showPopulatedAreaMask}
                          mapContainerId="map-container-risk-to-homes"
                          displayControlsAndLabels={true}
                          selectedBaseMapLayer={vulnerablePopulations.selectedBaseMapLayer}
                          vulnerablePopulations={vulnerablePopulations}
                        />
                      </Box>
                      <BoundaryLegend screen={screen} geographyLevel={geographyLevel} />
                    </Box>
                  )}
                  <Box
                    height={{ min: size === "small" ? "auto" : "470px" }}
                    direction={size === "small" ? "column" : "row"}
                  >
                    <RiskDetailComponent
                      comparisonLevel={effectiveComparisonLevel}
                      comparisonAbbr={mapStyleStateAbbrev ? mapStyleStateAbbrev : "US"}
                      comparisonCountyName={comparisonCountyName}
                      comparisonStateName={comparisonStateName}
                      detailPlaceId={detailGeographyId}
                      geography={detailGeography ? detailGeography.name_short : ""}
                      geographyLevel={geographyLevel}
                      screen={screen}
                      rrz={riskReductionZone}
                      riskToHomes={riskToHomes}
                      vulnerablePopulations={vulnerablePopulations}
                      wildfireLikelihood={wildfireLikelihood}
                    />
                  </Box>
                  <Box flex={{ grow: 1 }} height={{ min: "371px" }}>
                    <TakeAction screen={screen} />
                  </Box>
                  <Box flex={{ grow: 1 }}>
                    <HowWeCalculate screen={screen} />
                  </Box>
                </Box>
              </>
            ) : "errorMessage" in geography ? (
              geography.errorMessage
            ) : null}
          </ScreenContainer>
          <AppFooter />
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default DetailScreen;
