import {
  Anchor,
  Box,
  BoxProps,
  Button,
  Drop,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from "grommet";
import { Close, FormPreviousLink, Search } from "grommet-icons";
import { Node } from "htmlparser2";
import React, { createRef, RefObject, useMemo, useState } from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { connect } from "react-redux";
import styled from "styled-components";

import { GlossaryEntry } from "../models";
import { State } from "../reducers";
import { GlossaryState } from "../reducers/glossary";
import QuestionIcon from "../icons/QuestionIcon";
import store from "../store";
import { setGlossaryTerm, toggleGlossary } from "../actions/glossaryControl";

const DropStyled = styled(Drop)`
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  width: 45rem !important;
  max-height: none !important;
`;

interface StateProps {
  readonly glossaryResource: GlossaryState;
  readonly selectedTerm: string;
  readonly openDrop: boolean;
}

interface Props {
  readonly displayIcon: boolean;
  readonly lightBackground?: boolean;
}

const GlossaryComponent = ({
  glossaryResource,
  selectedTerm,
  openDrop,
  displayIcon,
  lightBackground,
  ...props
}: StateProps & Props & BoxProps) => {
  const [boxRef] = useState(createRef() as RefObject<HTMLDivElement>);
  // Use `closeGlossary` and `toggleGlossaryOpen` instead of `setOpenDrop` directly
  // to open or close the glossary, in order to clear the initial state when it closes.
  const [searchTerm, setSearchTerm] = useState("");

  const closeGlossary = () => {
    onTermSelected("");
    store.dispatch(toggleGlossary(false));
  };

  const openGlossary = () => {
    store.dispatch(toggleGlossary(true));
  };

  // If there's a term selected but the glossary isn't open (e.g. the term is coming from a
  // page tooltip), open it.
  selectedTerm && (openDrop || openGlossary());

  // Search and open/close are handled with component state, but selected term is in app state
  // to facilitate setting it from elsewhere in the app.
  const setSelectedTerm = (term: string) => {
    store.dispatch(setGlossaryTerm(term));
  };

  const glossary = "resource" in glossaryResource ? glossaryResource.resource.glossary : undefined;
  const currentTerm = selectedTerm && glossary ? glossary.terms[selectedTerm] : undefined;
  const title = glossary ? glossary.title : "Glossary";

  const termSet: Set<string> = useMemo(() => {
    const terms = glossary
      ? Object.entries(glossary.terms).map(([key, ge]: readonly [string, GlossaryEntry]) => {
          return key;
        })
      : undefined;
    return new Set(terms);
  }, [glossary]);

  const getSuggestions = () => {
    const terms = glossary ? Object.entries(glossary.terms) : undefined;
    return searchTerm && terms
      ? terms
          .filter(([key, term]: readonly [string, GlossaryEntry]) =>
            term.label.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map(([key, term]: readonly [string, GlossaryEntry]) => ({
            label: <span>{term.label}</span>,
            value: key
          }))
      : [];
  };

  const onTermSelected = (term: string) => {
    // check if passed term exists in the glossary before attempting to display it
    termSet.has(term) ? setSelectedTerm(term) : setSelectedTerm("");
    setSearchTerm("");
  };

  // Helper for react-html-parser tranformer to add properties to links
  const transformLinks: any = (node: Node, idx: number) => {
    // Open real (external) links in a new tab
    // tslint:disable-next-line no-if-statement
    if (node.attribs) {
      // tslint:disable-next-line no-object-mutation
      node.attribs.target = "_blank";
      // tslint:disable-next-line no-object-mutation
      node.attribs.rel = "noopener noreferrer";
    }
    convertNodeToElement(node, idx, htmlParserTransform);
  };

  // Custom tranformation method for react-html-parser to handle linking within the glossary
  const htmlParserTransform: any = (node: Node, idx: number) => {
    const isLink = node.type === "tag" && node.name === "a";
    const dataLink = node.attribs ? node.attribs["data-link"] : "";

    // Handle links within the glossary by changing the currently selected term
    return isLink && dataLink ? (
      <a
        key={idx}
        href={"!#"}
        onClick={event => {
          event.preventDefault();
          onTermSelected(dataLink);
        }}
      >
        {node.children && (
          <span>{node.children.map(n => convertNodeToElement(n, idx, htmlParserTransform))}</span>
        )}
      </a>
    ) : isLink && !dataLink ? (
      transformLinks(node, idx)
    ) : (
      convertNodeToElement(node, idx, htmlParserTransform)
    );
  };

  const termsList = () => {
    const terms = glossary ? glossary.terms : undefined;
    const onAutocompleteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
    };

    return (
      <Box pad="small" align="start">
        <Text>{glossary ? glossary.description : ""}</Text>
        <hr className="GlossaryHeader-divider" />
        <Box id="glossarySearchBox" style={{ display: "none" }}>
          <Button id="glossarySearchBoxIcon" icon={<Search />} />
          <TextInput
            id="glossaryInput"
            onChange={onAutocompleteChange}
            placeholder=""
            suggestions={getSuggestions()}
            onSelect={({ suggestion }) =>
              suggestion ? onTermSelected(suggestion.value) : setSearchTerm("")
            }
          />
        </Box>
        <ul style={{ fontSize: "1.5rem" }}>
          {terms &&
            Object.entries(terms)
              .sort(([_1, term1], [_2, term2]) => term1.label.localeCompare(term2.label))
              .map(([key, term]: readonly [string, GlossaryEntry]) => {
                return (
                  <li key={key}>
                    <Anchor
                      key={key}
                      color="heading"
                      style={{ textDecoration: "underline" }}
                      label={term.label}
                      onClick={() => {
                        onTermSelected(key);
                      }}
                    />
                  </li>
                );
              })}
        </ul>
      </Box>
    );
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box flex={true} align="center" justify="center">
          <Box ref={boxRef} onClick={openGlossary} direction="row" align="center" gap="xsmall">
            {displayIcon ? <QuestionIcon color="#666" size="small" /> : ""}
            <Text color={lightBackground ? "#666" : "#fff"}>Glossary</Text>
          </Box>

          {openDrop && (
            <DropStyled
              target={boxRef.current as HTMLDivElement}
              onClickOutside={closeGlossary}
              onEsc={closeGlossary}
              background="white"
            >
              <Box>
                <Box direction="row">
                  <Box flex="grow" direction="row">
                    {selectedTerm && (
                      <Button onClick={() => onTermSelected("")}>
                        <Box
                          direction="row"
                          align="center"
                          gap="xsmall"
                          style={{ marginLeft: "1rem", marginRight: "-1rem" }}
                        >
                          <FormPreviousLink />
                        </Box>
                      </Button>
                    )}
                    <Heading
                      level="4"
                      margin="small"
                      alignSelf="start"
                      color="heading"
                      style={{ fontWeight: 900, display: "inline" }}
                    >
                      {title}
                    </Heading>
                  </Box>
                  <Button alignSelf="end" onClick={closeGlossary} color="heading">
                    <Close
                      size="14px"
                      color="heading"
                      style={{ right: "1rem", top: "-0.8rem", position: "relative" }}
                    />
                  </Button>
                </Box>
                {currentTerm && (
                  <Box pad="small">
                    <Heading
                      margin={{ top: "0", left: "0", right: "0", bottom: "1rem" }}
                      level="3"
                      size="xlarge"
                      color="heading"
                    >
                      {currentTerm.label}
                    </Heading>
                    <Text>
                      {ReactHtmlParser(currentTerm.shortDefinition, {
                        transform: htmlParserTransform
                      })}
                    </Text>
                    <Text>
                      {ReactHtmlParser(currentTerm.definition, { transform: htmlParserTransform })}
                    </Text>
                  </Box>
                )}
                {!selectedTerm.length && termsList()}
              </Box>
            </DropStyled>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  glossaryResource: state.glossary,
  selectedTerm: state.glossaryControl.term,
  openDrop: state.glossaryControl.open
});

export default connect(mapStateToProps)(GlossaryComponent);
