import React from "react";
import { Text } from "grommet";
import GlossaryLink from "./GlossaryLink";
import { GeographyLevel, Screen } from "../models";

interface Props {
  readonly screen: Screen;
  readonly comparisonGeographyName: string;
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly percentileRank: number;
  readonly quantityLabel: JSX.Element;
}

export const PercentileComparisonText = ({
  comparisonGeographyName,
  geography,
  geographyLevel,
  percentileRank,
  quantityLabel,
  screen
}: Props) => {
  const formattedGeographyLevel = geographyLevel.replace("_", " ");

  const geographyTypeLabel =
    comparisonGeographyName && geographyLevel === GeographyLevel.community ? (
      <GlossaryLink term="communities">{formattedGeographyLevel}</GlossaryLink>
    ) : comparisonGeographyName && geographyLevel === GeographyLevel.tribal_area ? (
      <>
        <GlossaryLink term="tribal_areas">tribal areas</GlossaryLink> and counties
      </>
    ) : (
      `${formattedGeographyLevel}`
    );

  return (
    <Text size="large">
      {percentileRank > 5 ? (
        <>
          {screen === Screen.RiskToHomes ? (
            <>
              <GlossaryLink term="homes">Homes</GlossaryLink> in {geography} have
            </>
          ) : (
            `${geography} has`
          )}
          , on average, greater {quantityLabel} than {percentileRank}% of {geographyTypeLabel}
          {comparisonGeographyName ? ` in ${comparisonGeographyName}` : ""}
        </>
      ) : (
        <>
          {screen === Screen.RiskToHomes ? (
            <>
              <GlossaryLink term="homes">Homes</GlossaryLink> in nearly
            </>
          ) : (
            "Nearly"
          )}{" "}
          all other {comparisonGeographyName} {geographyTypeLabel}, on average, have greater{" "}
          {quantityLabel} than {screen === Screen.RiskToHomes && "in"} {geography}
        </>
      )}
      .
    </Text>
  );
};
