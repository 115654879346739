import { err, JsonDecoder, ok } from "ts.data.json";
import {
  CountyGeography,
  RRZ,
  RRZDataSet,
  Geography,
  GeographyVulnerablePopulationData,
  Glossary,
  GlossaryEntry,
  RiskToHomes,
  RiskToHomesDataSet,
  StateGeography,
  VulnerablePopulationsDataSet,
  WildfireLikelihood,
  WildfireLikelihoodDataSet,
  VulnerablePopulationMetricType,
  VulnerablePopulationTractInfo,
  VulnerablePopulationTractDictionary,
  VulnerablePopulationNameAndData,
  ValueMoEPctPctMoE,
  RiskClassLevel,
  Ranks
} from "./models";

// From example at https://github.com/joanllenas/ts.data.json#jsondecoderallof
const hasLength = (len: number) =>
  new JsonDecoder.Decoder<ReadonlyArray<any>>((json: any) => {
    return (json as readonly any[]).length === len
      ? ok<readonly any[]>(json)
      : err<readonly any[]>(`Array length is not ${len}`);
  });

const bboxDecoder = JsonDecoder.allOf(
  JsonDecoder.array<number>(JsonDecoder.number, "BoundingBox"),
  hasLength(4)
);

export const rrzDataSetDecoder = JsonDecoder.object<RRZDataSet>(
  {
    fraction_ie: JsonDecoder.optional(JsonDecoder.number),
    fraction_de: JsonDecoder.optional(JsonDecoder.number),
    fraction_me: JsonDecoder.optional(JsonDecoder.number),
    fraction_wtz: JsonDecoder.optional(JsonDecoder.number),
    total_buildings: JsonDecoder.optional(JsonDecoder.number),
    buildings_fraction_me: JsonDecoder.optional(JsonDecoder.number),
    buildings_fraction_ie: JsonDecoder.optional(JsonDecoder.number),
    buildings_fraction_de: JsonDecoder.optional(JsonDecoder.number)
  },
  "RRZDataSet"
);

export const rrzDecoder = JsonDecoder.object<RRZ>(
  {
    d: rrzDataSetDecoder
  },
  "RRZ"
);

export const rrzCollectionDecoder = JsonDecoder.dictionary(rrzDecoder, "RRZCollection");

export const vulnerablePopulationsDataSetDecoder = JsonDecoder.object<VulnerablePopulationsDataSet>(
  {
    HH: JsonDecoder.optional(JsonDecoder.number),
    HH_dsc: JsonDecoder.optional(JsonDecoder.number),
    HOUS_MOBILES: JsonDecoder.optional(JsonDecoder.number),
    HOUS_MOBILES_dsc: JsonDecoder.optional(JsonDecoder.number),
    HOUS_NOCAR: JsonDecoder.optional(JsonDecoder.number),
    HOUS_NOCAR_dsc: JsonDecoder.optional(JsonDecoder.number),
    LANG_POP: JsonDecoder.optional(JsonDecoder.number),
    LANG_POP_dsc: JsonDecoder.optional(JsonDecoder.number),
    LANG_ENGNOTPROF_PAR: JsonDecoder.optional(JsonDecoder.number),
    LANG_ENGNOTPROF_PAR_dsc: JsonDecoder.optional(JsonDecoder.number),
    POP_COHORT23: JsonDecoder.optional(JsonDecoder.number),
    POP_COHORT23_dsc: JsonDecoder.optional(JsonDecoder.number),
    POP_NON_INST: JsonDecoder.optional(JsonDecoder.number),
    POP_NON_INST_dsc: JsonDecoder.optional(JsonDecoder.number),
    POP_DISABLED: JsonDecoder.optional(JsonDecoder.number),
    POP_DISABLED_dsc: JsonDecoder.optional(JsonDecoder.number),
    POV_FAM: JsonDecoder.optional(JsonDecoder.number),
    POV_FAM_dsc: JsonDecoder.optional(JsonDecoder.number),
    POV_FAMBELOW: JsonDecoder.optional(JsonDecoder.number),
    POV_FAMBELOW_dsc: JsonDecoder.optional(JsonDecoder.number),
    POP: JsonDecoder.optional(JsonDecoder.number),
    POP_dsc: JsonDecoder.optional(JsonDecoder.number)
  },
  "VulnerablePopulationsDataSet"
);

const ranksDecoder = JsonDecoder.object<Ranks>(
  {
    rps_within_nation: JsonDecoder.number,
    bp_within_nation: JsonDecoder.number,
    rps_within_state: JsonDecoder.optional(JsonDecoder.number),
    bp_within_state: JsonDecoder.optional(JsonDecoder.number),
    rps_within_county: JsonDecoder.optional(JsonDecoder.number),
    bp_within_county: JsonDecoder.optional(JsonDecoder.number)
  },
  "Ranks"
);

const countyDecoder = JsonDecoder.object<CountyGeography>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    name_short: JsonDecoder.string
  },
  "CountyGeography"
);

const stateDecoder = JsonDecoder.object<StateGeography>(
  {
    abrv: JsonDecoder.string,
    bounds: JsonDecoder.optional(bboxDecoder),
    name: JsonDecoder.string
  },
  "StateGeography"
);

// Decoders for data obtained from
// https://api-dev.headwaterseconomics.org/wrc/#get-vulnerable-populations-data
export const vulnerablePopulationMetricTypeDecoder =
  JsonDecoder.oneOf<VulnerablePopulationMetricType>(
    [
      JsonDecoder.isExactly("minority"),
      JsonDecoder.isExactly("no_car"),
      JsonDecoder.isExactly("not_good_english"),
      JsonDecoder.isExactly("family_poverty"),
      JsonDecoder.isExactly("under_5"),
      JsonDecoder.isExactly("over_65"),
      JsonDecoder.isExactly("disabled"),
      JsonDecoder.isExactly("mobile")
    ],
    "VulnerablePopulationMetricType"
  );

export const valueMoEPctPctMoEDecoder = JsonDecoder.object<ValueMoEPctPctMoE>(
  {
    value: JsonDecoder.optional(JsonDecoder.number),
    moe: JsonDecoder.optional(JsonDecoder.number),
    pct: JsonDecoder.optional(JsonDecoder.number),
    pct_moe: JsonDecoder.optional(JsonDecoder.number),
    pct_median: JsonDecoder.optional(JsonDecoder.number)
  },
  "valueMoEPctPctMoEDecoder"
);

export const geographyVulnerablePopulationDataDecoder =
  JsonDecoder.object<GeographyVulnerablePopulationData>(
    {
      minority: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      no_car: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      not_good_english: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      family_poverty: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      under_5: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      over_65: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      disabled: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      mobile: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      population: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      pop_black: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      pop_ind: JsonDecoder.optional(valueMoEPctPctMoEDecoder),
      pop_hisp: JsonDecoder.optional(valueMoEPctPctMoEDecoder)
    },
    "GeographyVulnerablePopulationData"
  );

export const tractDictionaryDecoder = JsonDecoder.dictionary<VulnerablePopulationNameAndData>(
  JsonDecoder.object(
    {
      name: JsonDecoder.string,
      data: geographyVulnerablePopulationDataDecoder
    },
    "TractObjectDecoder"
  ),
  "TractDictDecoder"
);

// The API wraps some of the data we want to use inside "data" keys. Adding `map(obj => obj.data)`
// allows us to pull out the inner data and make the type-checker happy without having to declare
// intermediate types to model the nesting.
export const vulnerablePopulationTractInfoDecoder = JsonDecoder.object(
  {
    data: JsonDecoder.object<VulnerablePopulationTractInfo>(
      {
        variablesRanked: JsonDecoder.array<VulnerablePopulationMetricType>(
          vulnerablePopulationMetricTypeDecoder,
          "VulnerablePopulationMetricType"
        ),
        geos: JsonDecoder.dictionary(
          JsonDecoder.oneOf<
            GeographyVulnerablePopulationData | VulnerablePopulationTractDictionary
          >(
            [tractDictionaryDecoder, geographyVulnerablePopulationDataDecoder],
            "TractOrGeoDecoder"
          ),
          "GeosDecoder"
        )
      },
      "GeographyData"
    )
  },
  "VulnerablePopulationTractInfo"
).map(obj => obj.data);

const riskLevelDecoder = JsonDecoder.enumeration<RiskClassLevel>(RiskClassLevel, "RiskClassLevel");

export const geographyDecoder = JsonDecoder.object<Geography>(
  {
    bounds: bboxDecoder,
    breadcrumb: JsonDecoder.string,
    counties: JsonDecoder.optional(JsonDecoder.array<CountyGeography>(countyDecoder, "Counties")),
    expanded_bounds: JsonDecoder.optional(bboxDecoder),
    geo_level: JsonDecoder.string,
    name: JsonDecoder.string,
    name_short: JsonDecoder.string,
    search: JsonDecoder.optional(JsonDecoder.string),
    state: stateDecoder,
    ranks: JsonDecoder.optional(ranksDecoder),
    risk_to_homes_class: JsonDecoder.optional(riskLevelDecoder),
    wildfire_likelihood_class: JsonDecoder.optional(riskLevelDecoder),
    exposure_type_class: JsonDecoder.optional(riskLevelDecoder),
    vulnerable_populations_class: JsonDecoder.optional(riskLevelDecoder)
  },
  "Geography"
);

export const geographyListDecoder = JsonDecoder.dictionary(geographyDecoder, "GeographyList");

export const expandedAreaBoundsDecoder = JsonDecoder.dictionary(bboxDecoder, "ExpandedAreaBounds");

const glossaryEntryDecoder = JsonDecoder.object<GlossaryEntry>(
  {
    label: JsonDecoder.string,
    shortDefinition: JsonDecoder.string,
    definition: JsonDecoder.string
  },
  "GlossaryEntry"
);

export const glossaryDecoder = JsonDecoder.object<Glossary>(
  {
    title: JsonDecoder.string,
    description: JsonDecoder.string,
    terms: JsonDecoder.dictionary(glossaryEntryDecoder, "GlossaryEntries")
  },
  "Glossary"
);

export const riskToHomesDataDecoder = JsonDecoder.object<RiskToHomesDataSet>(
  {
    name: JsonDecoder.optional(JsonDecoder.string),
    total_housing_units: JsonDecoder.optional(JsonDecoder.number),
    bp_mean: JsonDecoder.optional(JsonDecoder.number),
    crps_mean: JsonDecoder.optional(JsonDecoder.number),
    rps_mean: JsonDecoder.optional(JsonDecoder.number),
    exclude_from_percentile: JsonDecoder.optional(JsonDecoder.boolean)
  },
  "RiskToHomesDataSet"
);

export const riskToHomesDecoder = JsonDecoder.object<RiskToHomes>(
  {
    d: riskToHomesDataDecoder
  },
  "RiskToHomes"
);

export const riskToHomesCollectionDecoder = JsonDecoder.dictionary(
  riskToHomesDecoder,
  "RiskToHomesCollection"
);

export const wildfireLikelihoodDataSetDecoder = JsonDecoder.object<WildfireLikelihoodDataSet>(
  {
    name: JsonDecoder.optional(JsonDecoder.string),
    total_housing_units: JsonDecoder.optional(JsonDecoder.number),
    bp_mean: JsonDecoder.optional(JsonDecoder.number),
    exclude_from_percentile: JsonDecoder.optional(JsonDecoder.boolean)
  },
  "WildfireLikelihoodDataSet"
);

export const wildfireLikelihoodDecoder = JsonDecoder.object<WildfireLikelihood>(
  {
    d: wildfireLikelihoodDataSetDecoder
  },
  "WildfireLikelihood"
);

export const wildfireLikelihoodCollectionDecoder = JsonDecoder.dictionary(
  wildfireLikelihoodDecoder,
  "WildfireLikelihoodCollection"
);
