import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const HouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="HouseIcon" viewBox="0 0 24 21" a11yTitle="House" {...props}>
      <path
        stroke="none"
        d="M 23.2109 10.5 C 23.2109 11.2031 22.625 11.75 21.9609 11.75 H 20.7109 L 20.75 18 C 20.75 18.1172 20.75 18.2344 20.75 18.3125 V 18.9375 C 20.75 19.8359 20.0469 20.5 19.1875 20.5 H 18.5625 C 18.4844 20.5 18.4453 20.5 18.4062 20.5 C 18.3672 20.5 18.2891 20.5 18.25 20.5 H 16.0625 C 15.1641 20.5 14.5 19.8359 14.5 18.9375 V 15.5 C 14.5 14.8359 13.9141 14.25 13.25 14.25 H 10.75 C 10.0469 14.25 9.5 14.8359 9.5 15.5 V 18.9375 C 9.5 19.8359 8.79688 20.5 7.9375 20.5 H 5.75 C 5.67188 20.5 5.63281 20.5 5.55469 20.5 C 5.51562 20.5 5.47656 20.5 5.4375 20.5 H 4.8125 C 3.91406 20.5 3.25 19.8359 3.25 18.9375 V 14.5625 C 3.25 14.5625 3.25 14.5234 3.25 14.4844 V 11.75 H 2 C 1.29688 11.75 0.75 11.2031 0.75 10.5 C 0.75 10.1484 0.867188 9.83594 1.14062 9.5625 L 11.1406 0.851562 C 11.4141 0.578125 11.7266 0.5 12 0.5 C 12.2734 0.5 12.5859 0.617188 12.8203 0.8125 L 22.7812 9.5625 C 23.0938 9.83594 23.25 10.1484 23.2109 10.5 Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default HouseIcon;
