import React from "react";
import { createRoot } from "react-dom/client";
import { Box, Text } from "grommet";
import styled from "styled-components";

const PopupBox = styled(Box)`
  box-shadow: 0px 0px 2px 0px #00000080;
  padding: 10px;
  border-radius: 2px;
`;

const PopupText = styled(Text)`
  color: #333333;
  font-size: 16px;
  line-height: 21px;
`;

const makeVulnerablePopulationsNoTractsPopUp = () => {
  const popUpNode = document.createElement("div");
  const reactRoot = createRoot(popUpNode);
  reactRoot.render(
    <PopupBox>
      <PopupText>
        <b>No tracts meet criteria</b>
        <br />
        Add or remove categories using
        <br />
        the checkboxes on the left.
      </PopupText>
    </PopupBox>
  );
  return popUpNode;
};

export default makeVulnerablePopulationsNoTractsPopUp;
