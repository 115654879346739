import React from "react";
import { Box, Paragraph, ResponsiveContext, Heading, Text } from "grommet";
import TakeActionIcon from "../icons/TakeActionIcon";
import { Screen, RRZClass } from "../models";

import { rrzColors } from "../constants";

interface Props {
  readonly screen: Screen;
}

const TakeAction = (props: Props) => {
  const { screen } = props;
  const text =
    screen === Screen.WildfireLikelihood ? (
      <Paragraph size="large" color="text" margin={{ vertical: "0" }} fill={true}>
        Wildfire likelihood can be difficult to modify, but communities can work to{" "}
        <a href="/reduce-risk/prevent-ignitions" target="_blank">
          prevent ignitions
        </a>{" "}
        and treat{" "}
        <a href="/reduce-risk/fuel-treatments" target="_blank">
          hazardous fuels
        </a>{" "}
        to reduce likelihood. Communities can also be better prepared for wildfires by developing{" "}
        <a href="/reduce-risk/wildfire-response" target="_blank">
          wildfire response
        </a>
        ,{" "}
        <a href="/reduce-risk/evacuation-readiness" target="_blank">
          evacuation and readiness
        </a>
        , and{" "}
        <a href="/reduce-risk/smoke-ready" target="_blank">
          smoke ready
        </a>{" "}
        strategies.
      </Paragraph>
    ) : screen === Screen.RiskReductionZones ? (
      <>
        <Box
          direction="column"
          as="section"
          fill="horizontal"
          flex={{ shrink: 0 }}
          gap="xsmall"
          pad={{ left: "small" }}
          margin={{ bottom: "medium" }}
          style={{
            borderLeft: "8px solid",
            borderColor: rrzColors[RRZClass.Indirect]
          }}
        >
          <Heading color="heading" level={3} margin={{ bottom: "0", top: "0" }} size="large">
            Indirect Exposure Zone
          </Heading>
          <Paragraph size="large" color="text" margin={{ vertical: "0" }} fill={true}>
            Reduce places for embers to land and ignite:
          </Paragraph>
          <Text
            as="ul"
            size="large"
            color="text"
            style={{ marginTop: 0, marginBottom: 0, paddingLeft: "1em" }}
          >
            <li>
              <a href="/reduce-risk/ignition-resistant-homes" target="_blank">
                Make homes ignition-resistant
              </a>
            </li>
            <li>
              <a href="/reduce-risk/ignition-resistant-homes/#hiz" target="_blank">
                Have a 5-foot noncombustible zone around homes
              </a>
            </li>
            <li>
              <a href="/reduce-risk/land-use-planning" target="_blank">
                Apply land use planning strategies
              </a>
            </li>
          </Text>
        </Box>
        <Box
          direction="column"
          as="section"
          fill="horizontal"
          flex={{ shrink: 0 }}
          gap="xsmall"
          pad={{ left: "small" }}
          margin={{ bottom: "medium" }}
          style={{
            borderLeft: "8px solid",
            borderColor: rrzColors[RRZClass.Direct]
          }}
        >
          <Heading color="heading" level={3} margin={{ bottom: "0", top: "0" }} size="large">
            Direct Exposure Zone
          </Heading>
          <Paragraph size="large" color="text" margin={{ vertical: "0" }} fill={true}>
            Reduce ways for vegetation to carry fire to homes, and reduce places for embers to land
            and ignite:
          </Paragraph>
          <Text
            as="ul"
            size="large"
            color="text"
            style={{ marginTop: 0, marginBottom: 0, paddingLeft: "1em" }}
          >
            <li>
              <a href="/reduce-risk/ignition-resistant-homes" target="_blank">
                Make homes ignition-resistant
              </a>
            </li>
            <li>
              <a href="/reduce-risk/ignition-resistant-homes/#hiz" target="_blank">
                Have a 5-foot noncombustible zone around homes
              </a>
            </li>
            <li>
              <a href="/reduce-risk/land-use-planning" target="_blank">
                Apply land use planning strategies
              </a>
            </li>
            <li>
              <a href="/reduce-risk/fuel-treatments" target="_blank">
                Reduce hazardous fuels
              </a>{" "}
              to modify fire behavior
            </li>
            <li>
              Create defensible spaces to help anchor{" "}
              <a href="/reduce-risk/wildfire-response" target="_blank">
                wildfire response
              </a>{" "}
              activities
            </li>
          </Text>
        </Box>
        <Box
          direction="column"
          as="section"
          basis="auto"
          fill={true}
          gap="xsmall"
          pad={{ left: "small" }}
          style={{
            borderLeft: "8px solid",
            borderColor: rrzColors[RRZClass.Transmission]
          }}
        >
          <Heading color="heading" level={3} margin={{ bottom: "0", top: "0" }} size="large">
            Wildfire Transmission Zone
          </Heading>
          <Paragraph size="large" color="text" margin={{ vertical: "0" }} fill={true}>
            Reduce continuity of vegetative fuels to slow fire spread:
          </Paragraph>
          <Text
            as="ul"
            size="large"
            color="text"
            style={{ marginTop: 0, marginBottom: 0, paddingLeft: "1em" }}
          >
            <li>
              <a href="/reduce-risk/fuel-treatments" target="_blank">
                Reduce hazardous fuels
              </a>{" "}
              to modify fire behavior
            </li>
            <li>
              Create defensible spaces to help anchor{" "}
              <a href="/reduce-risk/wildfire-response" target="_blank">
                wildfire response
              </a>{" "}
              activities
            </li>
          </Text>
        </Box>
      </>
    ) : screen === Screen.RiskToHomes ? (
      <Paragraph size="large" color="text" margin={{ vertical: "0" }} fill={true}>
        Communities can reduce their wildfire risk to homes by enacting{" "}
        <a href="/reduce-risk/land-use-planning" target="_blank">
          land use planning strategies
        </a>{" "}
        to make neighborhoods safer, building{" "}
        <a href="/reduce-risk/ignition-resistant-homes" target="_blank">
          ignition-resistant homes
        </a>
        , and developing{" "}
        <a href="/reduce-risk/equitable-risk-reduction" target="_blank">
          equitable risk reduction
        </a>{" "}
        strategies.
      </Paragraph>
    ) : screen === Screen.VulnerablePopulations ? (
      <Paragraph size="large" color="text" margin={{ vertical: "0" }} fill={true}>
        <a href="/reduce-risk/equitable-risk-reduction" target="_blank">
          Equitable risk reduction
        </a>{" "}
        strategies can help communities address the unique needs of socially and economically
        vulnerable neighborhoods.{" "}
        <a href="/reduce-risk/smoke-ready" target="_blank">
          Smoke ready
        </a>{" "}
        strategies,{" "}
        <a href="/reduce-risk/evacuation-readiness" target="_blank">
          evacuation planning
        </a>
        , and{" "}
        <a href="/reduce-risk/recovery" target="_blank">
          recovery
        </a>{" "}
        after a wildfire can be especially important considerations for vulnerable populations.
      </Paragraph>
    ) : (
      ""
    );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          border={{ color: "#E4E4E4", size: "1px" }}
          direction={size === "small" ? "column" : "row"}
          margin={{ top: "small", bottom: "small" }}
        >
          <Box
            width={size === "small" ? "100%" : "50%"}
            height={"auto"}
            pad={{
              top: size === "small" ? "large" : "medium",
              bottom: "none",
              horizontal: "small"
            }}
            direction="column"
            justify="start"
          >
            <Box>
              <Heading color="heading" level={2} margin={{ top: "0" }} size="xlarge">
                Take action
              </Heading>
              {text}
            </Box>
            <Box direction="row" margin={{ vertical: "40px" }} align="center">
              <TakeActionIcon color="#0095CB" size="small" />
              <Text color="#0095CB" size="large" margin={{ left: "8px" }}>
                <a href="/reduce-risk" target="_blank" style={{ textDecoration: "none" }}>
                  Find ways to reduce risk
                </a>
              </Text>
            </Box>
          </Box>
          <Box
            width={size === "small" ? "100%" : "50%"}
            height={size === "small" ? "371px" : ""}
            style={{
              background: "url(" + process.env.PUBLIC_URL + "/TakeAction-9-22.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default TakeAction;
