import React from "react";
import { Box, Text } from "grommet";
import { RasterClass } from "../models";
import styled from "styled-components";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 24rem;

  &.compact {
    width: 18rem;
    padding: 0.6rem 1rem;
  }
`;

const Item = styled(Box)`
  border-top: 0.1rem solid #d9d9d9;
  display: flex;
  flex-direction: row;
  padding: 1rem;

  &:first-child {
    border-top: none;
  }

  &.compact {
    border-top: none;
    padding: 0.5rem 0;
  }
`;

const Swatch = styled(Box)`
  background-color: ${props => props.color};
  border-radius: 0.2rem;
  flex: 0 0 0.8rem;

  &.compact {
    flex: 0 0 1.4rem;
    margin: 2px 0;
  }
`;

const TextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`;

const Label = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
  line-height: 1.8rem;
`;

const Description = styled(Text)`
  color: #555;
  font-size: 1.4rem;
  line-height: 130%;
`;

const MapClassLegend = ({
  classes,
  compact
}: {
  classes: ReadonlyArray<RasterClass>;
  compact: boolean;
}) => (
  <Container className={compact ? "compact" : ""}>
    {classes.map(({ label, color, definition }, idx) => (
      <Item key={idx} className={compact ? "compact" : ""}>
        <Swatch color={color} className={compact ? "compact" : ""} />
        <TextBox>
          <Box>
            <Label>{label}</Label>
          </Box>
          {!compact && (
            <Box pad="0.5rem 0 0 0">
              <Description>{definition}</Description>
            </Box>
          )}
        </TextBox>
      </Item>
    ))}
  </Container>
);

export default MapClassLegend;
