import React from "react";
import { Root } from "react-dom/client";
import { Box, Text } from "grommet";
import { VulnerablePopulationNameAndData } from "../models";
import styled from "styled-components";
import { Provider } from "react-redux";
import store from "../store";

const PopupBox = styled(Box)`
  box-shadow: 0px 0px 2px 0px #00000080;
  padding: 10px;
  border-radius: 2px;
`;

const TractName = styled(Text)`
  font-size: 14px;
  text-align: center;
  font-weight: 700;
`;

const SubHeader = styled(Text)`
  font-size: 14px;
  text-align: left;
  color: #555555;
  font-weight: 400;
`;

interface Props {
  readonly tract: VulnerablePopulationNameAndData;
}

const VulnerablePopulationsPopUpComponent = ({ tract }: Props) => {
  return (
    <PopupBox>
      <TractName>{tract.name}</TractName>
      <SubHeader>Click tract to see details</SubHeader>
    </PopupBox>
  );
};

const VulnerablePopulationsPopUp = (
  reactRoot: Root,
  tractData: VulnerablePopulationNameAndData
) => {
  reactRoot.render(
    <Provider store={store}>
      <VulnerablePopulationsPopUpComponent tract={tractData} />
    </Provider>
  );
};

export default VulnerablePopulationsPopUp;
