import React from "react";

const BackToOverviewButton = () => {
  return (
    <h3
      style={{
        color: "white",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "14px"
      }}
    >
      ← Back to overview
    </h3>
  );
};

export default BackToOverviewButton;
