import { Cmd, Loop, loop, LoopReducer } from "redux-loop";

import { VulnerablePopulationsAction } from "../actions";
import {
  ActionTypes,
  vulnerablePopulationsFetchSuccess,
  vulnerablePopulationsFetchFailure
} from "../actions/vulnerablePopulations";

import { fetchVulnerablePopulations } from "../api";
import { VulnerablePopulationTractInfoResource, BaseMapLayer, NormalizedTractId } from "../models";
import { Resource } from "../resource";

export interface VulnerablePopulationsState {
  readonly selectedVulnerablePopulations: ReadonlyArray<string>;
  readonly rankedVulnerablePopulations: ReadonlyArray<string>;
  readonly tractInfo: Resource<VulnerablePopulationTractInfoResource>;
  readonly selectedBaseMapLayer: BaseMapLayer;
  readonly selectedModalTract: NormalizedTractId;
  readonly selectedPanelTract: NormalizedTractId;
  readonly displayExpandedMenu: boolean;
}

export const initialState: VulnerablePopulationsState = {
  selectedVulnerablePopulations: [],
  rankedVulnerablePopulations: [],
  tractInfo: { isPending: false },
  selectedBaseMapLayer: BaseMapLayer.none,
  selectedModalTract: undefined,
  selectedPanelTract: undefined,
  displayExpandedMenu: false
};

const VulnerablePopulationsCollectionReducer: LoopReducer<
  VulnerablePopulationsState,
  VulnerablePopulationsAction
> = (
  state: VulnerablePopulationsState = initialState,
  action: VulnerablePopulationsAction
): VulnerablePopulationsState | Loop<VulnerablePopulationsState, VulnerablePopulationsAction> => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_VULNERABLE_POPULATIONS:
      return {
        ...state,
        selectedVulnerablePopulations: action.selectedVulnerablePopulations
      };
    case ActionTypes.VULNERABLE_POPULATIONS_FETCH:
      return loop(
        {
          ...state,
          tractInfo: { isPending: true },
          selectedVulnerablePopulations: [],
          rankedVulnerablePopulations: []
        },
        Cmd.run(fetchVulnerablePopulations, {
          args: [action.geoId],
          successActionCreator: vulnerablePopulationsFetchSuccess,
          failActionCreator: vulnerablePopulationsFetchFailure
        })
      );
    case ActionTypes.VULNERABLE_POPULATIONS_FETCH_SUCCESS:
      // In contrast to most other success handlers which just set the data on state and let the
      // components figure out how to display it, here we also need to fire off a new action to
      // select the top two most highly ranked vulnerable population variables.
      return loop(
        {
          ...state,
          rankedVulnerablePopulations: action.tractInfo.variablesRanked,
          tractInfo: {
            resource: {
              tractInfo: action.tractInfo
            }
          }
        },
        Cmd.action<VulnerablePopulationsAction>({
          type: ActionTypes.SET_SELECTED_VULNERABLE_POPULATIONS,
          selectedVulnerablePopulations: action.tractInfo.variablesRanked.slice(0, 2)
        })
      );
    case ActionTypes.VULNERABLE_POPULATIONS_FETCH_FAILURE:
      return {
        ...state,
        tractInfo: { errorMessage: action.errorMessage }
      };
    case ActionTypes.SET_SELECTED_BASE_MAP_LAYER:
      return {
        ...state,
        selectedBaseMapLayer: action.selectedBaseMapLayer
      };
    case ActionTypes.SET_SELECTED_MODAL_TRACT:
      return {
        ...state,
        selectedModalTract: action.selectedModalTract
      };
    case ActionTypes.SET_SELECTED_PANEL_TRACT:
      return {
        ...state,
        selectedPanelTract: action.selectedPanelTract
      };
    case ActionTypes.SET_DISPLAY_EXPANDED_MENU:
      return {
        ...state,
        displayExpandedMenu: action.displayExpandedMenu
      };
    default:
      return state;
  }
};

export default VulnerablePopulationsCollectionReducer;
