import React, { useMemo } from "react";
import { Box, Heading } from "grommet";

import { RRZDataSet, RRZClass } from "../models";
import RRZChartItem from "./RiskReductionZoneChartItem";
import RRZChartLegend from "./RiskReductionZoneChartLegend";
import { RRZStructureScale, rrzColors } from "../constants";

interface StateProps {
  readonly rrz: RRZDataSet;
  readonly size: string;
  readonly structureScale: null | RRZStructureScale;
}

interface RRZChartData {
  readonly percentage: number;
  readonly count: number;
}

const createRRZChartData = (percentage: number | undefined, total: number | undefined) => ({
  percentage: Math.round((percentage || 0) * 100),
  count: Math.round((percentage || 0) * (total || 0))
});

const RRZChart = ({ rrz, size, structureScale }: StateProps) => {
  const rrzData: Record<string, RRZChartData> = useMemo(
    () => ({
      [RRZClass.Minimal]: createRRZChartData(rrz.buildings_fraction_me, rrz.total_buildings),
      [RRZClass.Indirect]: createRRZChartData(rrz.buildings_fraction_ie, rrz.total_buildings),
      [RRZClass.Direct]: createRRZChartData(rrz.buildings_fraction_de, rrz.total_buildings)
    }),
    [rrz]
  );

  return structureScale ? (
    <Box
      flex={true}
      direction="column"
      pad={{ bottom: "4.8rem" }}
      margin={{ left: size === "small" ? "0rem" : "3rem" }}
    >
      <Box
        pad={{
          top: size === "small" ? "large" : "medium"
        }}
        border={
          size === "small"
            ? [
                {
                  color: "#D9D9D9",
                  size: "1px",
                  style: "solid",
                  side: "bottom"
                }
              ]
            : []
        }
      >
        <Heading level={2} margin={{ top: "0" }} size="xlarge" color="#333">
          Exposed buildings by zone
        </Heading>
      </Box>
      <Box
        direction={size === "small" ? "column" : "row"}
        margin={size === "small" ? { top: "1rem" } : {}}
        gap="1rem"
        aria-label="Chart of exposed buildings by zone."
        role="list"
      >
        <RRZChartItem
          label={RRZClass.Minimal}
          percentage={rrzData[RRZClass.Minimal].percentage}
          structureCount={rrzData[RRZClass.Minimal].count}
          themeColor={rrzColors[RRZClass.Minimal]}
          bgColor="rgba(185, 187, 194, 0.08)"
          structureScale={structureScale}
          size={size}
        />
        <RRZChartItem
          label={RRZClass.Indirect}
          percentage={rrzData[RRZClass.Indirect].percentage}
          structureCount={rrzData[RRZClass.Indirect].count}
          themeColor={rrzColors[RRZClass.Indirect]}
          bgColor="rgba(245, 199, 102, 0.10)"
          structureScale={structureScale}
          size={size}
        />
        <RRZChartItem
          label={RRZClass.Direct}
          percentage={rrzData[RRZClass.Direct].percentage}
          structureCount={rrzData[RRZClass.Direct].count}
          themeColor={rrzColors[RRZClass.Direct]}
          bgColor="rgba(255, 59, 84, 0.08)"
          structureScale={structureScale}
          size={size}
        />
      </Box>
      {rrz.total_buildings && (
        <Box margin={{ top: "1rem" }}>
          <RRZChartLegend structureScale={structureScale} totalStructures={rrz.total_buildings} />
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default RRZChart;
