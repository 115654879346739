import { sortedIndex } from "lodash";
import {
  RiskToHomes,
  RiskToHomesCollection,
  RiskToHomesDataSet,
  WildfireLikelihood,
  WildfireLikelihoodDataSet,
  WildfireLikelihoodCollection
} from "./models";

export const ellipsizeName = (name: string, maxLength = 30) => {
  if (name.length < maxLength) return name;

  const words = name.split(" ");

  // If the first word is already too long, elipsize it
  if (words[0].length > maxLength) {
    return words[0].slice(0, maxLength).concat("...");
  }

  // Otherwise, return the longest substring of whole words that fits within the limit
  return (
    words
      .map((_, i) => words.slice(0, i + 1).join(" "))
      .reverse()
      .find(w => w.length <= maxLength) || ""
  ).concat("...");
};

export const makeResponsiveValue = <T>(size: string, candidates: readonly T[]): T => {
  const smallCandidate = candidates[0];
  const mediumCandidate = candidates[1] ?? smallCandidate;
  const largeCandidate = candidates[2] ?? mediumCandidate;
  switch (size) {
    case "small":
      return smallCandidate;
    case "medium":
      return mediumCandidate;
    default:
      return largeCandidate;
  }
};

// see: https://stackoverflow.com/a/13627586
export const ordinal = (num: number): string => {
  const divTen = num % 10;
  const divHundred = num % 100;
  return divTen === 1 && divHundred !== 11
    ? "st"
    : divTen === 2 && divHundred !== 12
    ? "nd"
    : divTen === 3 && divHundred !== 13
    ? "rd"
    : "th";
};

// Round a number to the the given number of significant figures, i.e. keep 'digits' place values
// counting from the left, rounding the last preserved value and zeroing out any remaining
// place values. E.g.
// roundToSigFigs(1234, 2) -> 1200
// roundToSigFigs(7654, 2) -> 7700
export const roundToSigFigs = (num: number, digits: number) => {
  // This only works for positive numbers. Just return the input if it's out of bounds.
  if (num <= 0) {
    return num;
  }
  const oom = Math.floor(Math.log10(num));
  const oomMultiplier = Math.pow(10, oom - digits + 1);
  const sigFigs = Math.round(num / oomMultiplier);
  return sigFigs * oomMultiplier;
};

// set the width of the screen that the map considers "narrow" (to adjust layout appropriately).
const MAX_NARROW_WINDOW_WIDTH = 1100;

export function isWindowNarrow(width: number): boolean {
  return width <= MAX_NARROW_WINDOW_WIDTH;
}

export const getSortedValuesForKey = (
  riskToHomes: RiskToHomesCollection,
  field: keyof RiskToHomesDataSet,
  exclude: boolean = false
): readonly number[] => {
  return (
    Object.entries(riskToHomes)
      // Pull out the dataset from the RTH object
      .map(([key, rth]: readonly [string, RiskToHomes]) => {
        return rth.d;
      })
      // Remove values marked exclude_from_percentile if exclude is true
      .filter((rthData: RiskToHomesDataSet) => !exclude || !rthData.exclude_from_percentile)
      // If the field is there, return it as a number
      .map((rthData: RiskToHomesDataSet) => {
        return field in rthData ? (rthData[field] as number) : undefined;
      })
      // Filter out falsy fields (i.e. ones that weren't there. We don't expect any real values
      // to be exactly zero)
      .filter(entry => !!entry)
      // sort ascending
      .sort((a?: number, b?: number): number => {
        return a && b ? a - b : -1;
      }) as readonly number[]
  );
};

// Returns the percent rank of a given value `val` within sortedValues (or, for values that don't
// exist, what the percent rank would be, if it did exist).
export const percentRank = (val: number, sortedValues: readonly number[]): number => {
  return Math.round((sortedIndex(sortedValues, val) / (sortedValues.length - 1)) * 100) / 100;
};

export const getBpSortedValues = (
  wildfireLikelihood: WildfireLikelihoodCollection,
  exclude: boolean
): readonly number[] => {
  return (
    Object.entries(wildfireLikelihood)
      .map(([key, w]: readonly [string, WildfireLikelihood]) => {
        return w.d;
      })
      // Remove whitelisted communities from the sorted values so they don't impact the percentiles
      .filter((wData: WildfireLikelihoodDataSet) => !exclude || !wData.exclude_from_percentile)
      // Get just the value, but return -1 (an invalid probability) if the field is missing.
      // Always returning a number from this and filtering out the invalid ones below makes
      // Typescript happier than if we return (number | undefined) then filter out the undefined
      // entries.
      .map((wData: WildfireLikelihoodDataSet) => {
        return wData.bp_mean === undefined ? -1 : wData.bp_mean;
      })
      .filter(entry => entry !== -1)
      .sort((a: number, b: number): number => a - b) as readonly number[]
  );
};
