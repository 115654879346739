import React, { useState } from "react";
import { Box, Grid, Heading, Menu, ResponsiveContext, Text } from "grommet";
import CaretIcon from "../icons/CaretIcon";
import styled from "styled-components";
import { ActionDetails } from "../models";
import HouseIcon from "../icons/HouseIcon";
import EquitableRiskIcon from "../icons/EquitableRiskIcon";
import EvacuationReadinessIcon from "../icons/EvacuationReadinessIcon";
import HazardousFuelsIcon from "../icons/HazardousFuelsIcon";
import MapIcon from "../icons/MapIcon";
import PreventIgnitionsIcon from "../icons/PreventIgnitionsIcon";
import RecoveryRebuildingIcon from "../icons/RecoveryRebuildingIcon";
import ResponseIcon from "../icons/ResponseIcon";
import SmokeReadyIcon from "../icons/SmokeReadyIcon";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const ACTIONS = {
  IgnitionResistantHomes: {
    name: "Ignition-Resistant Homes",
    icon: HouseIcon,
    description: "Use wildfire-resistant building materials and landscaping.",
    url: "/reduce-risk/ignition-resistant-homes"
  },
  LandUsePlanning: {
    name: "Land Use Planning",
    icon: MapIcon,
    description:
      "Direct how communities develop in wildfire-prone areas using plans and regulations.",
    url: "/reduce-risk/land-use-planning"
  },
  EvacuationAndReadiness: {
    name: "Evacuation & Readiness",
    icon: EvacuationReadinessIcon,
    description:
      "Be ready for wildfires with community alerts, evacuation routes, and family plans.",
    url: "/reduce-risk/evacuation-readiness"
  },
  EquitableRiskReduction: {
    name: "Equitable Risk Reduction",
    icon: EquitableRiskIcon,
    description: "Address the social and economic vulnerabilities of people in the community.",
    url: "/reduce-risk/equitable-risk-reduction"
  },
  SmokeReady: {
    name: "Smoke Ready",
    icon: SmokeReadyIcon,
    description: "Prepare for the health impacts of wildfire smoke in the community. ",
    url: "/reduce-risk/smoke-ready"
  },
  PreventIgnitions: {
    name: "Prevent Ignitions",
    icon: PreventIgnitionsIcon,
    description: "Reduce ignitions from campfires, debris burning, vehicles, and other sources.",
    url: "/reduce-risk/prevent-ignitions"
  },
  Response: {
    name: "Response",
    icon: ResponseIcon,
    description: "Manage and fight wildfires to protect lives, communities, and resources.",
    url: "/reduce-risk/wildfire-response"
  },
  HazardousFuelsManagement: {
    name: "Hazardous Fuels Management",
    icon: HazardousFuelsIcon,
    description: "Cut, thin, burn, or otherwise reduce flammable vegetation on the landscape.",
    url: "/reduce-risk/fuel-treatments"
  },
  RecoveryAndRebuilding: {
    name: "Recovery & Rebuilding",
    icon: RecoveryRebuildingIcon,
    description: "Restore the landscape and community following a wildfire.",
    url: "/reduce-risk/recovery"
  }
};

const ROLES = {
  Default: {
    name: "Select a role",
    actions: []
  },
  ElectedOfficials: {
    name: "Elected Officials",
    actions: [
      ACTIONS.LandUsePlanning,
      ACTIONS.EvacuationAndReadiness,
      ACTIONS.EquitableRiskReduction,
      ACTIONS.Response,
      ACTIONS.RecoveryAndRebuilding
    ]
  },
  PlannersAndDevelopers: {
    name: "Planners & Developers",
    actions: [
      ACTIONS.IgnitionResistantHomes,
      ACTIONS.LandUsePlanning,
      ACTIONS.EvacuationAndReadiness,
      ACTIONS.EquitableRiskReduction,
      ACTIONS.RecoveryAndRebuilding
    ]
  },
  EmergencyServices: {
    name: "Emergency Services",
    actions: [
      ACTIONS.LandUsePlanning,
      ACTIONS.EvacuationAndReadiness,
      ACTIONS.EquitableRiskReduction,
      ACTIONS.SmokeReady,
      ACTIONS.Response
    ]
  },
  Homeowners: {
    name: "Homeowners",
    actions: [
      ACTIONS.IgnitionResistantHomes,
      ACTIONS.EvacuationAndReadiness,
      ACTIONS.SmokeReady,
      ACTIONS.PreventIgnitions,
      ACTIONS.RecoveryAndRebuilding
    ]
  },
  LandManagers: {
    name: "Land Managers",
    actions: [
      ACTIONS.LandUsePlanning,
      ACTIONS.EquitableRiskReduction,
      ACTIONS.PreventIgnitions,
      ACTIONS.Response,
      ACTIONS.HazardousFuelsManagement
    ]
  },
  FireCollaboratives: {
    name: "Fire Collaboratives",
    actions: [
      ACTIONS.IgnitionResistantHomes,
      ACTIONS.EvacuationAndReadiness,
      ACTIONS.SmokeReady,
      ACTIONS.Response,
      ACTIONS.HazardousFuelsManagement
    ]
  },
  HealthAndSocialServices: {
    name: "Health & Social Services",
    actions: [
      ACTIONS.EvacuationAndReadiness,
      ACTIONS.EquitableRiskReduction,
      ACTIONS.SmokeReady,
      ACTIONS.Response,
      ACTIONS.RecoveryAndRebuilding
    ]
  }
};

const ActionsContainer = styled(Box)`
  overflow: auto;
  overflow-y: auto;
  flex-shrink: 0;
  padding-top: 43px;
  padding-bottom: 80px;
`;

const MenuLabel = styled.div`
  color: #00698f;
  font-weight: bold;
  font-size: 16px;
`;

const MenuCaret = styled.div`
  margin-top: 50%;
`;

const ActionCard = styled.a`
  display: flex;
  border-radius: 3px;
  min-height: 100px;
  font-color: #555555;
  text-decoration: none;
`;

const ActionTitle = styled.div`
  margin-left: 13px;
  margin-top: 16px;
`;

const ActionText = styled.div`
  margin-left: 13px;
  margin-bottom: 13px;
  margin-right: 13px;
  font-size: 14px;
  color: #555555;
`;

interface Props {
  readonly active: boolean;
  readonly action: string;
}

const ActionCards = () => {
  const [activeRole, setActiveRole] = useState("Default");

  const setSelectedRole = (ev: React.MouseEvent, role: string) => {
    ev.stopPropagation();
    setActiveRole(role);
  };

  const RoleDropdown = () => {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box width="400px">
            <Grid columns={["95px", "auto"]} gap="small">
              <Text alignSelf="center" color={"#333333"} style={{ fontSize: "13px" }}>
                FILTER BY ROLE
              </Text>
              <Box border={{ color: "#CCCCCC", size: "1px" }} background="white" width="100%">
                <Grid columns={["auto", "20px"]}>
                  <Menu
                    items={Object.entries(ROLES)
                      .slice(1)
                      .map(([key, role]) => ({
                        label: <Text>{role.name}</Text>,
                        onClick: (event: React.MouseEvent) => {
                          logGoogleAnalyticsEvent(
                            "overview",
                            "pick your role selection",
                            role.name
                          );
                          setSelectedRole(event, key);
                        }
                      }))}
                    dropAlign={{ top: "bottom" }}
                    label={
                      <MenuLabel tabIndex={0}>
                        {ROLES[activeRole as keyof typeof ROLES].name}
                      </MenuLabel>
                    }
                    margin={size === "small" ? "0px" : "-5px"}
                    icon={false}
                    tabIndex={-1}
                  />
                  <MenuCaret>
                    <CaretIcon size="12px" color="#0095CB" />
                  </MenuCaret>
                </Grid>
              </Box>
            </Grid>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  const Card = ({ active, action }: Props) => {
    const ActionIcon = ACTIONS[action as keyof typeof ACTIONS].icon;
    return (
      <ActionCard
        href={ACTIONS[action as keyof typeof ACTIONS].url}
        style={{
          border: active ? "solid #0095CB" : "solid #c5c5c5",
          borderWidth: active ? "2px" : "1px",
          backgroundColor: active ? "rgba(229, 248, 255, 1)" : "white"
        }}
        onClick={() => {
          logGoogleAnalyticsEvent(
            "overview",
            "action card click",
            ACTIONS[action as keyof typeof ACTIONS].name
          );
        }}
      >
        <Grid rows={["auto", "auto"]}>
          <Grid columns={["30px", "auto", "auto"]} gap="xsmall">
            <Box margin={"12px"} width="35px">
              <ActionIcon color={active ? "#0095CB" : "#000000"} />
            </Box>
            <ActionTitle>
              <Text color={active ? "#00698f" : "#333333"} weight="bold" size="16px">
                {ACTIONS[action as keyof typeof ACTIONS].name}
              </Text>
            </ActionTitle>
          </Grid>
          <ActionText>{ACTIONS[action as keyof typeof ACTIONS].description}</ActionText>
        </Grid>
      </ActionCard>
    );
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <ActionsContainer gap="medium">
          <Heading color="dark-1" size="xlarge">
            Identify your most relevant actions
          </Heading>
          <Text size="large">
            Everyone has a part to play in reducing wildfire risk. Select a role to highlight tools,
            tips, and programs that can help.
          </Text>
          <RoleDropdown />
          <Grid
            columns={["auto", "auto", "auto"]}
            rows={["auto", "auto", "auto"]}
            gap="20px"
            style={{
              display: size === "small" ? "flex" : "grid",
              flexDirection: size === "small" ? "column" : "row"
            }}
          >
            {Object.entries(ACTIONS).map(([key, action]) => {
              const activeActions: ReadonlyArray<ActionDetails> =
                ROLES[activeRole as keyof typeof ROLES].actions;
              return <Card key={key} active={activeActions.includes(action)} action={key} />;
            })}
          </Grid>
        </ActionsContainer>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ActionCards;
