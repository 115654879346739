import React from "react";
import { Image, ResponsiveContext } from "grommet";
import { SwarmPlotCanvas } from "@nivo/swarmplot";
import { scaleLinear } from "d3-scale";

import {
  WildfireLikelihoodChartData,
  wildfireLikelihoodColorBreaks,
  MinimumChartBP
} from "../models";
import { ellipsizeName, ordinal } from "../utils";
import {
  Chart,
  ChartContainer,
  ChartRight,
  ChartXAxis,
  Tooltip,
  TooltipTitle,
  TooltipProperty,
  TooltipValue
} from "./Chart";
import GlossaryLink from "./GlossaryLink";

interface StateProps {
  readonly detailPlaceId: string;
  readonly geography: string;
  readonly minBubbleSize: number;
  readonly maxBubbleSize: number;
  readonly wildfireLikelihoodChartData: WildfireLikelihoodChartData;
}

const WildfireLikelihoodChart = ({
  detailPlaceId,
  geography,
  minBubbleSize,
  maxBubbleSize,
  wildfireLikelihoodChartData
}: StateProps) => {
  function generateColor(item: any): string {
    return item && item.data && item.data.color
      ? item.data.color
      : wildfireLikelihoodColorBreaks[wildfireLikelihoodColorBreaks.length - 1].value;
  }
  function generateTooltip(item: any): JSX.Element {
    const rank = Math.round(item.node.data.bpPctRank * 100);
    return (
      <Tooltip>
        <TooltipTitle>{item.node.data.name}</TooltipTitle>
        <TooltipProperty>
          {rank}
          {ordinal(rank)} percentile
        </TooltipProperty>
        <TooltipValue>Wildfire likelihood</TooltipValue>
      </Tooltip>
    );
  }
  function generateEmptyTooltip(item: any): JSX.Element {
    return <span />;
  }
  const filteredChartData = wildfireLikelihoodChartData.data.filter(
    val => val.bp_mean && val.bp_mean > MinimumChartBP
    // tslint:disable-next-line readonly-array
  ) as any[];

  const detailPlace = filteredChartData.filter(d => d.key === detailPlaceId);
  const positionAnnotation =
    detailPlace && detailPlace[0] && detailPlace[0].bpLog
      ? scaleLinear()
          .domain([wildfireLikelihoodChartData.bpMin, wildfireLikelihoodChartData.bpMax])
          .range([0, 100])(detailPlace[0].bpLog) > 50
        ? "left"
        : "right"
      : "left";
  const note = ellipsizeName(geography);

  return (
    <Chart>
      <ChartRight>
        <ChartContainer>
          <ResponsiveContext.Consumer>
            {size => (
              <SwarmPlotCanvas
                data={filteredChartData}
                height={380}
                width={size === "small" ? Math.min(window.innerWidth, 510) : 510}
                identity="key"
                groups={[""]}
                value="bpLog"
                // log scale is not supported for swarm plots, so use computed log
                valueScale={{
                  type: "linear",
                  min: wildfireLikelihoodChartData.bpMin,
                  max: wildfireLikelihoodChartData.bpMax
                }}
                size={{
                  key: "total_housing_units",
                  values: [wildfireLikelihoodChartData.teMin, wildfireLikelihoodChartData.teMax],
                  sizes: [minBubbleSize, maxBubbleSize]
                }}
                tooltip={size === "small" ? generateEmptyTooltip : generateTooltip}
                spacing={3}
                forceStrength={4}
                simulationIterations={60}
                layout="horizontal"
                gap={20}
                colors={generateColor}
                colorBy="bp_mean"
                borderWidth={0.5}
                borderColor="#000"
                margin={{
                  top: 40,
                  right: size === "small" ? 25 : 130,
                  bottom: 40,
                  left: size === "small" ? 25 : 60
                }}
                enableGridX={false}
                enableGridY={false}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                theme={{
                  // @ts-ignore
                  annotations: {
                    text: {
                      fontSize: size === "small" ? "12" : "14",
                      fontFamily: "lato, sans-serif",
                      color: "#333",
                      weight: 400,
                      fontWeight: 400
                    }
                  }
                }}
                // @ts-ignore
                annotations={[
                  {
                    type: "circle",
                    match: { id: detailPlaceId },
                    noteX: positionAnnotation === "left" ? -20 : 20,
                    noteY: -20,
                    offset: size === "small" ? 6 : 3,
                    noteTextOffset: -16,
                    // nivo doesn't do a good job handling a variety of label widths. This
                    // calculation is trying to use the number of characters in the label to
                    // figure out a size that looks good.
                    noteWidth: size === "small" ? note.length * 5 : note.length * 7,
                    note
                  }
                ]}
              />
            )}
          </ResponsiveContext.Consumer>
        </ChartContainer>
        <ChartXAxis>
          <GlossaryLink term="wildfire_likelihood">Wildfire likelihood</GlossaryLink>{" "}
          <Image
            height="9px"
            style={{ marginLeft: "4px", position: "relative", top: "1px" }}
            a11yTitle="Arrow pointing up"
            src={process.env.PUBLIC_URL + "/legend-arrow.svg"}
          />
        </ChartXAxis>
      </ChartRight>
    </Chart>
  );
};

export default WildfireLikelihoodChart;
