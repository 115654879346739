import React from "react";
import { Box, ResponsiveContext, Heading } from "grommet";
import { Screen, GeographyLevel, ComparisonLevel, Ranks } from "../models";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { RRZState } from "../reducers/riskReductionZone";
import VulnerablePopulationsComparisonText from "./VulnerablePopulationsComparisonText";
import WildfireLikelihoodComparisonText from "./WildfireLikelihoodComparisonText";
import RRZComparisonText from "./RRZComparisonText";
import RiskToHomesComparisonText from "./RiskToHomesComparisonText";

interface Props {
  readonly screen: Screen;
  readonly geography: string;
  readonly detailGeographyId: string;
  readonly vulnerablePopulations: VulnerablePopulationsState;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly rrz: RRZState;
  readonly ranks: Ranks | undefined;
}

const RiskDetailHeader = (props: Props) => {
  const {
    screen,
    geography,
    detailGeographyId,
    geographyLevel,
    vulnerablePopulations,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    rrz,
    ranks
  } = props;

  const heading =
    screen === Screen.WildfireLikelihood
      ? "Wildfire likelihood"
      : screen === Screen.RiskReductionZones
      ? "Risk Reduction Zones"
      : screen === Screen.RiskToHomes
      ? "Risk to homes"
      : screen === Screen.VulnerablePopulations
      ? "Vulnerable populations"
      : "";

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          width={"100%"}
          height={"auto"}
          pad={{
            top: size === "small" ? "large" : "medium"
          }}
          direction="column"
          justify="start"
        >
          <Box>
            <Heading color="heading" level={2} margin={{ top: "0", bottom: "20px" }} size="xxlarge">
              {heading}
            </Heading>
            {screen === Screen.WildfireLikelihood ? (
              <WildfireLikelihoodComparisonText
                geography={geography}
                geographyLevel={geographyLevel}
                comparisonCountyName={comparisonCountyName}
                comparisonStateName={comparisonStateName}
                comparisonLevel={comparisonLevel}
                ranks={ranks}
              />
            ) : screen === Screen.RiskReductionZones ? (
              <RRZComparisonText
                geography={geography}
                detailGeographyId={detailGeographyId}
                rrz={rrz}
              />
            ) : screen === Screen.RiskToHomes ? (
              <RiskToHomesComparisonText
                geography={geography}
                geographyLevel={geographyLevel}
                comparisonCountyName={comparisonCountyName}
                comparisonStateName={comparisonStateName}
                comparisonLevel={comparisonLevel}
                ranks={ranks}
              />
            ) : screen === Screen.VulnerablePopulations ? (
              <VulnerablePopulationsComparisonText
                geography={geography}
                detailGeographyId={detailGeographyId}
                vulnerablePopulations={vulnerablePopulations}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default RiskDetailHeader;
