import React from "react";
import { Anchor, Box, Footer, Grid, ResponsiveContext, Text } from "grommet";
import GlossaryComponent from "./GlossaryComponent";
import styled from "styled-components";
import CombinedLogos from "../icons/CombinedLogos";
import { horizontalPadding } from "../constants";

const FooterLogos = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const TopRowFooterLink = styled(Text)`
  color: white;
`;

const BottomRowFooterLink = styled(Text)`
  color: white;
  opacity: 70%;
  font-weight: bold;
`;

const AppFooter = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Footer
          background="black"
          height={"155px"}
          pad={
            size === "large"
              ? { top: "0", right: "0", bottom: "0", left: "0" }
              : { top: "0", right: horizontalPadding, bottom: "0", left: horizontalPadding }
          }
        >
          <Box className="set-width-content-container">
            <Anchor href="/">
              <FooterLogos>
                <CombinedLogos size="280%" />
              </FooterLogos>
            </Anchor>
            <Box>
              <Grid gap="xsmall" align="center">
                <Grid columns={["23%", "31%", "auto", "auto"]} gap="small">
                  {size === "small" ? (
                    ""
                  ) : (
                    <>
                      <Box flex={true} align="start" justify="center">
                        <Anchor className="header-footer-nav-links" href="/reduce-risk">
                          <Box direction="row" align="center">
                            <TopRowFooterLink>Take Action</TopRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="start" justify="center">
                        <Anchor className="header-footer-nav-links" href="/download">
                          <Box direction="row" align="center">
                            <TopRowFooterLink>Download Data</TopRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                    </>
                  )}
                  {size === "small" ? (
                    ""
                  ) : (
                    <Box flex={true} align="start" justify="center" style={{ fontWeight: 600 }}>
                      <GlossaryComponent displayIcon={false} />
                    </Box>
                  )}
                </Grid>
                <Grid columns={["auto", "auto", "auto", "auto"]} gap="none">
                  {size === "small" ? (
                    ""
                  ) : (
                    <>
                      <Box flex={true} align="center" justify="center" margin={{ right: "1.8rem" }}>
                        <Anchor className="header-footer-nav-links" href="/understand-risk">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>Understand Risk</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="center" justify="center" margin={{ right: "1.8rem" }}>
                        <Anchor className="header-footer-nav-links" href="/reduce-risk">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>Reduce Risk</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="center" justify="center" margin={{ right: "1.8rem" }}>
                        <Anchor className="header-footer-nav-links" href="/about">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>About</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box flex={true} align="center" justify="center">
                        <Anchor className="header-footer-nav-links" href="/contact">
                          <Box direction="row" align="center">
                            <BottomRowFooterLink>Contact</BottomRowFooterLink>
                          </Box>
                        </Anchor>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Footer>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default AppFooter;
