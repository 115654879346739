import React, { useEffect, useMemo, useState } from "react";
import { Box, Paragraph } from "grommet";

import { RRZ, RRZDataSet } from "../models";
import { RRZState } from "../reducers/riskReductionZone";
import GlossaryLink from "./GlossaryLink";

interface ComponentProps {
  readonly geography: string;
  readonly detailGeographyId: string;
  readonly rrz: RRZState;
}

// this is similar to RRZClass enum
// but should only be used in this component
// to identify source
enum RRZSource {
  Minimal = "Minimal exposure",
  Indirect = "Indirect exposure",
  Direct = "Direct exposure",
  Mix = "Mix"
}

const RRZComparisonText = (props: ComponentProps) => {
  const { geography, detailGeographyId, rrz } = props;
  const [primarySource, setPrimarySource] = useState<null | RRZSource>(null);

  const chartData: RRZDataSet = useMemo(() => {
    const noData: RRZDataSet = {
      fraction_de: 0,
      fraction_ie: 0,
      fraction_me: 0
    };
    const exp: RRZ =
      "resource" in rrz && rrz.resource.rrz[detailGeographyId]
        ? rrz.resource.rrz[detailGeographyId]
        : { d: noData };
    return exp.d;
  }, [rrz, detailGeographyId]);

  useEffect(() => {
    // Identify which of the three percentages is the largest
    const greatest = Math.max(
      chartData.buildings_fraction_de || 0,
      chartData.buildings_fraction_ie || 0,
      chartData.buildings_fraction_me || 0
    );
    switch (greatest) {
      case 0:
        break; // values not loaded yet
      case chartData.buildings_fraction_de:
        setPrimarySource(RRZSource.Direct);
        break;
      case chartData.buildings_fraction_ie:
        setPrimarySource(RRZSource.Indirect);
        break;
      case chartData.buildings_fraction_me:
        if (chartData.buildings_fraction_me && chartData.buildings_fraction_me >= 0.5) {
          setPrimarySource(RRZSource.Minimal);
        } else {
          setPrimarySource(RRZSource.Mix);
        }
        break;
      default:
        break; // shouldn't ever get here
    }
  }, [chartData]);

  return (
    <>
      {geography && (
        <Box skeleton={primarySource === null}>
          <Paragraph color="text" margin={{ top: "0", bottom: "small" }} size="large" fill={true}>
            <GlossaryLink term="homes">Homes</GlossaryLink> and other{" "}
            <GlossaryLink term="buildings">buildings</GlossaryLink> in {geography} are predominantly
            {primarySource === RRZSource.Mix
              ? " exposed to wildfire in a combination of "
              : " in the "}
            {primarySource === RRZSource.Direct ? (
              <GlossaryLink term="direct_exposure_zone">Direct Exposure Zone</GlossaryLink>
            ) : primarySource === RRZSource.Indirect ? (
              <GlossaryLink term="indirect_exposure_zone">Indirect Exposure Zone</GlossaryLink>
            ) : primarySource === RRZSource.Minimal ? (
              <GlossaryLink term="minimal_exposure_zone">Minimal Exposure Zone</GlossaryLink>
            ) : primarySource === RRZSource.Mix ? (
              <>
                <GlossaryLink term="direct_exposure_zone">Direct</GlossaryLink> and{" "}
                <GlossaryLink term="indirect_exposure_zone">Indirect Exposure</GlossaryLink> Zones
              </>
            ) : null}
            .
          </Paragraph>
        </Box>
      )}
    </>
  );
};

export default RRZComparisonText;
