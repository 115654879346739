import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const AreaIcon = (props: any) => {
  const { strokeWidth, stroke, fillOpacity } = props;
  return (
    <ThemeProvider theme={IconTheme}>
      <StyledIcon id="AreaIcon" viewBox="0 0 18 18" a11yTitle="Expand" {...props}>
        <path
          d="M3.75 5V5.75H3H0.75L0.75 17.25H9.7849L17.25 12.5843V0.75L3.75 0.75L3.75 5Z"
          fillOpacity={fillOpacity}
        />
        <path
          d="M3.75 5V5.75H3H0.75L0.75 17.25H9.7849L17.25 12.5843V0.75L3.75 0.75L3.75 5Z"
          stroke={`${stroke}`}
          strokeWidth={strokeWidth}
        />
      </StyledIcon>
    </ThemeProvider>
  );
};

export default AreaIcon;
