import React, { useMemo } from "react";
import { Box, ResponsiveContext } from "grommet";
import styled from "styled-components";
import BoundaryLegendIcon from "./BoundaryLegendIcon";
import { BoundaryLegendCategory, GeographyLevel, Screen } from "../models";
import GlossaryLink from "./GlossaryLink";

const legendItemBaseStyle = `
  display: inline-flex;
  align-items: center;
  gap: .8rem;
  font-size: 1.4rem;
`;

const BoundaryLegendItem = styled.li`
  ${legendItemBaseStyle}
  color: var(--text-800);
`;

interface Props {
  readonly screen: Screen;
  readonly geographyLevel: GeographyLevel;
}

// The label for a legend entry is in the enum value, but for RiskCalcArea we want a glossary word
const boundaryLegendLabel = (item: BoundaryLegendCategory) => {
  return item === BoundaryLegendCategory.RiskCalcArea ? (
    <GlossaryLink term="risk_calculation_area">{item}</GlossaryLink>
  ) : (
    item
  );
};

const BoundaryLegend = ({ screen, geographyLevel }: Props) => {
  const legends = useMemo(() => {
    // Some of the entries are conditional. Making lists that might or might not have members and
    // including them with ... seems cleaner than putting all the conditionality in the main list.
    const riskCalcEntry =
      (screen === Screen.RiskToHomes || screen === Screen.WildfireLikelihood) &&
      geographyLevel !== GeographyLevel.state
        ? [BoundaryLegendCategory.RiskCalcArea]
        : [];
    const tractEntries =
      screen === Screen.VulnerablePopulations
        ? [BoundaryLegendCategory.SelectedTract, BoundaryLegendCategory.Tract]
        : [];
    const items = [
      BoundaryLegendCategory.Place,
      ...riskCalcEntry,
      BoundaryLegendCategory.County,
      BoundaryLegendCategory.State,
      ...tractEntries
    ];
    return items.map(item => (
      <BoundaryLegendItem key={item}>
        <BoundaryLegendIcon category={item} />
        {boundaryLegendLabel(item)}
      </BoundaryLegendItem>
    ));
  }, [screen, geographyLevel]);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box direction="row" gap="medium" pad="1rem">
          <Box
            as="ul"
            direction="row"
            wrap={true}
            role="list"
            style={{
              margin: 0,
              padding: 0,
              gap: "1rem 2rem"
            }}
            a11yTitle="Map legend"
          >
            {legends}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default BoundaryLegend;
