import mapboxgl from "mapbox-gl";
import { createRoot } from "react-dom/client";
import { defaultLineOptions } from "./mapStyles";
import {
  GeographyVulnerablePopulationData,
  VulnerablePopulationTractDictionary,
  NormalizedTractId,
  VulnerablePopulationTractInfo
} from "../models";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import {
  onTractsMouseLeave,
  onTractsMouseOver,
  onTractsDesktopClick,
  onTractsMobileClick,
  onMapClickClosePanel,
  clearMapListeners
} from "./mapListeners";
import makeVulnerablePopulationsNoTractsPopUp from "./VulnerablePopulationsNoTractsPopUp";

import { BOUNDARY_LAYERS, OVERLAY_IDS, boundaryLayerInsertBeforeSecondary } from "./mapLayers";

const addVulnerablePopulationsMapElements = (
  map: mapboxgl.Map,
  size: string,
  vulnPopTracts: VulnerablePopulationTractDictionary,
  placeId: string,
  selectedPanelTract: NormalizedTractId,
  selectedModalTract: NormalizedTractId,
  displayExpandedMenu: boolean
) => {
  // first remove existing listeners before adding new ones
  clearMapListeners(map);
  // remove the tract popup if open, which can occur following resizing
  document.querySelectorAll(".tract-popup").forEach(p => p.remove());

  const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS.census_tracts;
  const popup = new mapboxgl.Popup({
    className: "tract-popup",
    closeButton: false,
    closeOnClick: false
  }).setMaxWidth("350px");
  const popUpNode = document.createElement("div");
  const reactRoot = createRoot(popUpNode);

  // on desktop, add listeners for popup and panel
  if (size !== "small") {
    // when panel is closed, show popup
    map &&
      !selectedPanelTract &&
      map.on("mousemove", OVERLAY_IDS.tractsLayerBaseFill, e =>
        onTractsMouseOver(
          e,
          map,
          vulnPopTracts,
          placeId,
          popUpNode,
          reactRoot,
          popup,
          mapboxTilesetId,
          sourceLayer
        )
      );
    map &&
      !selectedPanelTract &&
      map.on("mouseleave", OVERLAY_IDS.tractsLayerBaseFill, () =>
        onTractsMouseLeave(map, popup, mapboxTilesetId, sourceLayer)
      );

    // when panel is closed, open the panel when a tract is clicked
    map &&
      !selectedPanelTract &&
      map.on("click", OVERLAY_IDS.tractsLayerBaseFill, e =>
        onTractsDesktopClick(e, map, placeId, mapboxTilesetId, sourceLayer, popup)
      );

    // when panel is open, close panel when the map is clicked
    map &&
      selectedPanelTract &&
      map.on("click", e => onMapClickClosePanel(map, mapboxTilesetId, sourceLayer));
  } else if (size === "small") {
    // on mobile, when widget menu is collapsed
    // open the modal when a tract is clicked
    map &&
      !displayExpandedMenu &&
      !selectedModalTract &&
      map.on("click", OVERLAY_IDS.tractsLayerBaseFill, e =>
        onTractsMobileClick(e, map, mapboxTilesetId, sourceLayer, placeId)
      );
  }
};

// dispay popup and disable dragPan when no tracts are highlighted
export const toggleNoTractsMeetCriteriaPopUp = (map: mapboxgl.Map, show: boolean) => {
  if (show) {
    const popup = new mapboxgl.Popup({
      className: "no-highlighted-tracts-popup",
      closeButton: false,
      closeOnClick: false,
      offset: -47
    }).setMaxWidth("350px");

    popup
      .setLngLat(map.getCenter())
      .setDOMContent(makeVulnerablePopulationsNoTractsPopUp())
      .addTo(map);
    map.dragPan.disable();
  } else {
    document.querySelectorAll(".no-highlighted-tracts-popup").forEach(p => p.remove());
    map.dragPan.enable();
  }
};

// Returns 'true' if the given tract exceeds the given geography's values for all selected indicators
const tractAboveAverage = (
  tractData: GeographyVulnerablePopulationData,
  selectedIndicators: readonly string[],
  geoData: GeographyVulnerablePopulationData
) => {
  return selectedIndicators.every(indicator => {
    const tractVal = tractData[indicator as keyof typeof tractData];
    const geoVal = geoData[indicator as keyof typeof geoData];
    return (
      tractVal && tractVal.pct && geoVal && geoVal.pct_median && tractVal.pct >= geoVal.pct_median
    );
  });
};

export const toggleCensusTractsLayer = (
  map: mapboxgl.Map,
  visible: boolean,
  tractInfo: VulnerablePopulationTractInfo,
  vulnerablePopulations: VulnerablePopulationsState,
  placeId: string,
  size: string,
  displayControlsAndLabels?: boolean
) => {
  const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS.census_tracts;

  // No in-place modification, these always get cleared and rebuilt
  Object.entries(OVERLAY_IDS).forEach(([layerName, layerId]) => {
    if (layerName.startsWith("tracts")) {
      map.getLayer(layerId) && map.removeLayer(layerId);
    }
  });

  // if nothing should be visible, bail right after layers are cleared
  if (!visible) return;

  const intersectingTracts = tractInfo.geos.tracts as VulnerablePopulationTractDictionary;
  const intersectingTractIds = Object.keys(intersectingTracts).map(id => parseInt(id, 10));

  const topTwoVulnerablePops = tractInfo.variablesRanked.slice(0, 2);
  const selectedGeoData = tractInfo.geos[placeId];
  //create 2 lists of tract IDs below and above average for the selected indicators
  const [highlightedTractIds, unHighlightedTractIds] = Object.entries(intersectingTracts).reduce(
    (result: number[][], tract) => {
      const aboveAvg = tractAboveAverage(
        tract[1].data,
        displayControlsAndLabels
          ? vulnerablePopulations.selectedVulnerablePopulations
          : topTwoVulnerablePops,
        selectedGeoData
      );
      result[aboveAvg ? 0 : 1].push(parseInt(tract[0], 10));
      return result;
    },
    [[], []]
  );

  const minzoom = 2;

  // On desktop, when the vulnerablePopulationsTractDetailPanel is closed, remove the
  // highlighting from the selected tract in the map
  map &&
    !vulnerablePopulations.selectedPanelTract &&
    size === "small" &&
    map.removeFeatureState({
      source: mapboxTilesetId,
      sourceLayer: sourceLayer
    });

  // On mobile, when the vulnerablePopulationsModal is closed, remove the highlighting from
  // the selected tract in the map
  map &&
    !vulnerablePopulations.selectedModalTract &&
    size === "small" &&
    map.removeFeatureState({
      source: mapboxTilesetId,
      sourceLayer: sourceLayer
    });

  // If there are no selected indicators (either not set yet or the client unchecked them all),
  // or there are no highlighted tracts based on selected indicators, show the "no tracts meet
  // criteria" pop-up and don't draw the filled layer.
  if (!vulnerablePopulations.selectedVulnerablePopulations.length || !highlightedTractIds.length) {
    if (displayControlsAndLabels) {
      toggleNoTractsMeetCriteriaPopUp(map, true);
    }
    return;
  }

  if (displayControlsAndLabels) {
    toggleNoTractsMeetCriteriaPopUp(map, false);
    // Add base texture to all intersecting tracts
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsLayerBaseTexture,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        type: "fill",
        minzoom,
        paint: {
          "fill-pattern": "tmpoly-circle-alt-light-200-black",
          "fill-opacity": 0.2
        },
        filter: ["in", ["id"], ["literal", intersectingTractIds]]
      },
      boundaryLayerInsertBeforeSecondary
    );
    // add fill layer to shade on mouseover and get accurate mouse location for tooltip addition
    map.addLayer(
      {
        id: OVERLAY_IDS.tractsLayerBaseFill,
        source: mapboxTilesetId,
        "source-layer": sourceLayer,
        type: "fill",
        minzoom,
        paint: {
          "fill-color": "#000",
          "fill-opacity": ["match", ["feature-state", "highlight"], "light", 0.3, "dark", 0.7, 0]
        },
        filter: ["in", ["id"], ["literal", intersectingTractIds]]
      },
      boundaryLayerInsertBeforeSecondary
    );

    addVulnerablePopulationsMapElements(
      map,
      size,
      intersectingTracts,
      placeId,
      vulnerablePopulations.selectedPanelTract,
      vulnerablePopulations.selectedPanelTract,
      vulnerablePopulations.displayExpandedMenu
    );
  }

  map.addLayer(
    {
      id: OVERLAY_IDS.tractsUnhighlightedLayerIdLine,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      minzoom,
      paint: {
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // Syntax in pairs of two:
          // zoom level,
          // width in px,
          minzoom,
          0,
          7,
          displayControlsAndLabels ? 1 : 0.5,
          12,
          1.1
        ],
        "line-opacity": 0.4,
        "line-color": "hsl(0, 0%, 0%)"
      },
      filter: ["in", ["id"], ["literal", unHighlightedTractIds]]
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforeSecondary
  );
  map.addLayer(
    {
      id: OVERLAY_IDS.tractsHighlightedLayerIdOutline,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      minzoom,
      paint: {
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // Syntax in pairs of two:
          // zoom level,
          // width in px,
          minzoom,
          2,
          8,
          displayControlsAndLabels ? 4 : 2
        ],
        "line-color": "hsl(0, 0%, 100%)"
      },
      filter: ["in", ["id"], ["literal", highlightedTractIds]]
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforeSecondary
  );
  map.addLayer(
    {
      id: OVERLAY_IDS.tractsLayerIdFill,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      type: "fill",
      minzoom,
      paint: {
        "fill-color": "hsla(184, 97%, 31%, 0.15)"
      },
      filter: ["in", ["id"], ["literal", highlightedTractIds]]
    },
    boundaryLayerInsertBeforeSecondary
  );
  map.addLayer(
    {
      id: OVERLAY_IDS.tractsHighlightedLayerIdLine,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      minzoom,
      paint: {
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // Syntax in pairs of two:
          // zoom level,
          // width in px,
          minzoom,
          0,
          7,
          displayControlsAndLabels ? 1.1 : 0.75,
          12,
          1.1
        ],
        "line-color": "hsl(184, 97%, 31%)",
        "line-opacity": 1
      },
      filter: ["in", ["id"], ["literal", highlightedTractIds]]
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforeSecondary
  );
};
