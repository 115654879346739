import { Grommet } from "grommet";
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import CustomGrommetTheme from "./grommet-theme";
import AppHeader from "./components/AppHeader";
import DetailScreen from "./screens/DetailScreen";
import OverviewScreen from "./screens/OverviewScreen";
import SearchScreen from "./screens/SearchScreen";
import { Screen } from "./models";
import { useRiskParams } from "./hooks";
import GoogleAnalytics from "./components/GoogleAnalytics";
const ScreenComponent = (props: any) => {
  const riskParams = useRiskParams();
  const screen = props.match.params.screen;
  return screen === Screen.Overview ? (
    <OverviewScreen {...riskParams} />
  ) : Object.values(Screen).includes(screen) ? (
    <DetailScreen {...riskParams} />
  ) : (
    <Redirect to="/" />
  );
};
const App = () => {
  return (
    <Grommet plain={true} theme={CustomGrommetTheme}>
      <Router basename={process.env.PUBLIC_URL}>
        {process.env.NODE_ENV === "development" ? "" : <GoogleAnalytics />}
        <Route
          component={AppHeader}
          exact={true}
          path={[
            "/:screen/:stateId/:countyIds/:communityId/",
            "/:screen/:stateId/:countyIds/",
            "/:screen/:stateId/",
            "/search",
            "/"
          ]}
        />
        <Switch>
          <Route path={["/", "/search"]} exact={true} component={SearchScreen} />
          <Route
            path={[
              "/:screen/:stateId/:countyIds/:communityId/",
              "/:screen/:stateId/:countyIds/",
              "/:screen/:stateId/"
            ]}
            exact={true}
            component={ScreenComponent}
          />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </Grommet>
  );
};
export default App;
