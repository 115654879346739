// UI types

export enum Screen {
  Overview = "overview",
  RiskToHomes = "risk-to-homes",
  RiskReductionZones = "risk-reduction-zones",
  WildfireLikelihood = "wildfire-likelihood",
  VulnerablePopulations = "vulnerable-populations"
}

// Geography types

export enum RiskClassLevel {
  Low = "low",
  Medium = "medium",
  High = "high",
  VeryHigh = "very high"
}

export interface CountyGeography {
  readonly id: string;
  readonly name: string;
  readonly name_short: string;
}

export interface StateGeography {
  readonly abrv: string;
  readonly bounds?: readonly number[]; // Should be mapboxgl.LngLatBoundsLike, but the decoder gets mad
  readonly name: string;
}

export interface Geography {
  readonly bounds: readonly number[]; // Should be mapboxgl.LngLatBoundsLike, but the decoder gets mad
  readonly breadcrumb: string;
  readonly counties?: readonly CountyGeography[]; // counties, for communities
  readonly expanded_bounds?: readonly number[];
  readonly geo_level: string;
  readonly name: string; // name with state abrv
  readonly name_short: string; // name without state abrc
  readonly search?: string; // search term to use, if not simply {label}, {state abbreviation}
  readonly state: StateGeography;
  readonly ranks?: Ranks;
  readonly risk_to_homes_class?: RiskClassLevel;
  readonly wildfire_likelihood_class?: RiskClassLevel;
  readonly exposure_type_class?: RiskClassLevel;
  readonly vulnerable_populations_class?: RiskClassLevel;
}

export type GeographyList = Record<string, Geography>;

export interface GeographyListResource {
  readonly geographies: GeographyList;
  readonly searchList: SearchEntryList;
}

export interface GeographyResource {
  readonly geography: Geography;
}

export interface SearchEntry {
  readonly key: string;
  readonly type: string;
  readonly state: string;
  readonly counties?: readonly string[];
  readonly tribal_area?: string;
  readonly community?: string;
  readonly searchText: string;
}

export type SearchEntryList = readonly SearchEntry[];

// Map Legend types

// Risk to Homes, Wildfire Likelihood
export interface RampLegend {
  readonly colors: ReadonlyArray<string>;
  readonly legendLabel: string;
  readonly lowLabel: string;
  readonly highLabel: string;
}

// RRZ raster class
export interface RasterClass {
  readonly color: string;
  readonly label: RRZClass;
  readonly definition?: string;
}

export interface ClassLegend {
  readonly label?: string;
  readonly classes: ReadonlyArray<RasterClass>;
}

// Boundary legend types
export enum BoundaryLegendCategory {
  Place = "Selected place",
  RiskCalcArea = "Risk calculation area",
  County = "County line",
  State = "State line",
  Tract = "Census tract",
  SelectedTract = "Area with vulnerable populations"
}

// Glossary types

export interface GlossaryEntry {
  readonly label: string;
  readonly shortDefinition: string;
  readonly definition: string;
}

export type GlossaryTerms = Record<string, GlossaryEntry>;

export interface Glossary {
  readonly title: string;
  readonly description: string;
  readonly terms: GlossaryTerms;
}

export interface GlossaryResource {
  readonly glossary: Glossary;
}

// Action types

export interface ActionDetails {
  readonly name: string;
  readonly icon: (props: any) => JSX.Element;
  readonly description: string;
}

// Summary statistics

// the API path names for summary statistic comparison levels
export enum GeographyLevel {
  county = "counties",
  state = "states",
  community = "communities",
  tribal_area = "tribal_areas"
}

// geography level names for the comparison switcher
export enum ComparisonLevel {
  county = "County",
  state = "State",
  national = "National"
}

// singlular, capitalized geography level labels, keyed by the values of GeographyLevel
export enum GeographyLevelSingularLabel {
  communities = "Community",
  counties = "County",
  states = "State",
  tribal_areas = "Tribal area"
}

// mapping of geography FIPS to their place names
export type GeographyNames = Record<string, string>;

// Reusable interface for geography data types
interface GeographyData<T> {
  readonly d: T;
}

// RiskToHomes models
export interface RiskToHomesDataSet {
  readonly name?: string;
  readonly total_housing_units?: number;
  readonly bp_mean?: number;
  readonly crps_mean?: number;
  readonly rps_mean?: number;
  readonly exclude_from_percentile?: boolean;
}

export interface RiskToHomesRankData {
  readonly name?: string;
  readonly key?: string;
  readonly rpsPctRank: number;
}

export type RiskToHomes = GeographyData<RiskToHomesDataSet>;

export type RiskToHomesCollection = Record<string, RiskToHomes>;

export interface RiskToHomesResource {
  readonly riskToHomes: RiskToHomesCollection;
}

export interface RiskToHomesData extends RiskToHomesDataSet {
  readonly x: number;
  readonly y: number;
  readonly color: string;
  readonly key: string;
  readonly rpsPctRank: number;
}

export interface RiskToHomesChartData {
  readonly chartData: ReadonlyArray<RiskToHomesData>;
  readonly bpMin: number;
  readonly bpMax: number;
  readonly crpsMin: number;
  readonly crpsMax: number;
  readonly teMin: number;
  readonly teMax: number;
}

export interface ChartBreak {
  readonly max: number;
  readonly value: string;
  readonly hideInLegend?: boolean;
}

// This is just a constant to be used in various chart-related components
export const MinimumChartBP = 0.000001;

export const riskToHomesColors: ReadonlyArray<ChartBreak> = [
  { max: 0.4, value: "#FEFFA8" },
  { max: 0.7, value: "#FFCB3F" },
  { max: 0.9, value: "#FF8718" },
  { max: 0.95, value: "#FF1A00" },
  { max: 1.0, value: "#CE001B" }
];

// Wildfire likelihood models
export interface WildfireLikelihoodDataSet {
  readonly name?: string;
  readonly total_housing_units?: number;
  readonly bp_mean?: number;
  readonly exclude_from_percentile?: boolean;
}

export interface WildfireLikelihoodRankData {
  readonly name?: string;
  readonly key?: string;
  readonly bpPctRank?: number;
}

export type WildfireLikelihood = GeographyData<WildfireLikelihoodDataSet>;

export type WildfireLikelihoodCollection = Record<string, WildfireLikelihood>;

export interface WildfireLikelihoodResource {
  readonly wildfireLikelihood: WildfireLikelihoodCollection;
}

export interface WildfireLikelihoodData extends WildfireLikelihoodDataSet {
  readonly bpLog?: number;
  readonly bpPctRank?: number;
  readonly color: string;
  readonly key: string;
  readonly name: string;
}

export interface WildfireLikelihoodChartData {
  readonly data: ReadonlyArray<WildfireLikelihoodData>;
  // bpMin and bpMax are for the computed logarithm here, not the base value
  readonly bpMin: number;
  readonly bpMax: number;
  readonly teMin: number;
  readonly teMax: number;
}

// The map shows 10 breaks, but for the legend we want to show only every other.
// Showing 1,3,6,8,10 to make the legend cover the full range rather than skipping
// the 1st step. Leaving the others in place but commented out, though, for
// reference and in case we want to adjust.
export const wildfireLikelihoodColorBreaks: ReadonlyArray<ChartBreak> = [
  {
    max: 0.0001,
    value: "#fff0cf"
  },
  {
    max: 0.0002154,
    value: "#fddcaf",
    hideInLegend: true
  },
  {
    max: 0.0004642,
    value: "#fdca94"
  },
  {
    max: 0.001,
    value: "#fdb27b",
    hideInLegend: true
  },
  {
    max: 0.0021544,
    value: "#fc8d59",
    hideInLegend: true
  },
  {
    max: 0.0046416,
    value: "#f26d4b"
  },
  {
    max: 0.01,
    value: "#e1452f",
    hideInLegend: true
  },
  {
    max: 0.0215443,
    value: "#c91d13"
  },
  {
    max: 0.0464159,
    value: "#a90000",
    hideInLegend: true
  },
  {
    // also default for greater than max
    max: 0.13,
    value: "#7f0000"
  }
];

export const wildfireLikelihoodTextBreaks: ReadonlyArray<ChartBreak> = [
  {
    max: 0.4,
    value: "low"
  },
  {
    max: 0.7,
    value: "moderately low"
  },
  {
    max: 0.9,
    value: "moderately high"
  },
  {
    max: 0.95,
    value: "high"
  },
  {
    max: 1,
    value: "very high"
  }
];

// Risk Reduction Zone

export enum RRZClass {
  Minimal = "Minimal exposure",
  Indirect = "Indirect exposure",
  Direct = "Direct exposure",
  Transmission = "Wildfire transmission"
}

export interface RRZDataSet {
  readonly fraction_de?: number;
  readonly fraction_ie?: number;
  readonly fraction_me?: number;
  readonly fraction_wtz?: number;
  readonly total_buildings?: number;
  readonly buildings_fraction_me?: number;
  readonly buildings_fraction_ie?: number;
  readonly buildings_fraction_de?: number;
}

export type RRZ = GeographyData<RRZDataSet>;

export type RRZCollection = Record<string, RRZ>;

export interface RRZResource {
  readonly rrz: RRZCollection;
}

// Vulnerable Populations

export interface VulnerablePopulationsDataSet {
  readonly HH?: number;
  readonly HH_dsc?: number;
  readonly HOUS_MOBILES?: number;
  readonly HOUS_MOBILES_dsc?: number;
  readonly HOUS_NOCAR?: number;
  readonly HOUS_NOCAR_dsc?: number;
  readonly LANG_POP?: number;
  readonly LANG_POP_dsc?: number;
  readonly LANG_ENGNOTPROF_PAR?: number;
  readonly LANG_ENGNOTPROF_PAR_dsc?: number;
  readonly POP_COHORT23?: number;
  readonly POP_COHORT23_dsc?: number;
  readonly POP_NON_INST?: number;
  readonly POP_NON_INST_dsc?: number;
  readonly POP_DISABLED?: number;
  readonly POP_DISABLED_dsc?: number;
  readonly POV_FAM?: number;
  readonly POV_FAM_dsc?: number;
  readonly POV_FAMBELOW?: number;
  readonly POV_FAMBELOW_dsc?: number;
  readonly POP?: number;
  readonly POP_dsc?: number;
}

export interface ValueMoEPctPctMoE {
  readonly value?: number;
  readonly moe?: number;
  readonly pct?: number;
  readonly pct_moe?: number;
  readonly pct_median?: number;
}

export type VulnerablePopulationTableRow = {
  readonly label: JSX.Element;
  readonly subCategory: boolean;
} & ValueMoEPctPctMoE;

export type VulnerablePopulationMetricType =
  | "minority"
  | "no_car"
  | "not_good_english"
  | "family_poverty"
  | "under_5"
  | "over_65"
  | "disabled"
  | "mobile";

export interface GeographyVulnerablePopulationData {
  readonly minority?: ValueMoEPctPctMoE;
  readonly no_car?: ValueMoEPctPctMoE;
  readonly not_good_english?: ValueMoEPctPctMoE;
  readonly family_poverty?: ValueMoEPctPctMoE;
  readonly under_5?: ValueMoEPctPctMoE;
  readonly over_65?: ValueMoEPctPctMoE;
  readonly disabled?: ValueMoEPctPctMoE;
  readonly mobile?: ValueMoEPctPctMoE;
  readonly population?: ValueMoEPctPctMoE;
  readonly pop_black?: ValueMoEPctPctMoE;
  readonly pop_ind?: ValueMoEPctPctMoE;
  readonly pop_hisp?: ValueMoEPctPctMoE;
}

// The actual shape here is
// geos: {
//   tracts: {
//     [geoId: string]: {
//        name: string
//        data: GeographyVulnerablePopulationData
//     }
//   }
//   [geoId: string]: GeographyVulnerablePopulationData
// }
// But the decoder library doesn't make that easy to represent exactly,
// so this is a more general type that's easier to decode.
export interface VulnerablePopulationTractInfo {
  readonly variablesRanked: readonly VulnerablePopulationMetricType[];
  readonly geos: {
    readonly [key: string]: GeographyVulnerablePopulationData | VulnerablePopulationTractDictionary;
  };
}

export interface VulnerablePopulationTractDictionary {
  readonly [geoId: string]: VulnerablePopulationNameAndData;
}

export interface VulnerablePopulationNameAndData {
  readonly data: GeographyVulnerablePopulationData;
  readonly name: string;
}

export interface VulnerablePopulationTractInfoResource {
  readonly tractInfo: VulnerablePopulationTractInfo;
}
export enum BaseMapLayer {
  none = "None",
  riskToHomes = "Risk to Homes",
  wildfireLikelihood = "Wildfire Likelihood",
  riskReductionZones = "Risk Reduction Zones"
}

export interface MapLayerOptionType {
  readonly lab: string;
  readonly value: BaseMapLayer;
}

export type NormalizedTractId = string | undefined;

// Overview

export interface RiskCardDisplay {
  readonly cardLabel: string;
  readonly screen: Screen;
  readonly text: string;
  readonly riskLevel: RiskClassLevel | undefined;
}
export interface Ranks {
  readonly rps_within_nation: number;
  readonly bp_within_nation: number;
  readonly rps_within_state?: number;
  readonly bp_within_state?: number;
  readonly rps_within_county?: number;
  readonly bp_within_county?: number;
}
