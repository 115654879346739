import { Box, CheckBox, Grid, Grommet, Text } from "grommet";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import CustomGrommetTheme from "../grommet-theme";
import { State } from "../reducers";
import { GeographyLevel, GeographyLevelSingularLabel } from "../models";
import {
  setSelectedVulnerablePopulations,
  setDisplayExpandedMenu
} from "../actions/vulnerablePopulations";
import CollapsedMenuIcon from "../icons/CollapsedMenuIcon";
import AreaIcon from "../icons/AreaIcon";
import { isWindowNarrow } from "../utils";
import { vulnerablePopulationsGlossaryTermLabels } from "../constants";

import store from "../store";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import GlossaryLink from "./GlossaryLink";

const LegendHeading = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
`;

const LegendText = styled(Text)`
  font-size: 14px;
  color: #000000;
  line-height: 18px;
`;

const WidgetTitles = styled.div`
  font-size: 14px;
  margin-bottom: 17px;
  color: #555555;
  line-height: 18px;
`;

const Choice = styled.div`
  height: 18px;
  margin-bottom: 8px;
`;

const ExpandedMobileMenu = styled.div`
  height: 345px;
  width: 280px;
  position: absolute;
  top: 32px;
  background: #ffffff;
`;

const RankingNote = styled.div`
  font-size: 14px;
  line-height: 1.3em;
  padding: 3px 5px;
  background: #fbfaf9;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
`;

interface Props {
  readonly level: GeographyLevel;
}

interface StateProps {
  readonly vulnerablePopulationsState: VulnerablePopulationsState;
  readonly selectVulnerablePopulations: ReadonlyArray<string>;
  readonly displayExpandedMenu: boolean;
}

const Widget = ({ level, vulnerablePopulationsState }: Props & StateProps) => {
  const {
    tractInfo,
    selectedVulnerablePopulations,
    rankedVulnerablePopulations,
    displayExpandedMenu
  } = vulnerablePopulationsState;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setSelectedPopulations = (chosenPopulations: ReadonlyArray<string>) => {
    store.dispatch(setSelectedVulnerablePopulations(chosenPopulations));
  };

  const toggleExpandedMenu = () => {
    store.dispatch(setDisplayExpandedMenu(!displayExpandedMenu));
  };

  const handleSelect = (newValue: string) => {
    selectedVulnerablePopulations.includes(newValue)
      ? setSelectedPopulations(selectedVulnerablePopulations.filter(val => val !== newValue))
      : setSelectedPopulations([...selectedVulnerablePopulations, newValue]);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      if (isWindowNarrow(window.innerWidth)) {
        store.dispatch(setDisplayExpandedMenu(false));
      }
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const WidgetExpanded = () => {
    return (
      <Grommet theme={CustomGrommetTheme} style={{ backgroundColor: "white", borderRadius: "4px" }}>
        <Box margin="10px">
          <Box direction="row" align="start" margin={{ bottom: "10px" }}>
            <Box margin={{ top: "1.5px" }}>
              <AreaIcon
                color="#02929B"
                size="17px"
                strokeWidth="1.5"
                stroke="#02929B"
                fillOpacity="0.2"
              />
            </Box>
            <Box margin={{ left: "10px" }}>
              <LegendHeading margin={{ right: "small" }}>
                Areas with vulnerable populations
              </LegendHeading>
            </Box>
          </Box>
          <WidgetTitles>Highlight areas that rank above the local median for:</WidgetTitles>
          {/* This generates the checkbox rows by iterating over the ranked variables, but if that
            array hasn't been populated yet, it will use the terms list instead to get the right
            number. The conditional "skeleton" property on the Box will make them show up as
            placeholder gray boxes until the sorted variables arrive. */}
          <Box
            skeleton={rankedVulnerablePopulations.length === 0}
            style={{ backgroundColor: "white", marginBottom: "9px" }}
          >
            {(rankedVulnerablePopulations.length > 0
              ? rankedVulnerablePopulations
              : Object.entries(vulnerablePopulationsGlossaryTermLabels)
                  .filter(([label, entry]) => !entry.subCategory)
                  .map(entry => entry[0])
            ).map(key => {
              return (
                <Choice key={key}>
                  <CheckBox
                    checked={selectedVulnerablePopulations.includes(key) ? true : false}
                    label={
                      <Text size="14px" color="#555555">
                        {
                          vulnerablePopulationsGlossaryTermLabels[
                            key as keyof typeof vulnerablePopulationsGlossaryTermLabels
                          ].short
                        }
                      </Text>
                    }
                    disabled={"isPending" in tractInfo}
                    value={key}
                    onChange={e => handleSelect(e.target.value)}
                  />
                </Choice>
              );
            })}
          </Box>
          <RankingNote>
            List sorted by <GlossaryLink term="local_rank">local rank</GlossaryLink>. Top two
            selected by default.
          </RankingNote>
          <hr />
          <LegendHeading>Other areas</LegendHeading>
          <Box direction="row" align="center" margin={{ top: "6px", bottom: "6px" }}>
            <AreaIcon
              color="#000000"
              size="18px"
              strokeWidth="1"
              stroke="#000000"
              fillOpacity="0"
            />
            <Box margin={{ left: "10px" }}>
              <LegendText>Census tract</LegendText>
            </Box>
          </Box>
          <Box direction="row" align="center" margin={{ bottom: "6px" }}>
            <Box
              height="18px"
              width="18px"
              style={{ border: "4px solid #000000", background: "rgba(0, 0, 0, 0.01)" }}
            />
            <Box margin={{ left: "10px" }}>
              <LegendText>{GeographyLevelSingularLabel[level]} boundary</LegendText>
            </Box>
          </Box>
        </Box>
      </Grommet>
    );
  };

  const WidgetCollapsed = () => {
    return (
      <Box margin="7px" onClick={toggleExpandedMenu} focusIndicator={false}>
        <Grid columns={["auto", "auto"]} gap="xsmall" align="center">
          <CollapsedMenuIcon size="13px" color="#333333" />
          <Text weight={"bold"} size="12px" color="#333333">
            Select populations
          </Text>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid rows={["auto", "auto"]} gap="none">
      <Box
        height={isWindowNarrow(windowWidth) ? "32px" : ""}
        width={isWindowNarrow(windowWidth) ? "141px" : "215px"}
        style={{
          backgroundColor: displayExpandedMenu ? "#EEEEEE" : "#FFFFFF",
          borderRadius: "4px"
        }}
      >
        {isWindowNarrow(windowWidth) ? <WidgetCollapsed /> : <WidgetExpanded />}
      </Box>
      {displayExpandedMenu ? (
        <ExpandedMobileMenu>
          <WidgetExpanded />
        </ExpandedMobileMenu>
      ) : (
        ""
      )}
    </Grid>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  vulnerablePopulationsState: state.vulnerablePopulations,
  selectVulnerablePopulations: state.vulnerablePopulations.selectedVulnerablePopulations,
  displayExpandedMenu: state.vulnerablePopulations.displayExpandedMenu
});

const WidgetComponent = connect(mapStateToProps)(Widget);

const VulnerablePopulationsWidget = (
  el: HTMLDivElement,
  level: GeographyLevel
): mapboxgl.IControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(
        <Provider store={store}>
          <WidgetComponent level={level} />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    }
  };
};

export default VulnerablePopulationsWidget;
