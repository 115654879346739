import React from "react";
import { Select, Box } from "grommet";

import { ComparisonLevel, GeographyLevel } from "../models";
import { setComparisonLevel } from "../actions/comparisonLevel";
import CaretIcon from "../icons/CaretIcon";
import store from "../store";

interface Props {
  readonly comparisonLevel: ComparisonLevel;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
}

const defaultComparisonLevel = (geoLevel: GeographyLevel) => {
  return {
    [GeographyLevel.community]: ComparisonLevel.national,
    [GeographyLevel.county]: ComparisonLevel.national,
    [GeographyLevel.tribal_area]: ComparisonLevel.national,
    [GeographyLevel.state]: ComparisonLevel.national
  }[geoLevel];
};

const availableComparisonLevels = (geoLevel: GeographyLevel) => {
  return {
    [GeographyLevel.community]: [
      ComparisonLevel.county,
      ComparisonLevel.state,
      ComparisonLevel.national
    ],
    [GeographyLevel.county]: [ComparisonLevel.state, ComparisonLevel.national],
    [GeographyLevel.tribal_area]: [ComparisonLevel.state, ComparisonLevel.national],
    [GeographyLevel.state]: [ComparisonLevel.national]
  }[geoLevel];
};

export const selectedOrDefaultComparisonLevel = (
  geographyLevel: GeographyLevel,
  comparisonLevel: ComparisonLevel
) => {
  return availableComparisonLevels(geographyLevel).includes(comparisonLevel)
    ? comparisonLevel
    : defaultComparisonLevel(geographyLevel);
};

export const ComparisonSwitcher = ({
  comparisonLevel,
  geographyLevel,
  comparisonCountyName,
  comparisonStateName
}: Props) => {
  const levelLabels = {
    [ComparisonLevel.county]: comparisonCountyName,
    [ComparisonLevel.state]: comparisonStateName,
    [ComparisonLevel.national]: "Nation"
  };

  const availableLevels = availableComparisonLevels(geographyLevel).map(level => {
    return { val: level, label: levelLabels[level] };
  });
  return availableLevels.length > 1 ? (
    <div
      className="compare-to"
      style={{
        color: "#555",
        fontSize: "1.5rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative"
      }}
    >
      <span style={{ marginRight: "5px" }}>Compare to </span>
      <Box style={{ maxWidth: "175px" }}>
        <Select
          options={availableLevels}
          value={{ val: comparisonLevel, label: levelLabels[comparisonLevel] }}
          labelKey="label"
          valueKey="val"
          size="medium"
          icon={<CaretIcon size="14px" />}
          onChange={({ option }) => {
            store.dispatch(setComparisonLevel(option.val));
          }}
        />
      </Box>
    </div>
  ) : null;
};
