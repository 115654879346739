import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import React, { useMemo } from "react";
import {
  ComparisonLevel,
  GeographyLevel,
  MinimumChartBP,
  RiskToHomesChartData,
  RiskToHomes,
  RiskToHomesCollection,
  RiskToHomesData,
  riskToHomesColors
} from "../models";
import { RiskToHomesState } from "../reducers/riskToHomes";
import ChartLegends from "./ChartLegends";
import RiskToHomesChart from "./RiskToHomesChart";
import { percentRank, getSortedValuesForKey } from "../utils";

interface ComponentProps {
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly detailPlaceId: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly comparisonAbbr: string;
  readonly riskToHomes: RiskToHomesState;
}

// Match the bubble sizes set by `nodeSize` `sizes` in the chart to those in the legend
const minBubbleSize = 5;
const maxBubbleSize = 30;

const getRiskToHomesChartData = (riskToHomes: RiskToHomesCollection): RiskToHomesChartData => {
  // log scale values need to be nonzero and all positive (or all negative)
  const bpValues = getSortedValuesForKey(riskToHomes, "bp_mean").filter(
    val => val > MinimumChartBP
  );
  const crpsValues = getSortedValuesForKey(riskToHomes, "crps_mean");
  // passing exclude = true filters out values from the communities whitelist so they don't
  // influence the percentile rankings.
  const rpsValues = getSortedValuesForKey(riskToHomes, "rps_mean", true);
  const teValues = getSortedValuesForKey(riskToHomes, "total_housing_units");
  const chartData: readonly RiskToHomesData[] = Object.entries(riskToHomes)
    .map(([key, rth]: readonly [string, RiskToHomes]) => {
      const rthData = rth.d;
      const rpsPctRank =
        rthData.rps_mean !== undefined ? percentRank(rthData.rps_mean, rpsValues) : undefined;
      const colorBreak =
        rpsPctRank !== undefined
          ? riskToHomesColors.find(r => rpsPctRank < r.max) ||
            (rpsPctRank && rpsPctRank >= riskToHomesColors[riskToHomesColors.length - 1].max
              ? riskToHomesColors[riskToHomesColors.length - 1]
              : undefined)
          : undefined;
      // Bubble color breaks are based on percent rank RPS
      const color = colorBreak ? colorBreak.value : "#ccc";
      return !rthData.total_housing_units ||
        rthData.rps_mean === undefined ||
        rthData.bp_mean === undefined ||
        rthData.crps_mean === undefined
        ? undefined
        : Object.assign(
            {
              x: rthData.bp_mean,
              y: rthData.crps_mean
            },
            Object.assign({ color, key, rpsPctRank }, rthData)
          );
    })
    .filter(rthData => {
      return rthData;
    }) as readonly RiskToHomesData[];
  return {
    // tslint:disable-next-line readonly-array
    chartData,
    bpMin: Math.min(...bpValues),
    bpMax: Math.max(...bpValues),
    crpsMin: Math.min(...crpsValues),
    crpsMax: Math.max(...crpsValues),
    teMin: Math.min(...teValues),
    teMax: Math.max(...teValues)
  };
};

const RiskToHomesComponent = (props: ComponentProps) => {
  const { detailPlaceId, geography, comparisonAbbr, riskToHomes } = props;

  const riskToHomesChartData: RiskToHomesChartData = useMemo(() => {
    return "resource" in riskToHomes
      ? getRiskToHomesChartData(riskToHomes.resource.riskToHomes)
      : {
          chartData: [],
          bpMin: 0,
          bpMax: 0,
          crpsMin: 0,
          crpsMax: 0,
          rpsMin: 0,
          rpsMax: 0,
          teMin: 0,
          teMax: 0
        };
  }, [riskToHomes]);

  const descriptionText =
    "Risk to homes measures the relative consequence of wildfire to residential structures everywhere on the landscape, whether a home actually exists there or not. This allows us to consider wildfire risk in places with homes in addition to places where new construction is proposed.";

  return (
    <>
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <Box
              width={size === "small" ? "100%" : "50%"}
              align="center"
              direction="column"
              justify="start"
            >
              <Box
                pad={{
                  top: size === "small" ? "large" : "medium"
                }}
              >
                <Heading
                  level={2}
                  margin={{ top: "0" }}
                  style={{ flex: 1 }}
                  size="xlarge"
                  color="heading"
                >
                  About risk to homes
                </Heading>
                {geography && (
                  <Paragraph
                    size="large"
                    color="text"
                    margin={{ top: "0", bottom: "small" }}
                    fill={true}
                  >
                    {descriptionText}
                  </Paragraph>
                )}
              </Box>
              <Box margin={{ left: "small", top: "40px" }} alignSelf="start">
                <ChartLegends
                  colors={riskToHomesColors.map(c => c.value)}
                  colorsLabel={`Risk to homes in ${comparisonAbbr}`}
                  minBubbleSize={6}
                  maxBubbleSize={18}
                />
              </Box>
            </Box>

            <Box width={size === "small" ? "100%" : "50%"} align="center" height={"470px"}>
              <div className="risk-to-homes" style={{ overflow: "hidden" }}>
                <RiskToHomesChart
                  detailPlaceId={detailPlaceId}
                  geography={geography}
                  minBubbleSize={minBubbleSize}
                  maxBubbleSize={maxBubbleSize}
                  riskToHomesChartData={riskToHomesChartData}
                />
              </div>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    </>
  );
};

export default RiskToHomesComponent;
