import { Box, Text, Heading, ResponsiveContext } from "grommet";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { GeographyLevel, Screen, RiskClassLevel } from "../models";
import { riskFillColors } from "../constants";
import GlossaryLink from "./GlossaryLink";

interface StateProps {
  readonly countyDescNames?: readonly string[];
  readonly detailPlaceId: string;
  readonly detailPlaceName: string;
  readonly geographyLevel: GeographyLevel;
  readonly stateDescName: string;
  readonly rpsRank?: number;
  readonly rpsRankClass?: string;
}

interface QueryParamTypes {
  readonly screen?: Screen;
  readonly stateId?: string;
  readonly countyIds?: string;
}

const GeographyDescription = (props: StateProps) => {
  const {
    countyDescNames,
    detailPlaceId,
    detailPlaceName,
    geographyLevel,
    stateDescName,
    rpsRank,
    rpsRankClass
  } = props;
  const { screen, stateId, countyIds } = useParams<QueryParamTypes>();
  const countyIdsArray = countyIds ? countyIds.split("|") : [];
  const stateLink = `/${screen}/${stateId}/`;
  // Show county link(s) if there is at least one county to show
  // and it is not the currently selected geography.
  const showCounty = !!(countyIdsArray.length > 0 && countyIdsArray[0] !== detailPlaceId);
  const showState = !!(stateId && stateId !== detailPlaceId);

  const rpsPctRank =
    rpsRank !== undefined
      ? rpsRankClass === RiskClassLevel.Low
        ? 100 - Math.round(100 * rpsRank)
        : Math.round(100 * rpsRank)
      : undefined;
  const compareDescriptor =
    rpsRank !== undefined ? (rpsRankClass === RiskClassLevel.Low ? "lower" : "higher") : "";

  const geographyTypeLabel =
    geographyLevel === GeographyLevel.tribal_area
      ? `${geographyLevel.replace("_", " ")} and counties`
      : geographyLevel.replace("_", " ");

  const description =
    screen === Screen.Overview ? (
      <Text size="inherit">
        {detailPlaceName} has a{" "}
        <GlossaryLink term="risk_rating" alignBottom={true}>
          <Text
            size="inherit"
            color={
              rpsRankClass ? riskFillColors[rpsRankClass as keyof typeof riskFillColors] : "heading"
            }
          >
            {rpsRankClass}
          </Text>
        </GlossaryLink>{" "}
        risk of wildfire—{compareDescriptor} than{" "}
        {rpsRankClass === RiskClassLevel.Low && rpsPctRank !== undefined && rpsPctRank >= 95
          ? "nearly all"
          : `${rpsPctRank}% of`}{" "}
        {geographyTypeLabel} in the US.
      </Text>
    ) : (
      detailPlaceName
    );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box gap={screen === Screen.Overview ? "42px" : "4px"}>
          {size !== "small" && showState && (
            <Text>
              <Link className="breadcrumbLink" to={stateLink}>{`${stateDescName}`}</Link> {"> "}
              {showCounty &&
                countyDescNames &&
                countyDescNames.map((countyDescName, i) => {
                  const countyLink = `${stateLink}${countyIdsArray[i]}/`;
                  return (
                    <span key={i}>
                      <Link className="breadcrumbLink" to={countyLink}>
                        {`${countyDescName}`}
                      </Link>{" "}
                      {i === countyDescNames.length - 1 ? ">" : "/"}{" "}
                    </span>
                  );
                })}
            </Text>
          )}
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Heading
              color="heading"
              level={1}
              margin={size === "small" ? { top: "0", bottom: "6px", vertical: "0" } : "0"}
              size={size === "small" ? "xlarge" : screen === Screen.Overview ? "xxlarge" : "xlarge"}
              textAlign={size === "small" ? "center" : undefined}
            >
              {description}
            </Heading>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default GeographyDescription;
