import mapboxgl from "mapbox-gl";
import { Root } from "react-dom/client";
import { NormalizedTractId, VulnerablePopulationTractDictionary } from "../models";
import { setSelectedModalTract, setSelectedPanelTract } from "../actions/vulnerablePopulations";
import VulnerablePopulationsPopUp from "./VulnerablePopulationsPopUp";
import store from "../store";

export default undefined;

const tractIdFromMouseEvent = (e: mapboxgl.MapLayerMouseEvent) => {
  const hoveredTract = e.features ? e.features[0] : undefined;
  return hoveredTract ? String(hoveredTract.id) : undefined;
};

const makeNormalizedTractId = (tractId: string | undefined, placeId: string): NormalizedTractId => {
  const normalizedTractId =
    tractId && tractId.length === 10 && placeId.length !== 6 ? `0${tractId}` : tractId;
  return normalizedTractId;
};

export const onTractsMouseOver = (
  e: mapboxgl.MapLayerMouseEvent,
  map: mapboxgl.Map,
  vulnPopTracts: VulnerablePopulationTractDictionary,
  placeId: string,
  popUpNode: HTMLDivElement,
  reactRoot: Root,
  popup: mapboxgl.Popup,
  sourceId: string,
  sourceLayer: string | undefined
) => {
  // on hover, add shading to tract polygon and use tract id to call and add that pop up
  const tractId = tractIdFromMouseEvent(e);
  map.removeFeatureState({
    source: sourceId,
    sourceLayer: sourceLayer
  });

  map.setFeatureState(
    {
      source: sourceId,
      sourceLayer: sourceLayer,
      id: tractId
    },
    {
      highlight: "light"
    }
  );

  /* tslint:disable:no-object-mutation */
  map.getCanvas().style.cursor = "pointer";
  /* tslint:enable:no-object-mutation */
  const normalizedTractId = makeNormalizedTractId(tractId, placeId);
  if (normalizedTractId && vulnPopTracts) {
    VulnerablePopulationsPopUp(
      reactRoot,
      vulnPopTracts[normalizedTractId as keyof typeof vulnPopTracts]
    );
    popup.setLngLat(e.lngLat).setDOMContent(popUpNode).addTo(map);
  } else {
    popup.remove();
  }
};

export const onTractsMouseLeave = (
  map: mapboxgl.Map,
  popup: mapboxgl.Popup,
  sourceId: string,
  sourceLayer: string | undefined
) => {
  // on mouseleave, remove shading to tract polygon and pop up
  map.removeFeatureState({
    source: sourceId,
    sourceLayer: sourceLayer
  });
  /* tslint:disable:no-object-mutation */
  map.getCanvas().style.cursor = "";
  /* tslint:enable:no-object-mutation */
  popup.remove();
};

export const onTractsMobileClick = (
  e: mapboxgl.MapLayerMouseEvent,
  map: mapboxgl.Map,
  sourceId: string,
  sourceLayer: string | undefined,
  placeId: string
) => {
  // when a tract is clicked, add shading to tract polygon and use tract id to open modal
  const tractId = tractIdFromMouseEvent(e);
  const normalizedTractId = makeNormalizedTractId(tractId, placeId);
  store.dispatch(setSelectedModalTract(normalizedTractId));
  map.removeFeatureState({
    source: sourceId,
    sourceLayer: sourceLayer
  });
  map.setFeatureState(
    {
      source: sourceId,
      sourceLayer: sourceLayer,
      id: tractId
    },
    {
      highlight: "dark"
    }
  );
};

export const onTractsDesktopClick = (
  e: mapboxgl.MapLayerMouseEvent,
  map: mapboxgl.Map,
  placeId: string,
  sourceId: string,
  sourceLayer: string | undefined,
  popup: mapboxgl.Popup
) => {
  // when a tract is clicked, add shading to tract polygon, center the map on the tract,
  // stop showing the pop up, and use tract id to open panel
  const tractId = tractIdFromMouseEvent(e);
  const normalizedTractId = makeNormalizedTractId(tractId, placeId);
  store.dispatch(setSelectedPanelTract(normalizedTractId));
  map.removeFeatureState({
    source: sourceId,
    sourceLayer: sourceLayer
  });
  map.setFeatureState(
    {
      source: sourceId,
      sourceLayer: sourceLayer,
      id: tractId
    },
    {
      highlight: "dark"
    }
  );
  // With so much stuff on top of the map, "centering" the click location should actually mean
  // centering it in the open space between the tract details panel and the base map selector.
  // So the 'easeTo' call has padding to account for the approximate width of those elements.
  // The ts-ignore is necessary because 'padding' is missing from the type definition
  // @ts-ignore
  map.easeTo({ center: e.lngLat, padding: { left: 400, right: 200 } });
  popup.remove();

  // remove map event listeners while panel is open
  clearMapListeners(map);
};

export const onMapClickClosePanel = (
  map: mapboxgl.Map,
  sourceId: string,
  sourceLayer: string | undefined
) => {
  // when the panel is open, listen for clicks on the map to close the panel
  store.dispatch(setSelectedPanelTract(undefined));
  // and clear selected-tract highlighting
  map.removeFeatureState({
    source: sourceId,
    sourceLayer: sourceLayer
  });
};

export const clearMapListeners = (map: mapboxgl.Map) => {
  // @ts-ignore
  if (map._listeners.click) {
    // @ts-ignore
    map._listeners.click = [];
  }
  // @ts-ignore
  if (map._listeners.mousemove) {
    // @ts-ignore
    map._listeners.mousemove = [];
  }
  // @ts-ignore
  if (map._listeners.mouseout) {
    // @ts-ignore
    map._listeners.mouseout = [];
  }
};
