import { Paragraph } from "grommet";
import React, { useMemo } from "react";
import { ComparisonLevel, GeographyLevel, Ranks, Screen } from "../models";
import { PercentileComparisonText } from "./PercentileComparisonText";

interface ComponentProps {
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly ranks: Ranks | undefined;
}

const RiskToHomesComparisonText = (props: ComponentProps) => {
  const {
    geography,
    geographyLevel,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    ranks
  } = props;

  const comparisonText = useMemo(() => {
    const rpsPctRank = !ranks
      ? undefined
      : Math.round(
          (comparisonLevel === ComparisonLevel.county && ranks.rps_within_county !== undefined
            ? ranks.rps_within_county
            : comparisonLevel === ComparisonLevel.state && ranks.rps_within_state !== undefined
            ? ranks.rps_within_state
            : ranks.rps_within_nation) * 100
        );
    const comparisonGeographyName =
      comparisonLevel === ComparisonLevel.county
        ? comparisonCountyName
        : comparisonLevel === ComparisonLevel.state
        ? comparisonStateName
        : "the US";

    return rpsPctRank === undefined ? (
      // Avoids possibly flashing the wrong text while rpsPctRank is being recalculated
      <></>
    ) : (
      <PercentileComparisonText
        screen={Screen.RiskToHomes}
        comparisonGeographyName={comparisonGeographyName}
        geography={geography}
        geographyLevel={geographyLevel}
        percentileRank={rpsPctRank}
        quantityLabel={<>risk</>}
      />
    );
  }, [
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    geographyLevel,
    geography,
    ranks
  ]);

  return (
    <>
      {geography && (
        <Paragraph size="large" color="text" margin={{ top: "0", bottom: "small" }} fill={true}>
          {comparisonText}
        </Paragraph>
      )}
    </>
  );
};

export default RiskToHomesComparisonText;
