import React, { useState, createRef, RefObject, MouseEvent } from "react";
import { Drop, Box, ResponsiveContext, Text, Button } from "grommet";
import ReactHtmlParser from "react-html-parser";
import { Node } from "htmlparser2";
import { connect } from "react-redux";
import { setGlossaryTerm } from "../actions/glossaryControl";
import QuestionIcon from "../icons/QuestionIcon";
import { GlossaryState } from "../reducers/glossary";
import { State } from "../reducers";
import store from "../store";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";
import styled from "styled-components";

interface StateProps {
  readonly glossaryResource: GlossaryState;
}

interface Props {
  readonly term: string;
  readonly children: React.ReactNode;
  readonly alignBottom?: boolean;
}

const GlossaryButton = styled(Button)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  :focus {
    border: 1px dashed #999999;
  }
`;

// Convert links in short definition to plain text, since related-glossary-word behavior
// isn't currently defined within the tooltip.
const stripLinks: any = (node: Node) => {
  if (node.children && node.type === "tag" && node.name === "a") {
    return node.children[0].data;
  }
};

const GlossaryLink = ({ glossaryResource, term, children, alignBottom }: StateProps & Props) => {
  const [boxRef] = useState(
    createRef() as RefObject<HTMLAnchorElement> & RefObject<HTMLButtonElement>
  );
  const [openTip, setOpenTip] = useState(false);

  const closeTip = () => setOpenTip(false);

  const setTerm = (ev: MouseEvent) => {
    ev.stopPropagation();
    store.dispatch(setGlossaryTerm(term));
    closeTip();
  };

  const shortDefinition =
    "resource" in glossaryResource
      ? glossaryResource.resource.glossary.terms[term]?.shortDefinition
      : undefined;

  return shortDefinition ? (
    <GlossaryButton
      ref={boxRef}
      onClick={() => {
        logGoogleAnalyticsEvent("general", "view glossary term", term);
        setOpenTip(!openTip);
      }}
      className="glossary-link"
      aria-label={`${children?.toString()}, press to open glossary`}
    >
      <span style={{ textDecoration: "none", borderBottom: "1px dashed #999999" }}>
        {children}
        {openTip && (
          <Drop
            // We normally show the tip above the cursor, but alignBottom overrides that to
            // show it below instead, for links that are too near the top of the page.
            align={alignBottom ? { top: "bottom" } : { bottom: "top" }}
            target={boxRef.current as HTMLButtonElement}
            elevation="large"
            onClickOutside={closeTip}
            onEsc={closeTip}
            style={{ marginBottom: "10px" }}
          >
            <Box gap="small" pad="small" margin="none">
              <Box overflow="auto" width="medium">
                <Text>{ReactHtmlParser(shortDefinition, { transform: stripLinks })}</Text>
              </Box>
              <ResponsiveContext.Consumer>
                {size =>
                  size !== "small" && (
                    <Text
                      onClick={setTerm}
                      style={{
                        cursor: "pointer",
                        paddingTop: "1rem",
                        borderTop: "1px solid #EAECED"
                      }}
                    >
                      Read more in the <QuestionIcon color="#B0ACAA" size="small" /> Glossary
                    </Text>
                  )
                }
              </ResponsiveContext.Consumer>
            </Box>
          </Drop>
        )}
      </span>
    </GlossaryButton>
  ) : (
    <span>{children}</span>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  glossaryResource: state.glossary
});

export default connect(mapStateToProps)(GlossaryLink);
