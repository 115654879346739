import { grommet } from "grommet/themes";
import { deepMerge } from "grommet/utils";

// Documentation on Grommet themes:
// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// The base theme this extends and overrides can be found here:
// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js

const fontSizes: readonly string[] = ["1.3rem", "1.5rem", "2.1rem", "2.8rem", "4.5rem"];
const bodyHeight = "1.5";
const headingHeight = "1.2";

const bodyFontSizes = {
  small: {
    size: fontSizes[0],
    height: bodyHeight
  },
  medium: {
    size: fontSizes[1],
    height: bodyHeight
  },
  large: {
    size: fontSizes[2],
    height: bodyHeight
  },
  xlarge: {
    size: fontSizes[3],
    height: bodyHeight
  },
  xxlarge: {
    size: fontSizes[4],
    height: bodyHeight
  }
};

const headingFontSizes = {
  small: {
    size: fontSizes[0],
    height: headingHeight
  },
  medium: {
    size: fontSizes[1],
    height: headingHeight
  },
  large: {
    size: fontSizes[2],
    height: headingHeight
  },
  xlarge: {
    size: fontSizes[3],
    height: headingHeight
  },
  xxlarge: {
    size: fontSizes[4],
    height: headingHeight
  }
};

const CustomGrommetTheme = deepMerge(grommet, {
  global: {
    breakpoints: {
      medium: { value: 1136 }
    },
    font: {
      family: "Lato, sans-serif"
    },
    colors: {
      background: "#fcfcfc",
      lightestBrown: "#f4f2f0",
      mediumBrown: "#7C675A",
      darkestBrown: "#39312D",
      text: "#666",
      heading: "#333",
      // Used in the comparison switcher. This makes it just slightly lighter than the hover,
      // so there's a difference between the previously-selected and the one you're selecting now.
      selected: "rgba(221, 221, 221, 0.3)"
    },
    drop: {
      extend: (props: any) => `animation-duration: 0s;`
    },
    animation: {
      duration: false
    },
    focus: {
      border: {
        // remove the light green border from around selected components
        color: "#00000000"
      }
    },
    edgeSize: {
      none: "0",
      hair: "0.1rem",
      xxsmall: "0.3rem",
      xsmall: "0.6rem",
      small: "1.2rem",
      medium: "2.4rem",
      large: "4.8rem",
      xlarge: "9.6rem",
      responsiveBreakpoint: "small"
    }
  },
  text: bodyFontSizes,
  paragraph: bodyFontSizes,
  heading: {
    weight: 900,
    level: {
      "1": headingFontSizes,
      "2": headingFontSizes,
      "3": headingFontSizes,
      "4": headingFontSizes,
      "5": headingFontSizes,
      "6": headingFontSizes
    }
  },
  anchor: {
    color: {
      dark: "light-2",
      light: "dark-2"
    }
  },
  select: {
    step: 10,
    icons: {
      margin: "none"
    }
  },
  textInput: {
    size: "1.5rem"
  },
  tab: {
    active: {
      color: "mediumBrown",
      weight: 900
    },
    hover: {
      color: "heading"
    },
    border: {
      active: {
        color: {
          dark: "mediumBrown",
          light: "mediumBrown"
        }
      },
      hover: {
        color: {
          dark: "heading",
          light: "heading"
        }
      },
      // hide the underline from unselected screen names
      color: {
        dark: "#00000000",
        light: "#00000000"
      }
    },
    color: "heading"
  },
  checkBox: {
    size: "18px",
    gap: "8px",
    border: {
      color: "#C2C2C2",
      width: "1px"
    },
    check: {
      radius: "2px",
      extend: ({ theme, checked }: any) => `
        ${checked && `background-color: #02929B; border:none;`}
        `
    },
    icon: {
      extend: "stroke: white; stroke-width: 2px;"
    }
  }
});

export default CustomGrommetTheme;
