import { Image } from "grommet";
import React from "react";

const WRTCLogo = () => {
  return (
    <Image
      height="24px"
      a11yTitle="Wildfire Risk to Communities logo"
      src={process.env.PUBLIC_URL + "/logo-wrtc.png"}
    />
  );
};

export default WRTCLogo;
