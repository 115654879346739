import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

export const RRZEmptyHouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="RRZEmptyHouseIcon" viewBox="0 0 20 20" a11yTitle="Empty House Icon" {...props}>
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.2668L1.99993 10.1018V18.4148C1.99993 19.305 2.71628 20.0266 3.59993 20.0266H16.3999C17.2836 20.0266 17.9999 19.305 17.9999 18.4148V10.1016L10 2.2668ZM16.9999 10.5272L10 3.67177L2.99993 10.5274V18.4148C2.99993 18.7486 3.26856 19.0192 3.59993 19.0192H16.3999C16.7313 19.0192 16.9999 18.7486 16.9999 18.4148V10.5272Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3487 1.17678C10.1546 0.986372 9.84525 0.986372 9.65112 1.17678L0.84874 9.81063L0.151123 9.08888L8.95351 0.455036C9.53589 -0.116197 10.464 -0.116197 11.0464 0.455036L19.8487 9.08888L19.1511 9.81063L10.3487 1.17678Z"
        />
      </g>
    </StyledIcon>
  </ThemeProvider>
);

export const RRZHalfHouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon id="RRZHalfHouseIcon" viewBox="0 0 20 20" a11yTitle="Half House Icon" {...props}>
      <g clipPath="url(#clip0_5945_4523)">
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20.0266H16.3999C17.2836 20.0266 17.9999 19.305 17.9999 18.4148V10.1016L10 2.2668L10 2.26685V3.67181L10 3.67177L16.9999 10.5272V18.4148C16.9999 18.7486 16.7313 19.0192 16.3999 19.0192H10V20.0266ZM10 1.03397C10.1258 1.03399 10.2517 1.08159 10.3487 1.17678L19.1511 9.81063L19.8487 9.08888L11.0464 0.455036C10.7552 0.169437 10.3776 0.0266286 10 0.0266113V1.03397Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.0266113C9.62236 0.026594 9.24472 0.169405 8.95351 0.455045L0.151123 9.08907L0.84874 9.81083L9.65112 1.17681C9.74821 1.08158 9.87411 1.03398 10 1.03399V0.0266113ZM10 2.26648L1.99993 10.1016V18.4148C1.99993 19.305 2.71628 20.0266 3.59993 20.0266H10V2.26648Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5945_4523">
          <rect width="20" height="20" fill="white" transform="translate(0 0.0266113)" />
        </clipPath>
      </defs>
    </StyledIcon>
  </ThemeProvider>
);

export const RRZFullHouseIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="RRZFullHouseIcon"
      viewBox="0 0 20 20"
      a11yTitle="Full House Icon"
      {...props}
      strokeWidth="0"
    >
      <path
        d="M9.65112 1.17681C9.84525 0.986391 10.1546 0.986391 10.3487 1.17681L19.1511 9.81083L19.8487 9.08907L11.0464 0.455045C10.464 -0.1162 9.53589 -0.1162 8.95351 0.455045L0.151123 9.08907L0.84874 9.81083L9.65112 1.17681Z"
        fill={props.color}
      />
      <path
        d="M10 2.26644L17.9999 10.1014V18.4148C17.9999 19.305 17.2836 20.0266 16.3999 20.0266H3.59993C2.71628 20.0266 1.99993 19.305 1.99993 18.4148V10.1016L10 2.26644Z"
        fill={props.color}
      />
    </StyledIcon>
  </ThemeProvider>
);
