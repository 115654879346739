import React from "react";
import { ThemeProvider } from "styled-components";

import IconTheme from "./icon-theme";

/* tslint:disable:no-var-requires */
const { StyledIcon } = require("grommet-icons/StyledIcon");
/* tslint:enable:no-var-requires */

const TakeActionIcon = (props: any) => (
  <ThemeProvider theme={IconTheme}>
    <StyledIcon
      id="TakeActionIcon"
      color="#fff"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      a11yTitle="Take Action"
      {...props}
    >
      <path
        stroke="none"
        d="M5.30185 0.332275C5.39398 0.380581 5.47351 0.417846 5.54873 0.462357C6.71977 1.15935 7.67345 2.06405 8.38709 3.19683C9.26555 4.59253 9.62507 6.11314 9.53294 7.73036C9.48724 8.53017 9.38323 9.32722 9.30622 10.1257C9.30406 10.1453 9.30586 10.1653 9.30586 10.2043C9.67293 10.0432 9.95868 9.80235 10.2048 9.51251C10.4571 9.21543 10.6259 8.87763 10.7371 8.51292C10.8469 8.15269 10.8764 7.78212 10.894 7.39532C10.9487 7.46122 11.0048 7.52609 11.0574 7.59338C11.6731 8.37697 12.2227 9.19818 12.607 10.1132C12.9874 11.0183 13.172 11.9565 13.1191 12.9333C13.0853 13.5519 12.9262 14.1447 12.6869 14.7172C12.2587 15.7409 11.6319 16.6685 10.8677 17.4661C10.2048 18.158 7.2208 19.6678 6.31773 19.6678C5.41465 19.6678 4.24257 19.3059 4.25244 19.3363C3.65217 18.8494 3.07816 18.3346 2.54698 17.7773C1.7646 16.9565 1.08012 16.0694 0.590681 15.0543C0.0944082 14.0253 -0.102805 12.9523 0.0512228 11.8222C0.175741 10.9079 0.492794 10.0577 0.946241 9.24959C1.40617 8.4308 1.97621 7.68481 2.58693 6.9654C3.23399 6.20389 3.88681 5.44651 4.51947 4.67431C4.92578 4.17848 5.2673 3.64021 5.50302 3.04639C5.78949 2.32525 5.76178 1.60273 5.51562 0.87986C5.45516 0.702507 5.37958 0.529985 5.30185 0.332275Z"
      />
    </StyledIcon>
  </ThemeProvider>
);

export default TakeActionIcon;
