import { RiskClassLevel, RRZClass } from "./models";

export const vulnerablePopulationsGlossaryTermLabels = {
  family_poverty: {
    long: "Families in poverty",
    short: "Poverty",
    subCategory: false
  },
  disabled: {
    long: "People with disabilities",
    short: "Disabilities",
    subCategory: false
  },
  over_65: {
    long: "People over 65 years",
    short: "Over 65 years",
    subCategory: false
  },
  under_5: {
    long: "People under 5 years",
    short: "Under 5 years",
    subCategory: false
  },
  minority: {
    long: "People of color",
    short: "People of color",
    subCategory: false
  },
  pop_black: {
    long: "Black",
    short: "Black",
    subCategory: true
  },
  pop_ind: {
    long: "Native American",
    short: "Native American",
    subCategory: true
  },
  pop_hisp: {
    long: "Hispanic",
    short: "Hispanic",
    subCategory: true
  },
  not_good_english: {
    long: "Difficulty with English",
    short: "Limited English",
    subCategory: false
  },
  no_car: {
    long: "Households with no car",
    short: "No car",
    subCategory: false
  },
  mobile: {
    long: "Mobile homes",
    short: "Mobile homes",
    subCategory: false
  }
};

export const riskFillColors = {
  [RiskClassLevel.Low]: "#0095CB",
  [RiskClassLevel.Medium]: "#D68000",
  [RiskClassLevel.High]: "#FF0000",
  [RiskClassLevel.VeryHigh]: "#C91D13"
};

export const horizontalPadding = "18px";

export const rrzColors = {
  [RRZClass.Minimal]: "#B9BBC2",
  [RRZClass.Indirect]: "#F5C766",
  [RRZClass.Direct]: "#FF3B54",
  [RRZClass.Transmission]: "#D5E3D5"
};

export enum RRZStructureScale {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export const RRZHouseIconCount = {
  [RRZStructureScale.Small]: 10,
  [RRZStructureScale.Medium]: 20,
  [RRZStructureScale.Large]: 25
};
