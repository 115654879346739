import React from "react";
import { Box, Button, Grommet, Text } from "grommet";
import { Close } from "grommet-icons";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import CustomGrommetTheme from "../grommet-theme";
import mapboxgl from "mapbox-gl";
import { State } from "../reducers";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { setSelectedPanelTract } from "../actions/vulnerablePopulations";
import { VulnerablePopulationNameAndData, VulnerablePopulationTractDictionary } from "../models";
import VulnerablePopulationsTable from "./VulnerablePopulationsTable";
import styled from "styled-components";
import store from "../store";
import { BOUNDARY_LAYERS } from "./mapLayers";

const PanelHeading = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  padding-bottom: 8px;
`;

interface StateProps {
  readonly vulnerablePopulations: VulnerablePopulationsState;
}

interface Props extends StateProps {
  readonly map: mapboxgl.Map;
}

const Panel = ({ vulnerablePopulations, map }: Props) => {
  const { selectedPanelTract } = vulnerablePopulations;
  const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS.census_tracts;

  const closeTractPanel = () => {
    map.removeFeatureState({
      source: mapboxTilesetId,
      sourceLayer: sourceLayer
    });
    store.dispatch(setSelectedPanelTract(undefined));
  };

  const geos =
    "resource" in vulnerablePopulations.tractInfo &&
    vulnerablePopulations.tractInfo.resource.tractInfo.geos;
  const intersectingTracts =
    geos && geos.tracts && (geos.tracts as VulnerablePopulationTractDictionary);
  const geosData =
    geos && selectedPanelTract && geos[selectedPanelTract] ? geos[selectedPanelTract] : undefined;
  const modalTract =
    geos &&
    geos.tracts &&
    (geos.tracts[selectedPanelTract as keyof typeof geosData] as VulnerablePopulationNameAndData);
  const headerTract = intersectingTracts[selectedPanelTract as keyof typeof intersectingTracts];

  return (
    <Grommet theme={CustomGrommetTheme} style={{ backgroundColor: "white", borderRadius: "4px" }}>
      <Box width="370px" margin="10px">
        <Box>
          <Button
            alignSelf="start"
            onClick={closeTractPanel}
            color="text"
            style={{ paddingLeft: "0px" }}
          >
            <Close size="14px" />
            <Text size="15px" margin={{ left: "10px" }} weight={700}>
              Close this table
            </Text>
          </Button>
        </Box>
        <PanelHeading>{headerTract && headerTract.name}</PanelHeading>
        {modalTract && (
          <VulnerablePopulationsTable
            tableData={modalTract.data}
            detailPlaceId={selectedPanelTract}
            mode="panel"
          />
        )}
      </Box>
    </Grommet>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  vulnerablePopulations: state.vulnerablePopulations
});

const PanelComponent = connect(mapStateToProps)(Panel);

const VulnerablePopulationsTractDetailPanel = (el: HTMLDivElement): mapboxgl.IControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(
        <Provider store={store}>
          <PanelComponent map={map} />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    }
  };
};

export default VulnerablePopulationsTractDetailPanel;
