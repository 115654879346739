import { Box, Grid, Header, Menu, ResponsiveContext, Text } from "grommet";
import { Menu as MenuIcon, Search } from "grommet-icons";
import React, { useEffect } from "react";
import styled from "styled-components";

import BackToOverviewButton from "./BackToOverviewButton";
import GeographySearchInput from "./GeographySearch";
import WRTCLogo from "./WRTCLogo";
import { Link, NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { Screen } from "../models";
import { horizontalPadding } from "../constants";

const HeaderLogosSearch = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const headerElementId = "app-header";

const AppHeader = ({
  history,
  location,
  match: {
    params: { screen }
  }
}: RouteComponentProps<{ readonly screen: Screen }> &
  RouteComponentProps<"history"> &
  RouteComponentProps<"location">) => {
  const isOverviewScreen = screen === Screen.Overview;
  const isSearchScreen = location.pathname === "/search" || location.pathname === "/";
  const selectedColor = "#fff";
  const unselectedColor = "rgba(255,255,255,60%)";

  useEffect(() => {
    const header = document.getElementById(headerElementId);
    if (header && header.scrollIntoView) {
      header.scrollIntoView();
    }
  });

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Header
            background="darkestBrown"
            id={headerElementId}
            pad={
              size === "large"
                ? { top: "0.2rem", right: "0", bottom: "0.2rem", left: "0" }
                : {
                    top: "0.2rem",
                    right: horizontalPadding,
                    bottom: "0.2rem",
                    left: horizontalPadding
                  }
            }
          >
            <Box
              className="set-width-content-container"
              width={isSearchScreen ? "100%" : ""}
              margin={isSearchScreen ? { left: "10px" } : "0 auto"}
            >
              <HeaderLogosSearch
                align={"center"}
                columns={["auto", "auto", "auto", "auto"]}
                gap="none"
              >
                {isOverviewScreen || isSearchScreen ? (
                  <a className="header-footer-nav-links" href="/">
                    <WRTCLogo />
                  </a>
                ) : (
                  <Link
                    className="header-footer-nav-links"
                    to={history.location.pathname.replace(screen, Screen.Overview)}
                  >
                    <BackToOverviewButton />
                  </Link>
                )}
                {size === "small" || isSearchScreen ? (
                  ""
                ) : (
                  <Box id="headerSearchBox" background="white">
                    <Search height="14" id="headerSearchBoxIcon" color="black" />
                    <GeographySearchInput />
                  </Box>
                )}
              </HeaderLogosSearch>
              <Grid columns={["auto", "auto", "auto", "auto"]} gap="1.8rem">
                {size === "small" || isOverviewScreen || isSearchScreen ? (
                  ""
                ) : (
                  <>
                    <Box
                      flex={true}
                      align="center"
                      justify="center"
                      style={{ textAlign: "center" }}
                    >
                      <NavLink
                        className="header-footer-nav-links"
                        to={history.location.pathname.replace(screen, Screen.RiskToHomes)}
                      >
                        <Box direction="row" align="center" gap="xsmall">
                          <Text
                            color={screen === Screen.RiskToHomes ? selectedColor : unselectedColor}
                          >
                            Risk to homes
                          </Text>
                        </Box>
                      </NavLink>
                    </Box>
                    <Box
                      flex={true}
                      align="center"
                      justify="center"
                      style={{ textAlign: "center" }}
                    >
                      <NavLink
                        className="header-footer-nav-links"
                        to={history.location.pathname.replace(screen, Screen.WildfireLikelihood)}
                      >
                        <Box direction="row" align="center" gap="xsmall">
                          <Text
                            color={
                              screen === Screen.WildfireLikelihood ? selectedColor : unselectedColor
                            }
                          >
                            Wildfire likelihood
                          </Text>
                        </Box>
                      </NavLink>
                    </Box>
                    <Box
                      flex={true}
                      align="center"
                      justify="center"
                      style={{ textAlign: "center" }}
                    >
                      <NavLink
                        className="header-footer-nav-links"
                        to={history.location.pathname.replace(screen, Screen.RiskReductionZones)}
                      >
                        <Box direction="row" align="center" gap="xsmall">
                          <Text
                            color={
                              screen === Screen.RiskReductionZones ? selectedColor : unselectedColor
                            }
                          >
                            Risk Reduction Zones
                          </Text>
                        </Box>
                      </NavLink>
                    </Box>
                    <Box
                      flex={true}
                      align="center"
                      justify="center"
                      style={{ textAlign: "center" }}
                    >
                      <NavLink
                        className="header-footer-nav-links"
                        to={history.location.pathname.replace(screen, Screen.VulnerablePopulations)}
                      >
                        <Box direction="row" align="center" gap="xsmall">
                          <Text
                            color={
                              screen === Screen.VulnerablePopulations
                                ? selectedColor
                                : unselectedColor
                            }
                          >
                            Vulnerable populations
                          </Text>
                        </Box>
                      </NavLink>
                    </Box>
                  </>
                )}
              </Grid>
              {isOverviewScreen || isSearchScreen ? (
                <Menu
                  dropBackground="white"
                  margin={{ left: "1.4rem" }}
                  alignSelf="end"
                  dropAlign={{ top: "bottom", right: "right" }}
                  icon={<MenuIcon tabIndex={0} />}
                  items={[
                    { label: "Home", href: "/" },
                    { label: "Understand Risk", href: "/understand-risk" },
                    { label: "Reduce Risk", href: "/reduce-risk" },
                    { label: "About", href: "/about" },
                    { label: "Contact", href: "/contact" }
                  ]}
                  tabIndex={-1}
                />
              ) : (
                ""
              )}
            </Box>
          </Header>
          {size !== "small" || isSearchScreen ? (
            ""
          ) : (
            <Box style={{ background: "#38312E", flexShrink: 0 }}>
              <Box id="headerSearchBox" style={{ width: "auto", margin: "5px" }}>
                <Search height="14" id="headerSearchBoxIcon" />
                <GeographySearchInput />
              </Box>
            </Box>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default withRouter(AppHeader);
