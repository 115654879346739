import mapboxgl from "mapbox-gl";
import {
  defaultLineOptions,
  getRPSFillSteps,
  populatedAreasMaskStyles,
  communityLabelStyles,
  tribalLabelStyles,
  countyLabelStyles,
  stateLabelStyles
} from "./mapStyles";
import { Screen, GeographyLevel } from "../models";

export default undefined;

interface LayerConfig {
  readonly layerId: string;
  readonly mapboxTilesetId: string;
  readonly sourceLayer?: string;
  readonly styles?: Partial<mapboxgl.SymbolLayer>;
}

interface LayerConfigSet {
  readonly [layer: string]: LayerConfig;
}

const levelToExpandedLayerId: Record<GeographyLevel, string> = {
  [GeographyLevel.community]: "expanded_community",
  [GeographyLevel.county]: "expanded_county",
  [GeographyLevel.tribal_area]: "expanded_tribal",
  [GeographyLevel.state]: "expanded_state"
};

const CENSUS_GEOS_TILESET = "headmin.he-census-2022-e";
const WRC_GEOS_YEAR = "2022";
const WRC_GEOS_TILESET = `headmin.he-wrc-geos-${WRC_GEOS_YEAR}`;

export const BOUNDARY_LAYERS: LayerConfigSet = {
  [GeographyLevel.state]: {
    // layerId is the internal ID for this layer. We use geography level to pick which to use,
    // so this needs to line up with the geolevel choices. Hence using the enum for the value.
    layerId: GeographyLevel.state,
    // mapboxTilesetId is the external (i.e. on the Mapbox account) tileset ID
    mapboxTilesetId: CENSUS_GEOS_TILESET,
    // sourceLayer is the layer name within the Mapbox tileset
    sourceLayer: "state"
  },
  [GeographyLevel.county]: {
    layerId: GeographyLevel.county,
    mapboxTilesetId: CENSUS_GEOS_TILESET,
    sourceLayer: "county"
  },
  [GeographyLevel.tribal_area]: {
    layerId: GeographyLevel.tribal_area,
    mapboxTilesetId: CENSUS_GEOS_TILESET,
    sourceLayer: "tribal"
  },
  [GeographyLevel.community]: {
    layerId: GeographyLevel.community,
    mapboxTilesetId: CENSUS_GEOS_TILESET,
    sourceLayer: "place"
  },
  [levelToExpandedLayerId[GeographyLevel.community]]: {
    layerId: levelToExpandedLayerId[GeographyLevel.community],
    mapboxTilesetId: WRC_GEOS_TILESET,
    sourceLayer: `place_2400m_${WRC_GEOS_YEAR}`
  },
  [levelToExpandedLayerId[GeographyLevel.tribal_area]]: {
    layerId: levelToExpandedLayerId[GeographyLevel.tribal_area],
    mapboxTilesetId: WRC_GEOS_TILESET,
    sourceLayer: `tribal_2400m_${WRC_GEOS_YEAR}`
  },
  [levelToExpandedLayerId[GeographyLevel.county]]: {
    layerId: levelToExpandedLayerId[GeographyLevel.county],
    mapboxTilesetId: WRC_GEOS_TILESET,
    sourceLayer: `county_2400m_${WRC_GEOS_YEAR}`
  },
  census_tracts: {
    layerId: "tract",
    mapboxTilesetId: CENSUS_GEOS_TILESET,
    sourceLayer: "tract"
  }
};

const POPULATED_AREA_LAYER: LayerConfig = {
  layerId: "PopulatedAreasMask",
  mapboxTilesetId: "headmin.wrc-popareasmask-2023"
};

const PLACE_LABEL_LAYERS: LayerConfigSet = {
  [GeographyLevel.community]: {
    layerId: "communities_labels",
    mapboxTilesetId: WRC_GEOS_TILESET,
    sourceLayer: `place_labels_${WRC_GEOS_YEAR}`,
    styles: communityLabelStyles
  },
  [GeographyLevel.tribal_area]: {
    layerId: "tribal_areas_labels",
    mapboxTilesetId: WRC_GEOS_TILESET,
    sourceLayer: `tribal_labels_${WRC_GEOS_YEAR}`,
    styles: tribalLabelStyles
  },
  [GeographyLevel.county]: {
    layerId: "county_labels",
    mapboxTilesetId: CENSUS_GEOS_TILESET,
    sourceLayer: "county-labels",
    styles: countyLabelStyles
  },
  [GeographyLevel.state]: {
    layerId: "state_labels",
    mapboxTilesetId: "mapbox.mapbox-streets-v8",
    sourceLayer: "place_label",
    styles: stateLabelStyles
  }
};

const DATA_LAYERS: LayerConfigSet = {
  // The RPS layer had to be split because it exceeded Mapbox's 25GB limit.
  // CONUS also includes HI. Those two areas had to include level-12 tiles because
  // the level-11 ones were based on level-10 tiles, to avoid simplification.
  // The Alaska file only goes to level 11 because it didn't require simplification,
  // but it works fine to display it level 12 by over-zooming.
  rpsConus: {
    layerId: "RPSconus",
    mapboxTilesetId: "headmin.wrc-rps-conus-2023",
    sourceLayer: "RPS"
  },
  rpsAK: {
    layerId: "RPSak",
    mapboxTilesetId: "headmin.wrc-rps-ak-2023",
    sourceLayer: "RPS"
  },
  rrz: {
    layerId: "RRZ",
    mapboxTilesetId: "headmin.wrc-rrz-2023"
  },
  bp: {
    layerId: "BP",
    mapboxTilesetId: "headmin.wrc-wflikelihood-2023"
  }
};

export const OVERLAY_IDS = {
  selectedLayerIdLine: "selected-line",
  selectedLayerIdFill: "selected-fill",
  riskCalculationAreaLine: "risk-calculation-area-line",
  tractsLayerBaseFill: "tracts-base-fill",
  tractsLayerBaseTexture: "tracts-base-texture",
  tractsHighlightedLayerIdLine: "tracts-highlighted-line",
  tractsHighlightedLayerIdOutline: "tracts-highlighted-outline",
  tractsUnhighlightedLayerIdLine: "tracts-unhighlighted-line",
  tractsLayerIdFill: "tracts-fill"
};

// Layers to put the boundary overlays (including the "selected" hightlight layer) just below.
// Both are layers in the base style, chosen by manual inspection and trial-and-error as close
// to the lowest layers that we want to still show up on top of our overlays.
// Layers inserted before the primary layer will occur above layers inserted before the
// secondary layer. This is to ensure we can insert the "selected" highlight layer above
// the fill for highlighted and unhighlighted tracts on the vulnerable population map.
// If the base map changed, these might need to change as well.
export const boundaryLayerInsertBeforePrimary = "settlement-subdivision-label";
export const boundaryLayerInsertBeforeSecondary = "state-innerlines";

// Helper function to determine where in the layer stack to add the data layers.
// The answer is simple, but not quite static: they should go right below the hillshade layer,
// except if the populated areas mask is loaded (it will be right below the hillshade
// layer because it uses this same function for placement) then they should go below that.
const dataLayerInsertBefore = (map: mapboxgl.Map) => {
  return map.getLayer(POPULATED_AREA_LAYER.layerId) ? POPULATED_AREA_LAYER.layerId : "hillshade";
};

export const loadBoundaryLayers = (map: mapboxgl.Map) => {
  Object.values(BOUNDARY_LAYERS).forEach(layer => {
    map.getSource(layer.mapboxTilesetId) ||
      map.addSource(layer.mapboxTilesetId, {
        type: "vector",
        url: `mapbox://${layer.mapboxTilesetId}`
      });
  });
};

export const addPlacesLabelLayers = (map: mapboxgl.Map) => {
  Object.values(PLACE_LABEL_LAYERS).forEach(layer => {
    map.getSource(layer.mapboxTilesetId) ||
      map.addSource(layer.mapboxTilesetId, {
        type: "vector",
        url: `mapbox://${layer.mapboxTilesetId}`
      });
    map.getLayer(layer.layerId) ||
      map.addLayer({
        id: layer.layerId,
        source: layer.mapboxTilesetId,
        "source-layer": layer.sourceLayer,
        type: "symbol",
        ...layer.styles
      } as mapboxgl.SymbolLayer);
  });
};

const _addSelectedBoundaryLayer = (
  map: mapboxgl.Map,
  geographyLevel: GeographyLevel,
  detailPlaceId: string,
  displayControlsAndLabels?: boolean
) => {
  const layerKey = geographyLevel;
  const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS[layerKey];

  // Matching our FIPS codes to the features in the vector tile layers requires a bit of
  // manipulation.
  // - The census layers don't have an "id" attribute, but have their FIPS codes
  //   as feature IDs (as integers).
  // - The feature IDs for states are right-padded with three zeroes.
  //
  // Also the 'filter' param to addLayer has to be mutable, so we can't make this a ReadonlyArray
  // tslint:disable-next-line readonly-array
  const filterExpr = [
    "==",
    ["id"],
    parseInt(detailPlaceId, 10) * (geographyLevel === GeographyLevel.state ? 1000 : 1)
  ];
  map.getLayer(OVERLAY_IDS.selectedLayerIdLine) && map.removeLayer(OVERLAY_IDS.selectedLayerIdLine);
  map.addLayer(
    {
      id: OVERLAY_IDS.selectedLayerIdLine,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      paint: {
        "line-color": "hsl(0, 0%, 0%)",
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // Syntax in pairs of two:
          // zoom level,
          // width in px,
          0,
          0,
          geographyLevel === "communities" || geographyLevel === "tribal_areas" ? 10 : 7,
          displayControlsAndLabels ? 3.5 : 2,
          12,
          3
        ]
      },
      filter: filterExpr
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforePrimary
  );
};

const _toggleRiskCalculationAreaLayer = (
  map: mapboxgl.Map,
  geographyLevel: GeographyLevel,
  detailPlaceId: string,
  show: boolean
) => {
  // There are actually two layers stacked on top of each other to get the styling how we want it.
  // Define ID variables for each, and clear them if they're already present.
  const rcaBaseLayerId = `${OVERLAY_IDS.riskCalculationAreaLine}-base`;
  const rcaDottedLayerId = `${OVERLAY_IDS.riskCalculationAreaLine}-dotted`;
  map.getLayer(rcaBaseLayerId) && map.removeLayer(rcaBaseLayerId);
  map.getLayer(rcaDottedLayerId) && map.removeLayer(rcaDottedLayerId);

  // If we're here to clear it and not draw a new one, just return.
  if (!show) {
    return;
  }

  const layerKey = levelToExpandedLayerId[geographyLevel];
  const { mapboxTilesetId, sourceLayer } = BOUNDARY_LAYERS[layerKey];

  // The expanded boundary layers have their geoid in an "id" attribute that's a string
  // (with no left-padding).
  const filterExpr = ["==", "id", String(parseInt(detailPlaceId, 10))];

  map.addLayer(
    {
      id: rcaBaseLayerId,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      paint: {
        "line-color": "hsla(0, 0%, 0%, 0.2)",
        "line-width": 3
      },
      filter: filterExpr
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforePrimary
  );
  map.addLayer(
    {
      id: rcaDottedLayerId,
      source: mapboxTilesetId,
      "source-layer": sourceLayer,
      ...defaultLineOptions,
      paint: {
        "line-color": "hsl(0, 0%, 100%)",
        "line-width": 2,
        "line-dasharray": [0.1, 2]
      },
      filter: filterExpr
    } as mapboxgl.LineLayer,
    boundaryLayerInsertBeforePrimary
  );
};

export const toggleSelectedLayer = (
  map: mapboxgl.Map,
  geographyName: string,
  geographyLevel: GeographyLevel,
  detailPlaceId: string,
  screen: Screen,
  displayControlsAndLabels?: boolean
) => {
  _addSelectedBoundaryLayer(map, geographyLevel, detailPlaceId, displayControlsAndLabels);

  // Risk calculation area
  const showRiskCalculationArea = !!(
    displayControlsAndLabels &&
    (screen === Screen.RiskToHomes || screen === Screen.WildfireLikelihood) &&
    geographyLevel !== GeographyLevel.state
  );
  _toggleRiskCalculationAreaLayer(map, geographyLevel, detailPlaceId, showRiskCalculationArea);

  // Selected place label
  if (displayControlsAndLabels) {
    const selectedPlaceLabelLayerConfig = PLACE_LABEL_LAYERS[geographyLevel];
    // In order to have all community labels in one layer but still allow to show/hide
    // different size communities at different zoom levels, the communities layer
    // shows/hides places by place size (scalerank) using the text-field attribute.
    // We want to show the selected place at all zoom levels, regardless of size. Hence,
    // this overwrites the text-field settings inherited from the labels layer.
    // We need to avoid mutating the label layer style, though, since that would affect
    // the regular communities label layer, so this uses the spread operator to make
    // new copies of the objects that need to change, rather than writing to the original.
    const selectedPlaceLabelStyles = { ...selectedPlaceLabelLayerConfig.styles };
    const selectedLayout = selectedPlaceLabelStyles.layout && {
      ...selectedPlaceLabelStyles.layout,
      "text-field":
        geographyLevel === "communities"
          ? ["to-string", ["get", "name"]]
          : selectedPlaceLabelStyles.layout["text-field"]
    };
    // The state layer doesn't have the ID property, so we have to filter by name for that one.
    // States and tribal areas have an "id" attribute, but we need to adjust our ID to account
    // for the lack of leading zeroes.
    // The county layer has the FIPS code as its object ID, but matching that requires a different
    // lookup, so it's simpler to just match by name, since we have it.
    const selectedPlaceFilterExpr =
      geographyLevel === GeographyLevel.state || geographyLevel === GeographyLevel.county
        ? ["match", ["get", "name"], [geographyName], true, false]
        : ["match", ["get", "id"], [String(parseInt(detailPlaceId, 10))], true, false];
    map.getLayer("selected-place-label") && map.removeLayer("selected-place-label");
    map.addLayer({
      id: "selected-place-label",
      source: selectedPlaceLabelLayerConfig.mapboxTilesetId,
      "source-layer": selectedPlaceLabelLayerConfig.sourceLayer,
      type: "symbol",
      ...selectedPlaceLabelStyles,
      layout: selectedLayout,
      minzoom: 0,
      maxzoom: 13,
      filter: selectedPlaceFilterExpr
    } as mapboxgl.SymbolLayer);
  }
};

export const toggleRPSLayer = (map: mapboxgl.Map, state: string, visible: boolean) => {
  // This add, removes, or updates the styles for the RPS layer.
  // Actually "layers", since the RPS tileset is in two parts due to Mapbox file size limits.
  // So this has all its functionality in a helper function that it defines then calls twice.
  const renderRPSLayer = (layerConf: LayerConfig) => {
    map.getLayer(layerConf.layerId)
      ? visible
        ? map.setPaintProperty(layerConf.layerId, "fill-color", getRPSFillSteps(state)) &&
          map.setLayoutProperty(layerConf.layerId, "visibility", "visible")
        : map.setLayoutProperty(layerConf.layerId, "visibility", "none")
      : visible &&
        (map.getSource(layerConf.mapboxTilesetId) ||
          (map.addSource(layerConf.mapboxTilesetId, {
            type: "vector",
            // For local tileserver-gl source, use this instead of 'url':
            // tiles: [`http://localhost:8080/data/${layerConf.mapboxTilesetId}/{z}/{x}/{y}.pbf`],
            url: `mapbox://${layerConf.mapboxTilesetId}`,
            maxzoom: 12
          }) &&
            map.addLayer(
              {
                id: layerConf.layerId,
                type: "fill",
                source: layerConf.mapboxTilesetId,
                "source-layer": layerConf.sourceLayer,
                paint: {
                  "fill-antialias": false,
                  "fill-color": getRPSFillSteps(state)
                } as mapboxgl.FillPaint
              },
              dataLayerInsertBefore(map)
            )));
  };
  renderRPSLayer(DATA_LAYERS.rpsConus);
  renderRPSLayer(DATA_LAYERS.rpsAK);
};

export const toggleRasterLayer = (
  map: mapboxgl.Map,
  visible: boolean,
  layer: LayerConfig,
  layerOpts: Partial<mapboxgl.Layer> = {}
) => {
  const { layerId, mapboxTilesetId } = layer;
  map.getLayer(layerId)
    ? visible
      ? map.setLayoutProperty(layerId, "visibility", "visible")
      : map.setLayoutProperty(layerId, "visibility", "none")
    : visible &&
      (map.getSource(mapboxTilesetId) ||
        (map.addSource(mapboxTilesetId, {
          type: "raster",
          url: `mapbox://${mapboxTilesetId}`,
          maxzoom: 12
        }) &&
          map.addLayer(
            {
              id: layerId,
              source: mapboxTilesetId,
              type: "raster",
              ...layerOpts
            } as mapboxgl.RasterLayer,
            dataLayerInsertBefore(map)
          )));
};

export const toggleRRZLayer = (map: mapboxgl.Map, visible: boolean) => {
  toggleRasterLayer(map, visible, DATA_LAYERS.rrz);
};

export const toggleBPLayer = (map: mapboxgl.Map, visible: boolean) => {
  toggleRasterLayer(map, visible, DATA_LAYERS.bp);
};

// Show or hide the layer that highlights populated areas by putting a translucent mask
// over everything else.
export const togglePopulatedAreaLayer = (map: mapboxgl.Map, visible: boolean) => {
  toggleRasterLayer(map, visible, POPULATED_AREA_LAYER, populatedAreasMaskStyles);
};
