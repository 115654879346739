import { Box, BoxProps, ResponsiveContext } from "grommet";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Geography,
  GeographyLevel,
  Screen,
  StateGeography,
  BaseMapLayer,
  wildfireLikelihoodColorBreaks,
  riskToHomesColors,
  RRZClass
} from "../models";
import { setSelectedModalTract, setSelectedPanelTract } from "../actions/vulnerablePopulations";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import {
  loadBoundaryLayers,
  addPlacesLabelLayers,
  toggleRPSLayer,
  toggleRRZLayer,
  toggleBPLayer,
  toggleSelectedLayer,
  togglePopulatedAreaLayer
} from "./mapLayers";
import {
  toggleCensusTractsLayer,
  toggleNoTractsMeetCriteriaPopUp
} from "./mapLayersVulnerablePopulation";
import { defaultMapOptions, miniMapOptions } from "./mapStyles";
import PopulatedAreaMaskToggleControl from "./PopulatedAreaMaskControl";
import VulnerablePopulationsWidget from "./VulnerablePopWidget";
import VulnerablePopMapSelector from "./VulnerablePopMapSelector";
import VulnerablePopulationsTractDetailPanel from "./VulnerablePopTractDetailPanel";
import MapLegend from "./MapLegend";
import { isWindowNarrow } from "../utils";
import store from "../store";
import { rrzColors } from "../constants";

/* tslint:disable-next-line:no-object-mutation */
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || "";

type MapNarrowness = "NARROW" | "WIDE";

export type ReactIControl = mapboxgl.IControl & { readonly render: (props: any) => HTMLDivElement };

interface Props {
  readonly geographyLevel: GeographyLevel;
  readonly detailPlaceId: string;
  readonly geography: Geography;
  readonly mapStyleStateAbbrev: string | undefined;
  readonly stateGeography: StateGeography | undefined;
  readonly screen: Screen;
  readonly showPopulatedAreaMask: boolean;
  readonly mapContainerId: string;
  readonly displayControlsAndLabels: boolean;
  readonly selectedBaseMapLayer?: BaseMapLayer;
  readonly vulnerablePopulations?: VulnerablePopulationsState;
}

// Helper function to trigger effect-driven map changes.
// Waiting for a map `idle` event is the only consistent way we've been able to get them to
// load at the appropriate time.  Using a `load` event doesn't quite work because there's
// still a chance the referenced layers won't have loaded, which will result in an exception.
const runOnMapIdle = (map: mapboxgl.Map, action: (map: mapboxgl.Map) => void) => {
  map && (map.isStyleLoaded() ? action(map) : map.once("idle", () => action(map)));
};

const Map = ({
  geography,
  mapStyleStateAbbrev,
  stateGeography,
  screen,
  geographyLevel,
  detailPlaceId,
  showPopulatedAreaMask,
  mapContainerId,
  displayControlsAndLabels,
  selectedBaseMapLayer,
  vulnerablePopulations,
  ...props
}: Props & BoxProps) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const size = useContext(ResponsiveContext);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [populatedAreasToggle, setPopulatedAreasToggle] = useState<mapboxgl.IControl | null>(null);
  const [vulnerablePopWidget, setVulnerablePopWidget] = useState<mapboxgl.IControl | null>(null);
  const [vulnerablePopMapSelector, setVulnerablePopMapSelector] = useState<ReactIControl | null>(
    null
  );
  const [vulnerablePopTractPanel, setVulnerablePopTractPanel] = useState<mapboxgl.IControl | null>(
    null
  );
  const [mapLegend, setMapLegend] = useState<ReactIControl | null>(null);
  const [mapWidth, setMapWidth] = useState<MapNarrowness>("WIDE");
  const bufferBounds = ([swLon, swLat, neLon, neLat]: readonly number[], bufferDeg: number) => {
    // Assume Northern / Western hemispheres because this is a US-specific app.  The typecast is
    // necessary because returning a four-element array results in an auto-detected return type of
    // number[], while map.setMaxBounds() requires [number, number, number, number].
    /* tslint:disable-next-line: readonly-array */
    return [swLon - bufferDeg, swLat - bufferDeg, neLon + bufferDeg, neLat + bufferDeg] as [
      number,
      number,
      number,
      number
    ];
  };

  // Initialize the map
  useEffect(() => {
    const mapStyle = displayControlsAndLabels ? defaultMapOptions : miniMapOptions;
    const map = new mapboxgl.Map({
      ...mapStyle,
      container: mapContainerId
    });

    map.getCanvas().tabIndex = -1;

    // Create an HTML element with the necessary classes to be used as a container for a Mapbox GL
    // IControl
    const createMapCtrlContainer = (cssClasses: readonly string[]) => {
      const containerEl = document.createElement("div");
      containerEl.classList.add(...cssClasses);
      return containerEl;
    };
    // create containers for populated area mask toggle, vulnerable population widget, map legend
    const popAreasToggleCtrl = PopulatedAreaMaskToggleControl(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth"
      ])
    );
    const vulnerablePopSelectionWidget = VulnerablePopulationsWidget(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth",
        "vulnerable-populations-widget"
      ]),
      geographyLevel
    );
    const vulnerablePopulationsMapLayerSelector = VulnerablePopMapSelector(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth",
        "vulnerable-populations-map-selector"
      ])
    );
    const vulnerablePopTractPanel = VulnerablePopulationsTractDetailPanel(
      createMapCtrlContainer([
        "mapboxgl-ctrl",
        "mapboxgl-input",
        "mapboxgl-ctrl-group",
        "mapboxgl-input-autowidth",
        "vulnerable-populations-tract-detail-panel"
      ])
    );
    // MapLegend takes props rather than connecting directly to state
    const mapLegendCtrl = MapLegend(
      createMapCtrlContainer(["mapboxgl-ctrl", "mapboxgl-ctrl-group", "mapboxgl-input-autowidth"]),
      {}
    );
    // Reset the state variables that are used to control the widget and tract details components
    // on the Vulnerable Populations tab, since they could still be set from viewing a different
    // place.
    store.dispatch(setSelectedPanelTract(undefined));
    store.dispatch(setSelectedModalTract(undefined));

    const onLoad = () => {
      loadBoundaryLayers(map);
      if (displayControlsAndLabels) {
        map.addControl(
          new mapboxgl.ScaleControl({ maxWidth: 100, unit: "imperial" }),
          "bottom-right"
        );
        map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "bottom-right");
        addPlacesLabelLayers(map);
      }
    };
    map.once("load", onLoad);
    if (displayControlsAndLabels) {
      setPopulatedAreasToggle(popAreasToggleCtrl);
      setVulnerablePopWidget(vulnerablePopSelectionWidget);
      setVulnerablePopMapSelector(vulnerablePopulationsMapLayerSelector);
      setVulnerablePopTractPanel(vulnerablePopTractPanel);
      setMapLegend(mapLegendCtrl);
    }
    setMap(map);
    // remove zoom on scroll for easier navigation of detail page
    map.scrollZoom.disable();
  }, [mapContainerId, displayControlsAndLabels, geographyLevel]);

  // Listen to window width changes
  useEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;
      if (isWindowNarrow(width)) {
        setMapWidth("NARROW");
      } else {
        setMapWidth("WIDE");
      }
    };
    window.addEventListener("resize", handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Listen to window width changes for the purposes of adding/removing listeners
  // to control the vulnerable populations modal on mobile or panel on desktop
  useEffect(() => {
    size === "small"
      ? store.dispatch(setSelectedPanelTract(undefined))
      : store.dispatch(setSelectedModalTract(undefined));
  }, [size]);

  // Set max bounds when the state or comparison level changes
  useEffect(() => {
    // Don't set max bounds if the user views a state directly because we'll be using national
    // breaks. Passing undefined to map.setMaxBounds() clears any previously set max bounds.
    // Buffering by a degree on all sides seems to provide a decent amount of play in the bounds
    // without allowing the user to stray too far from the state they're in.
    const maxBounds =
      stateGeography && stateGeography.bounds && mapStyleStateAbbrev !== "US"
        ? bufferBounds(stateGeography.bounds, 1.0)
        : undefined;
    map && map.setMaxBounds(maxBounds);
  }, [map, stateGeography, mapStyleStateAbbrev]);

  // Zoom to geometry when it changes
  useEffect(() => {
    const bounds = geography.bounds;
    map &&
      map.fitBounds(bounds as mapboxgl.LngLatBoundsLike, {
        maxZoom: defaultMapOptions.maxZoom,
        padding: 25,
        duration: 0
      });
  }, [map, geography, detailPlaceId, geographyLevel]);

  // Show / hide the toggle control for turning the populated areas mask layer on and off
  // This needs to be before the map legend useEffect in order to keep this IControl above the
  // legend when they are vertically stacked.
  // It's only shown for the Risk to Homes and Wildfire Likelihood tabs.
  useEffect(() => {
    map &&
      populatedAreasToggle &&
      (screen === Screen.RiskToHomes || screen === Screen.WildfireLikelihood
        ? map.addControl(populatedAreasToggle, mapWidth === "WIDE" ? "top-right" : "top-left")
        : map.removeControl(populatedAreasToggle));
  }, [map, screen, mapWidth, populatedAreasToggle]);

  // Show / hide the Vulnerable Populations widget and panel
  // The control of the widget needs to be above the useEffect for the base map selector so the
  // widget will stay above the selector when they are vertically stacked.
  useEffect(() => {
    map &&
      vulnerablePopWidget &&
      (!vulnerablePopulations?.selectedPanelTract && screen === Screen.VulnerablePopulations
        ? map.addControl(vulnerablePopWidget, "top-left")
        : map.removeControl(vulnerablePopWidget));

    map &&
      vulnerablePopTractPanel &&
      (vulnerablePopulations?.selectedPanelTract && screen === Screen.VulnerablePopulations
        ? map.addControl(vulnerablePopTractPanel, "top-left")
        : map.removeControl(vulnerablePopTractPanel));
  }, [map, screen, vulnerablePopTractPanel, vulnerablePopWidget, vulnerablePopulations]);

  useEffect(() => {
    map &&
      vulnerablePopMapSelector &&
      (screen === Screen.VulnerablePopulations
        ? map.addControl(vulnerablePopMapSelector, mapWidth === "NARROW" ? "top-left" : "top-right")
        : map.removeControl(vulnerablePopMapSelector));
  }, [map, screen, mapWidth, vulnerablePopMapSelector]);

  // remove toggleNoTractsMeetCriteriaPopUp when on a detail page besides Vulnerable Populations
  useEffect(() => {
    map &&
      displayControlsAndLabels &&
      [Screen.RiskToHomes, Screen.WildfireLikelihood, Screen.RiskReductionZones].includes(screen) &&
      toggleNoTractsMeetCriteriaPopUp(map, false);
  }, [map, screen, displayControlsAndLabels]);

  // Display appropriate map legend in the proper position depending on page state
  useEffect(() => {
    // Define the props for different legends once so that they can be mixed and matched in the
    // Vulnerable Populations case without repeating ourselves too much.
    const riskToHomesProps = {
      rampLegend: {
        colors: riskToHomesColors.filter(c => !c.hideInLegend).map(c => c.value),
        legendLabel: "Wildfire risk to homes",
        lowLabel: "Less risk",
        highLabel: "More risk"
      }
    };
    const likelihoodProps = {
      rampLegend: {
        colors: wildfireLikelihoodColorBreaks.filter(c => !c.hideInLegend).map(c => c.value),
        legendLabel: "Wildfire likelihood",
        lowLabel: "Less likely",
        highLabel: "More likely"
      }
    };
    const rrzProps = {
      classLegend: {
        classes: [
          {
            color: rrzColors[RRZClass.Minimal],
            label: RRZClass.Minimal,
            definition: "Homes are not likely to be subjected to wildfire."
          },
          {
            color: rrzColors[RRZClass.Indirect],
            label: RRZClass.Indirect,
            definition:
              "Homes may be ignited by indirect sources such as embers and home-to-home ignition."
          },
          {
            color: rrzColors[RRZClass.Direct],
            label: RRZClass.Direct,
            definition:
              "Homes may be ignited by adjacent flammable vegetation, as well as indirect sources."
          },

          {
            color: rrzColors[RRZClass.Transmission],
            label: RRZClass.Transmission,
            definition: "Area near homes where flammable vegetation may expose homes to wildfire."
          }
        ]
      },
      noLabel: true,
      compact: mapWidth === "NARROW"
    };
    // The vulnerable populations screen is special because it can display multiple layers.
    // So we can handle the easy cases (i.e., any other screen *besides* Vulnerable Populations)
    // first.
    if (map && mapLegend && screen !== Screen.VulnerablePopulations) {
      map.addControl(mapLegend, "top-left");
      switch (screen) {
        case Screen.RiskToHomes:
          mapLegend.render(riskToHomesProps);
          break;
        case Screen.WildfireLikelihood:
          mapLegend.render(likelihoodProps);
          break;
        case Screen.RiskReductionZones:
          mapLegend.render(rrzProps);
          break;
        default:
          break;
      }
    } else if (map && vulnerablePopMapSelector && screen === Screen.VulnerablePopulations) {
      mapLegend && map.removeControl(mapLegend);
      const sharedProps = {
        isVulPopNarrowScreen: mapWidth === "NARROW"
      };
      switch (selectedBaseMapLayer) {
        case BaseMapLayer.riskToHomes:
          vulnerablePopMapSelector.render({
            ...sharedProps,
            ...riskToHomesProps
          });
          break;
        case BaseMapLayer.wildfireLikelihood:
          vulnerablePopMapSelector.render({
            ...sharedProps,
            ...likelihoodProps
          });
          break;
        case BaseMapLayer.riskReductionZones:
          vulnerablePopMapSelector.render({
            ...sharedProps,
            ...rrzProps
          });
          break;
        default:
          vulnerablePopMapSelector.render({
            ...sharedProps
          });
      }
    }
  }, [map, mapLegend, vulnerablePopMapSelector, mapWidth, screen, selectedBaseMapLayer]);

  // Toggle the selected layer
  useEffect(() => {
    const doToggle = (map: mapboxgl.Map) => {
      toggleSelectedLayer(
        map,
        geography.name,
        geographyLevel,
        detailPlaceId,
        screen,
        displayControlsAndLabels
      );
    };
    map && runOnMapIdle(map, doToggle);
  }, [map, geography.name, geographyLevel, detailPlaceId, screen, displayControlsAndLabels]);

  // Load/show the corresponding layer when a screen is selected, and hide the others.
  useEffect(() => {
    const toggleLayers = (map: mapboxgl.Map) => {
      toggleRPSLayer(
        map,
        mapStyleStateAbbrev || "US",
        screen === Screen.RiskToHomes ||
          (screen === Screen.VulnerablePopulations &&
            selectedBaseMapLayer === BaseMapLayer.riskToHomes)
      );
      toggleRRZLayer(
        map,
        screen === Screen.RiskReductionZones ||
          (screen === Screen.VulnerablePopulations &&
            selectedBaseMapLayer === BaseMapLayer.riskReductionZones)
      );
      toggleBPLayer(
        map,
        screen === Screen.WildfireLikelihood ||
          (screen === Screen.VulnerablePopulations &&
            selectedBaseMapLayer === BaseMapLayer.wildfireLikelihood)
      );
    };
    map && runOnMapIdle(map, toggleLayers);
  }, [
    map,
    screen,
    mapStyleStateAbbrev,
    selectedBaseMapLayer,
    vulnerablePopulations,
    detailPlaceId,
    displayControlsAndLabels,
    size
  ]);

  // Load/show the volnerable population layers accordingly
  useEffect(() => {
    const toggle = (map: mapboxgl.Map) => {
      if (
        vulnerablePopulations &&
        "resource" in vulnerablePopulations.tractInfo &&
        detailPlaceId in vulnerablePopulations.tractInfo.resource.tractInfo.geos
      ) {
        const {
          tractInfo: {
            resource: { tractInfo }
          }
        } = vulnerablePopulations;
        toggleCensusTractsLayer(
          map,
          screen === Screen.VulnerablePopulations,
          tractInfo,
          vulnerablePopulations,
          detailPlaceId,
          size,
          displayControlsAndLabels
        );
        return;
      }
    };
    map && runOnMapIdle(map, toggle);
  }, [map, screen, vulnerablePopulations, detailPlaceId, size, displayControlsAndLabels]);

  useEffect(() => {
    const doToggle = (map: mapboxgl.Map) => {
      togglePopulatedAreaLayer(
        map,
        showPopulatedAreaMask &&
          (screen === Screen.RiskToHomes || screen === Screen.WildfireLikelihood)
      );
    };
    map && runOnMapIdle(map, doToggle);
  }, [map, screen, showPopulatedAreaMask]);

  return (
    <Box
      id={mapContainerId}
      ref={mapRef}
      align="stretch"
      justify="start"
      flex={true}
      {...props}
      margin={
        displayControlsAndLabels ? { top: "20px", bottom: "0px" } : { top: "0px", bottom: "0px" }
      }
    />
  );
};

export default Map;
