import { TextInput, ResponsiveContext } from "grommet";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { geographyListClear, geographyListFetch } from "../actions/geographyList";
import { Screen } from "../models";
import { State } from "../reducers";
import { GeographyListState } from "../reducers/geographyList";
import styled from "styled-components";
import store from "../store";

const GeographySuggestion = styled.div`
  padding: 0.2rem 0.6rem;
  display: flex;
`;

const GeographyName = styled.div`
  color: #333;
  font-size: 1.5rem;
  flex: 1;
`;

const GeographyType = styled.div`
  font-size: 1.3rem;
  text-transform: uppercase;
  padding-left: 2rem;
`;

interface StateProps {
  readonly geographyList: GeographyListState;
}

const GeographySearchInput = ({
  geographyList,
  history,
  match
}: StateProps & RouteComponentProps<{ readonly screen: Screen }>) => {
  const [inputValue, setInputValue] = useState("");
  /* Turn the structured geography list into an array to use for searching and redirecting */
  const geographySearchList = "resource" in geographyList ? geographyList.resource.searchList : [];
  useEffect(() => {
    if (inputValue === "") {
      store.dispatch(geographyListClear());
      return;
    }

    // debounce search
    const t = setTimeout(() => {
      store.dispatch(geographyListFetch(inputValue));
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [inputValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const getSuggestions = () => {
    return geographySearchList.map(term => ({
      label: (
        <GeographySuggestion>
          <GeographyName>{term.searchText}</GeographyName>
          <GeographyType>{term.type}</GeographyType>
        </GeographySuggestion>
      ),
      value: term
    }));
  };
  const isStartScreen = Object.keys(match.params).length === 0;
  const isOverviewScreen = match.params.screen === Screen.Overview;
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <TextInput
          id="geographySearchBox"
          size="medium"
          dropHeight="medium"
          value={inputValue}
          onChange={onChange}
          placeholder={
            !(isStartScreen || isOverviewScreen)
              ? "New search"
              : size === "small"
              ? "Search by community"
              : "Search by community, tribal area, county, or state"
          }
          suggestions={getSuggestions()}
          defaultSuggestion={0}
          onSuggestionSelect={({ suggestion }) => {
            const path = [
              suggestion.value.state,
              suggestion.value.type === "tribal area"
                ? suggestion.value.tribal_area
                : suggestion.value.counties.join("|"),
              suggestion.value.community
            ]
              .filter(term => term)
              .join("/");
            setInputValue("");
            // Always go to overview, even if searching from a detail page
            const url = `/${Screen.Overview}/${path}/`;
            history.push(url);
            store.dispatch(geographyListClear());
          }}
          autoFocus={isStartScreen}
        />
      )}
    </ResponsiveContext.Consumer>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  geographyList: state.geographyList
});

export default connect(mapStateToProps)(withRouter(GeographySearchInput));
