import { Box, Grommet, Text, Select } from "grommet";
import { createRoot } from "react-dom/client";
import { connect, Provider } from "react-redux";
import { State } from "../reducers";
import { BaseMapLayer } from "../models";
import { setSelectedBaseMapLayer } from "../actions/vulnerablePopulations";
import CaretIcon from "../icons/CaretIcon";
import { ReactIControl } from "./Map";
import CustomGrommetTheme from "../grommet-theme";
import { MapLegendComponent, MapLegendComponentProps } from "./MapLegend";

import store from "../store";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const WidgetTitles = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  color: #000000;
`;

interface StateProps {
  readonly selectedBaseMapLayer: BaseMapLayer;
}

const Selector = ({
  selectedBaseMapLayer,
  ...legendProps
}: StateProps & MapLegendComponentProps) => {
  const setSelectedBaseMap = (selectedBaseMapLayer: BaseMapLayer) => {
    store.dispatch(setSelectedBaseMapLayer(selectedBaseMapLayer));
  };

  const selectorLegendProps = {
    ...legendProps,
    noPad: true,
    noLabel: true,
    compact: true
  };

  return (
    <Grommet theme={CustomGrommetTheme} style={{ backgroundColor: "white", borderRadius: "4px" }}>
      <Box margin="10px" width="205px">
        <WidgetTitles>Basemap</WidgetTitles>
        <Box style={{ border: "1px solid #CECECE", borderRadius: "2px" }}>
          <Select
            options={Object.values(BaseMapLayer)}
            value={selectedBaseMapLayer}
            valueLabel={<Text size={"14px"}>{selectedBaseMapLayer}</Text>}
            onChange={option => {
              logGoogleAnalyticsEvent("vulnerable populations", "basemap selection", option.value);
              setSelectedBaseMap(option.value);
            }}
            icon={<CaretIcon size="12px" />}
          />
        </Box>
        <MapLegendComponent {...selectorLegendProps} />
      </Box>
    </Grommet>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  selectedBaseMapLayer: state.vulnerablePopulations.selectedBaseMapLayer
});

const MapSelectorComponent = connect(mapStateToProps)(Selector);

interface ComponentProps {
  readonly isVulPopNarrowScreen?: boolean;
}

const VulnerablePopulationsMapSelector = (
  el: HTMLDivElement,
  props?: ComponentProps
): ReactIControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(
        <Provider store={store}>
          <MapSelectorComponent {...props} />
        </Provider>
      );
      return el;
    },
    onRemove: () => {
      // adjust the style of the map selector container when it is removed to accommodate
      // spacing of other map elements
      el.style.marginTop = "0px";
      root.render(undefined);
    },
    // This is not part of the IControl interface; this is here so we can re-render when the props
    // change.
    render: (props: ComponentProps) => {
      // the map selector is given a larger top margin if the vulnerable populations map is on a narrow
      // screen to avoid collision with the vulnerable populations widget
      /* tslint:disable:no-object-mutation */
      props.isVulPopNarrowScreen ? (el.style.marginTop = "50px") : (el.style.marginTop = "10px");
      /* tslint:enable:no-object-mutation */
      root.render(
        <Provider store={store}>
          <MapSelectorComponent {...props} />
        </Provider>
      );
      return el;
    }
  };
};

export default VulnerablePopulationsMapSelector;
