import React, { useMemo } from "react";
import { Image, ResponsiveContext } from "grommet";
import reduce from "lodash/reduce";
import { ScatterPlot } from "@nivo/scatterplot";
import { Scale } from "@nivo/scales";
import { scaleLog } from "d3-scale";
import { MinimumChartBP, RiskToHomesChartData, RiskToHomesData } from "../models";
import { ellipsizeName, ordinal } from "../utils";
import {
  Chart,
  ChartContainer,
  ChartCorner,
  ChartLeft,
  ChartRight,
  ChartXAxis,
  ChartYAxis,
  TextRotate,
  Tooltip,
  TooltipProperty,
  TooltipTitle,
  TooltipValue
} from "./Chart";
import GlossaryLink from "./GlossaryLink";

interface StateProps {
  readonly detailPlaceId: string;
  readonly geography: string;
  readonly minBubbleSize: number;
  readonly maxBubbleSize: number;
  readonly riskToHomesChartData: RiskToHomesChartData;
}

const chartTheme = {
  axis: {
    ticks: {
      text: {
        fontSize: 0
      }
    }
  },
  annotations: {
    text: {
      fontSize: "14px",
      weight: 400,
      fontWeight: 400,
      fontFamily: "lato, sans-serif",
      color: "#333"
    }
  }
};

/** Risk to homes scatterplot
 *
 * x-axis is bp_mean (likelihood)
 * y-axis is crps_mean (risk to homes if a fire occurs)
 * dot size is total_housing_units (number of housing units)
 * dot color is rps_mean
 *     five breaks: <40, 40-70, 70-90, 90-95, 95+
 */
const RiskToHomesChart = ({
  detailPlaceId,
  geography,
  minBubbleSize,
  maxBubbleSize,
  riskToHomesChartData
}: StateProps) => {
  // The (custom computed) `color` property of the datum is used for the chart dots.
  // nivo sends `any` type
  const generateColor = (item: any): string => {
    return "color" in item ? item.color : "";
  };
  const filteredChartData = useMemo(
    () => riskToHomesChartData.chartData.filter(val => val.x > MinimumChartBP),
    [riskToHomesChartData]
  );
  const generateTooltip = useMemo(
    () =>
      (item: any): JSX.Element => {
        // nivo's type definitions require that the tooltip function be (any => JSX.Element),
        // so we have to destructure and apply the type in here.
        const {
          node: { data }
        } = item as { readonly node: { readonly data: RiskToHomesData } };
        const rpsDisplay = Math.round(data.rpsPctRank * 100);
        return (
          <Tooltip>
            <TooltipTitle>{data.name}</TooltipTitle>
            <TooltipProperty>
              {rpsDisplay}
              {ordinal(rpsDisplay)} percentile
            </TooltipProperty>
            <TooltipValue>Risk to homes</TooltipValue>
          </Tooltip>
        );
      },
    []
  );
  function generateEmptyTooltip(item: any): JSX.Element {
    return <span />;
  }
  const detailPlace = filteredChartData.filter(d => d.key === detailPlaceId);
  const positionAnnotation =
    detailPlace && detailPlace[0] && detailPlace[0].x
      ? scaleLog()
          .base(7)
          .domain([riskToHomesChartData.bpMin, riskToHomesChartData.bpMax])
          .range([0, 100])(detailPlace[0].x) > 50
        ? "left"
        : "right"
      : "left";
  const currentDataId = useMemo(() => {
    return reduce(
      filteredChartData,
      (result, key, value) => {
        // nivo constructs identifiers as {group ID}.{offset in data array in group}
        return result ? result : key.key === detailPlaceId ? `RPS.${value}` : result;
      },
      ""
    );
  }, [detailPlaceId, filteredChartData]);
  const note = ellipsizeName(geography);

  return (
    <Chart>
      <ChartLeft>
        <ChartYAxis>
          <TextRotate>
            <GlossaryLink term="consequence">Wildfire consequence</GlossaryLink>{" "}
            <Image
              height="9px"
              style={{ marginLeft: "4px", position: "relative", top: "1px" }}
              a11yTitle="Arrow pointing up"
              src={process.env.PUBLIC_URL + "/legend-arrow.svg"}
            />
          </TextRotate>
        </ChartYAxis>
        <ChartCorner />
      </ChartLeft>
      <ChartRight>
        <ChartContainer>
          <ResponsiveContext.Consumer>
            {size => {
              return (
                <ScatterPlot
                  // nivo allows for readable data series
                  // tslint:disable-next-line readonly-array
                  data={[{ id: "RPS", data: filteredChartData as any[] }]}
                  width={size === "small" ? Math.min(window.innerWidth, 490) : 490}
                  height={400}
                  margin={{ top: 30, right: size === "small" ? 35 : 60, bottom: 25, left: 35 }}
                  theme={chartTheme}
                  xScale={
                    {
                      type: "log",
                      base: 7,
                      min: riskToHomesChartData.bpMin,
                      max: riskToHomesChartData.bpMax
                    } as any as Scale
                  }
                  yScale={
                    {
                      type: "linear",
                      min: riskToHomesChartData.crpsMin - 1,
                      max: riskToHomesChartData.crpsMax + 1
                    } as any as Scale
                  }
                  blendMode="normal"
                  animate={false}
                  enableGridY={false}
                  enableGridX={false}
                  axisTop={null}
                  tooltip={size === "small" ? generateEmptyTooltip : generateTooltip}
                  colors={generateColor}
                  axisRight={null}
                  axisLeft={{}}
                  axisBottom={{
                    tickRotation: 45,
                    format: (v: any) => `${v.toLocaleString("en-US")}`,
                    // Ten ticks, at equal intervals across the range of values.
                    // If the range data hasn't been populated yet, uses a placeholder (which never
                    // shows up, but prevents console errors).
                    tickValues: riskToHomesChartData.bpMax
                      ? Array.from(Array(10)).map(
                          (v, i, arr) =>
                            riskToHomesChartData.bpMin +
                            i *
                              ((riskToHomesChartData.bpMax - riskToHomesChartData.bpMin) /
                                (arr.length - 1))
                        )
                      : 10
                  }}
                  nodeSize={{
                    key: "total_housing_units",
                    values: [riskToHomesChartData.teMin, riskToHomesChartData.teMax],
                    sizes: [minBubbleSize, maxBubbleSize]
                  }}
                  renderNode={({
                    x,
                    y,
                    size,
                    color
                  }: {
                    readonly x: number;
                    readonly y: number;
                    readonly size: number;
                    readonly color: string;
                  }) => {
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <circle r={size / 2} fill={color} stroke="#000" strokeWidth="0.5" />
                      </g>
                    );
                  }}
                  // @ts-ignore
                  annotations={[
                    {
                      type: "circle",
                      match: { id: currentDataId },
                      noteX: positionAnnotation === "left" ? -20 : 20,
                      noteY: -20,
                      offset: size === "small" ? 6 : 3,
                      noteTextOffset: -16,
                      // nivo doesn't do a good job handling a variety of label widths. This
                      // calculation is trying to use the number of characters in the label to
                      // figure out a size that looks good.
                      noteWidth: size === "small" ? note.length * 5 : note.length * 7,
                      note
                    }
                  ]}
                />
              );
            }}
          </ResponsiveContext.Consumer>
        </ChartContainer>
        <ChartXAxis>
          <GlossaryLink term="wildfire_likelihood">Wildfire likelihood</GlossaryLink>{" "}
          <Image
            height="9px"
            style={{ marginLeft: "4px", position: "relative", top: "1px" }}
            a11yTitle="Arrow pointing to the right"
            src={process.env.PUBLIC_URL + "/legend-arrow.svg"}
          />
        </ChartXAxis>
      </ChartRight>
    </Chart>
  );
};

export default RiskToHomesChart;
