import { Paragraph } from "grommet";
import React, { useMemo } from "react";
import { ComparisonLevel, GeographyLevel, Ranks, Screen } from "../models";
import GlossaryLink from "./GlossaryLink";
import { PercentileComparisonText } from "./PercentileComparisonText";

interface ComponentProps {
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly ranks: Ranks | undefined;
}

const WildfireLikelihoodComparisonText = (props: ComponentProps) => {
  const {
    geography,
    geographyLevel,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    ranks
  } = props;

  const comparisonText = useMemo(() => {
    const bpPctRank = !ranks
      ? undefined
      : comparisonLevel === ComparisonLevel.county && ranks.bp_within_county !== undefined
      ? Math.round(100 * ranks.bp_within_county)
      : comparisonLevel === ComparisonLevel.state && ranks.bp_within_state !== undefined
      ? Math.round(100 * ranks.bp_within_state)
      : Math.round(100 * ranks.bp_within_nation);
    const comparisonGeographyName =
      comparisonLevel === ComparisonLevel.county
        ? comparisonCountyName
        : comparisonLevel === ComparisonLevel.state
        ? comparisonStateName
        : "the US";

    return bpPctRank === undefined ? (
      // Avoids possibly flashing the wrong text while bpPctRank is being recalculated
      <></>
    ) : (
      <PercentileComparisonText
        screen={Screen.WildfireLikelihood}
        comparisonGeographyName={comparisonGeographyName}
        geography={geography}
        geographyLevel={geographyLevel}
        percentileRank={bpPctRank}
        quantityLabel={<GlossaryLink term="wildfire_likelihood">wildfire likelihood</GlossaryLink>}
      />
    );
  }, [
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    geographyLevel,
    geography,
    ranks
  ]);

  return (
    <>
      {geography && (
        <Paragraph size="large" color="text" margin={{ top: "0", bottom: "small" }} fill={true}>
          {comparisonText}
        </Paragraph>
      )}
    </>
  );
};

export default WildfireLikelihoodComparisonText;
