import { Box, Grid, Header, Heading, Image, ResponsiveContext, Text } from "grommet";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import GeographyDescription from "../components/GeographyDescription";
import Map from "../components/Map";
import { Screen, RiskCardDisplay } from "../models";
import { RiskParams } from "../hooks";
import { makeResponsiveValue } from "../utils";
import ActionCards from "../components/ActionCards";
import AppFooter from "../components/AppFooter";
import RiskIcon from "../components/RiskIcon";
import AngleRightIcon from "../icons/AngleRightIcon";
import { horizontalPadding } from "../constants";
import { logGoogleAnalyticsEvent } from "../components/GoogleAnalytics";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`;

const StyledHeader = styled(Header)`
  font-size: 1.5rem !important;
  border-bottom: 1px solid #e9e5e2;
  z-index: 20;
`;

const RiskCard = styled.div`
  display: flex;
  background-color: white;
  border: solid #e6e6e6;
  border-width: thin;
  min-height: 310px;
`;

const UnderstandRiskContainer = styled(Box)`
  overflow-x: auto;
  overflow-y: auto;
  flex-shrink: 0;
  padding-top: 52px;
  border-bottom: 1px solid #e9e5e2;
`;

const LearnMoreBracket = styled(AngleRightIcon)`
  margin-top: 3px;
`;

type OverviewRiskParams = Pick<
  RiskParams,
  | "geography"
  | "detailGeography"
  | "detailGeographyId"
  | "countyGeographies"
  | "stateGeography"
  | "geographyLevel"
  | "mapStyleStateAbbrev"
  | "vulnerablePopulations"
>;

const OverviewScreen = ({
  history,
  geography,
  detailGeographyId,
  detailGeography,
  countyGeographies,
  stateGeography,
  geographyLevel,
  mapStyleStateAbbrev,
  vulnerablePopulations
}: OverviewRiskParams & RouteComponentProps<"history">) => {
  const riskCardCategories: ReadonlyArray<RiskCardDisplay> = [
    {
      cardLabel: "Risk to Homes",
      screen: Screen.RiskToHomes,
      text: "The relative risk to a house for every location on the landscape, whether a house currently exists there or not.",
      riskLevel: detailGeography?.risk_to_homes_class
    },
    {
      cardLabel: "Wildfire Likelihood",
      screen: Screen.WildfireLikelihood,
      text: "The probability of a wildfire burning in any given year.",
      riskLevel: detailGeography?.wildfire_likelihood_class
    },
    {
      cardLabel: "Risk Reduction Zones",
      screen: Screen.RiskReductionZones,
      text: "The relative exposure of homes to wildfires and areas where mitigation may be most effective.",
      riskLevel: detailGeography?.exposure_type_class
    },
    {
      cardLabel: "Vulnerable Populations",
      screen: Screen.VulnerablePopulations,
      text: "People that may be disproportionately impacted by wildfire because of social and economic factors.",
      riskLevel: detailGeography?.vulnerable_populations_class
    }
  ];
  const RiskCards = riskCardCategories.map(obj => {
    return (
      <Link
        to={history.location.pathname.replace(Screen.Overview, obj.screen)}
        style={{ textDecoration: "none" }}
        key={obj.screen}
        onClick={() => {
          logGoogleAnalyticsEvent("overview", "risk card click", obj.cardLabel);
        }}
      >
        <RiskCard>
          <Box
            width={"50%"}
            pad="20px"
            gap="xsmall"
            style={{ overflowX: "auto", overflowY: "auto", flexShrink: 0 }}
          >
            <Box height="94%">
              <Box style={{ minHeight: "25%" }}>
                <RiskIcon riskLevel={obj.riskLevel} />
              </Box>
              <Text
                color="dark-1"
                size="large"
                weight="bold"
                style={{ lineHeight: "1.2em", marginTop: "15px" }}
              >
                {obj.cardLabel}
              </Text>
              <Text color="dark-1" size="medium" style={{ lineHeight: "1.4em", marginTop: "5px" }}>
                {obj.text}
              </Text>
            </Box>
            <Box height="6%" style={{ marginTop: "10px" }}>
              <Box direction="row" gap="xxsmall" align="center">
                <Text color="dark-1" size="medium" weight="bold">
                  {"Learn more"}
                </Text>
                <LearnMoreBracket size="6px" color="#333333" />
              </Box>
            </Box>
          </Box>

          {detailGeography && (
            <Box
              style={{
                width: "50%",
                minHeight: "265px"
              }}
            >
              <Map
                geographyLevel={geographyLevel}
                detailPlaceId={detailGeographyId}
                geography={detailGeography}
                mapStyleStateAbbrev={"US"}
                stateGeography={stateGeography}
                screen={obj.screen}
                showPopulatedAreaMask={false}
                mapContainerId={`map-container-${obj.screen}`}
                displayControlsAndLabels={false}
                vulnerablePopulations={
                  obj.screen === Screen.VulnerablePopulations ? vulnerablePopulations : undefined
                }
              />
            </Box>
          )}
        </RiskCard>
      </Link>
    );
  });

  const ConnectedText = () => {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            height={size === "small" ? "220px" : "320px"}
            background="#3A312C"
            alignSelf="end"
            alignContent="start"
            pad="medium"
            gap="medium"
            style={
              size === "small"
                ? {}
                : {
                    borderRadius: "2px",
                    width: "50%"
                  }
            }
          >
            <Box gap="small">
              <Text
                color="light-1"
                size={makeResponsiveValue(size, ["large", "xlarge"])}
                weight="bold"
              >
                Learn how it's all connected
              </Text>
              <Text color="light-1" size={makeResponsiveValue(size, ["medium", "large"])}>
                For a community to be fire-adapted, we must integrate risk-reduction strategies into
                the design, layout, and development of homes and neighborhoods.
              </Text>
            </Box>
            <Box width={{ max: makeResponsiveValue(size, ["95px", "140px"]) }}>
              <Box background="#0095CB" pad="small" direction="row" align="center">
                <Text
                  color="light-1"
                  size={makeResponsiveValue(size, ["medium", "large"])}
                  weight="bold"
                >
                  Learn more
                </Text>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    );
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <ScreenContainer>
            {"resource" in geography ? (
              <>
                <StyledHeader
                  align={makeResponsiveValue(size, ["start", undefined])}
                  direction={makeResponsiveValue(size, ["column", "row"])}
                  gap="none"
                  pad={makeResponsiveValue(size, [
                    { left: horizontalPadding, right: horizontalPadding, top: "10px", bottom: "0" },
                    {
                      left: horizontalPadding,
                      right: horizontalPadding,
                      top: "10px",
                      bottom: "41px"
                    },
                    { left: "0px", right: "0px", top: "small", bottom: "41px" }
                  ])}
                >
                  <Box
                    className="restrict-growth-content-container"
                    pad={{ top: "small", bottom: "small" }}
                  >
                    <GeographyDescription
                      countyDescNames={
                        countyGeographies ? countyGeographies.map(c => c.name_short) : []
                      }
                      stateDescName={stateGeography ? stateGeography.name : ""}
                      geographyLevel={geographyLevel}
                      detailPlaceId={detailGeographyId}
                      detailPlaceName={detailGeography ? detailGeography.name_short : ""}
                      rpsRank={detailGeography?.ranks?.rps_within_nation}
                      rpsRankClass={detailGeography?.risk_to_homes_class}
                    />
                  </Box>
                </StyledHeader>
                <Box>
                  <Box
                    className="restrict-growth-content-container"
                    margin={size === "small" ? "0px" : "0 auto"}
                    pad={
                      size === "large"
                        ? { left: "0px", right: "0px" }
                        : { left: horizontalPadding, right: horizontalPadding }
                    }
                  >
                    <UnderstandRiskContainer pad={{ top: "medium", bottom: "medium" }} gap="xsmall">
                      <Heading color="dark-1" size="xlarge">
                        Understand your risk
                      </Heading>
                      <Text color="dark-1" size="large" style={{ paddingBottom: "30px" }}>
                        Wildfire risk is based on several factors. Understanding which factors
                        affect your community can help you identify strategies to reduce your risk.
                      </Text>
                      <Grid
                        columns={["auto", "auto"]}
                        gap="small"
                        style={{
                          display: size === "small" ? "flex" : "grid",
                          flexDirection: size === "small" ? "column" : "row"
                        }}
                      >
                        {RiskCards}
                      </Grid>
                    </UnderstandRiskContainer>
                    <ActionCards />
                  </Box>
                  {size === "small" ? (
                    <a href="/reduce-risk">
                      <Box
                        style={{
                          overflowY: "hidden",
                          position: "relative",
                          flexShrink: 0
                        }}
                      >
                        <ConnectedText />
                        <Box
                          height="220px"
                          style={{
                            background: "url(" + process.env.PUBLIC_URL + "/Town.jpg)",
                            backgroundPosition: "center 25%",
                            backgroundSize: "cover"
                          }}
                        />
                      </Box>
                    </a>
                  ) : (
                    <a href="/reduce-risk">
                      <Box
                        height={{ max: "600px" }}
                        style={{
                          overflowX: "hidden",
                          overflowY: "hidden",
                          position: "relative",
                          flexShrink: 0,
                          justifyContent: "center"
                        }}
                      >
                        <Image
                          src={process.env.PUBLIC_URL + "/Town.jpg"}
                          alt="Image of a town experiencing a wildfire"
                        />
                        <Box
                          style={{
                            position: "absolute",
                            alignSelf: "center",
                            width: "1100px"
                          }}
                        >
                          <ConnectedText />
                        </Box>
                      </Box>
                    </a>
                  )}
                </Box>
              </>
            ) : "errorMessage" in geography ? (
              geography.errorMessage
            ) : null}
          </ScreenContainer>
          <AppFooter />
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default withRouter(OverviewScreen);
