import { Anchor, Box, Button, Image, Layer, ResponsiveContext, Stack, Text } from "grommet";
import React from "react";
import styled from "styled-components";

import GeographySearchInput from "../components/GeographySearch";
import AppFooter from "../components/AppFooter";
import ReactPlayer from "react-player";
import { Close, PlayFill } from "grommet-icons";
import { Search } from "grommet-icons";

const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchScreen = () => {
  const [show, setShow] = React.useState(false);

  return (
    <PageDiv>
      <Box
        className="search-screen"
        height={{ min: "580px" }}
        animation={{
          type: "fadeIn",
          duration: 1000
        }}
        style={{
          background: "url(" + process.env.PUBLIC_URL + "/HE_Wildfires_07_22.jpg)",
          backgroundPosition: "center 80%",
          backgroundSize: "cover"
        }}
      >
        <div className="appHeader">
          <div className="appHeaderText">
            <h1>Explore your wildfire risk</h1>
            <p>
              Type the name of your community, tribal area, county, or state to view interactive
              charts and maps about wildfire risk and discover resources to reduce risk.
            </p>
          </div>
        </div>
        <Box id="homeSearchBox">
          <Search height="14" id="homeSearchBoxIcon" />
          <GeographySearchInput />
        </Box>
      </Box>

      <Box
        className="search-screen-lower"
        direction="row"
        wrap={true}
        justify="center"
        margin={{ vertical: "30px" }}
        height="auto"
        animation={{
          type: "fadeIn",
          duration: 1000
        }}
      >
        <Anchor>
          <Box
            round="xxsmall"
            width={{ max: "500px" }}
            overflow="hidden"
            pad="medium"
            onClick={() => setShow(true)}
          >
            <Stack anchor="center">
              <Image src={process.env.PUBLIC_URL + "/WRCdemo.jpg"} fill={true} />
              <Box
                height="90px"
                width="90px"
                background="#3A312C"
                round="45px"
                margin={{ right: "5px" }}
              />
              <PlayFill size="40px" color="light-1" />
            </Stack>
          </Box>
        </Anchor>
        <Box width={{ max: "500px" }} pad="medium">
          <div className="appHeader">
            <div className="appHeaderText">
              <h1>About Wildfire Risk to Communities</h1>
              <p>Watch this one-minute video to learn more about the tool.</p>
            </div>
          </div>
        </Box>
        {show && (
          <ResponsiveContext.Consumer>
            {size => (
              <Layer
                onEsc={() => setShow(false)}
                onClickOutside={() => setShow(false)}
                background="dark-1"
                full={true}
                margin={size === "small" ? "0px" : "30px"}
              >
                <Box round="xsmall" overflow="auto" background="dark-1">
                  <Box direction="row" justify="between" pad="small" height={{ min: "44px" }}>
                    <Text color="light-1">How to use Wildfire Risk to Communities</Text>
                    <Button
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setShow(false);
                        event.stopPropagation();
                      }}
                      plain={true}
                      label={<Close />}
                    />
                  </Box>
                  <div
                    style={{
                      position: "relative",
                      paddingTop:
                        "54.25%" /* Slightly smaller than player ratio: 100 / (1280 / 720) */
                    }}
                  >
                    <ReactPlayer
                      controls={true}
                      width="100%"
                      height="100%"
                      url="https://vimeo.com/642862066"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0"
                      }}
                    />
                  </div>
                </Box>
              </Layer>
            )}
          </ResponsiveContext.Consumer>
        )}
      </Box>
      <AppFooter />
    </PageDiv>
  );
};

export default SearchScreen;
