import React from "react";
import { Box, Heading, Paragraph, ResponsiveContext, Layer, Button, Text } from "grommet";
import { Close } from "grommet-icons";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { setSelectedModalTract } from "../actions/vulnerablePopulations";
import { GeographyVulnerablePopulationData, VulnerablePopulationNameAndData } from "../models";
import VulnerablePopulationsTable from "./VulnerablePopulationsTable";
import store from "../store";

interface StateProps {
  readonly geography: string;
  readonly detailPlaceId: string;
  readonly vulnerablePopulations: VulnerablePopulationsState;
}

const VulnerablePopulationsComponent = (props: StateProps) => {
  const { detailPlaceId, vulnerablePopulations, geography } = props;

  const descriptionText = (
    <Text>
      <Paragraph size="large" color="text" margin={{ top: "0", bottom: "medium" }} fill={true}>
        Social and economic factors can make it more difficult for some people to prepare for,
        respond to, and recover from wildfire. Vulnerable populations may lack access to resources,
        experience cultural and institutional barriers, have limited mobility, or have medical
        conditions exacerbated by stress or smoke.
      </Paragraph>
      <Paragraph size="large" color="text" margin={{ top: "0", bottom: "medium" }} fill={true}>
        For example, people over age 65 and people who are disabled are more susceptible to air
        pollution and particulates associated with wildfire smoke. Language barriers can make it
        difficult to follow directions during an evacuation or to access support after a disaster.
        Race and ethnicity are strongly correlated with disparities in health and access to aid and
        resources. Wildfires disproportionately impact people with low incomes because of factors
        such as inadequate housing and a diminished ability to evacuate or relocate.
      </Paragraph>
    </Text>
  );
  const closeTractModal = () => {
    store.dispatch(setSelectedModalTract(undefined));
  };

  const tractsData =
    "resource" in vulnerablePopulations.tractInfo
      ? vulnerablePopulations.tractInfo.resource.tractInfo.geos
      : undefined;
  const detailPlaceData = (
    tractsData && tractsData[detailPlaceId] ? tractsData[detailPlaceId] : undefined
  ) as GeographyVulnerablePopulationData;
  const modalTract = (
    tractsData && tractsData.tracts
      ? tractsData.tracts[
          vulnerablePopulations.selectedModalTract as keyof typeof tractsData.tracts
        ]
      : undefined
  ) as VulnerablePopulationNameAndData;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box width={size === "small" ? "100%" : "50%"} align="center">
            <Box
              pad={{
                top: size === "small" ? "large" : "medium"
              }}
            >
              <Heading level={2} margin={{ top: "0" }} size="xlarge" color="heading">
                About vulnerable populations
              </Heading>
              {descriptionText}
            </Box>
          </Box>
          <Box width={size === "small" ? "100%" : "50%"} align="center">
            <Box
              fill={true}
              pad={{
                top: size === "small" ? "large" : "medium",
                bottom: "none",
                horizontal: "small"
              }}
              style={{ maxWidth: "500px", overflowX: size === "small" ? "auto" : "initial" }}
            >
              <Box margin={{ bottom: "10px" }}>
                <Heading level={1} size="medium" margin={{ bottom: "0" }}>
                  Vulnerable populations
                </Heading>
                <Text>All areas in {geography}</Text>
              </Box>
              <VulnerablePopulationsTable
                tableData={detailPlaceData}
                detailPlaceId={detailPlaceId}
                mode={size === "small" ? "narrow" : "page"}
              />
              <Paragraph
                margin={{ top: "medium" }}
                color="text"
                size="small"
                style={{ maxWidth: "none" }}
              >
                Data are from the U.S. Census Bureau, American Community Survey. See{" "}
                <a href="/about/methods/">methods</a> for more information.
              </Paragraph>
            </Box>
          </Box>
          {modalTract && !vulnerablePopulations.displayExpandedMenu && (
            <Layer
              onEsc={closeTractModal}
              onClickOutside={closeTractModal}
              animation="fadeIn"
              background={{
                opacity: 0
              }}
            >
              <Box
                fill={true}
                background={{
                  color: "rgba(0,0,0,.2)"
                }}
                onClick={closeTractModal}
                justify="center"
                align="center"
              >
                <Box
                  background="#FFFFFF"
                  onClick={e => e.stopPropagation()}
                  width={{ max: "400px" }}
                  style={{
                    boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "2px"
                  }}
                >
                  <Box pad={{ left: "10px", top: "4px" }}>
                    <Button alignSelf="start" onClick={closeTractModal} color="text">
                      <Close size="14px" />
                      <Text size="15px" margin={{ left: "10px" }} weight={700}>
                        Close this table
                      </Text>
                    </Button>
                  </Box>
                  <Text
                    margin={{ left: "10px", top: "5px", bottom: "8px" }}
                    size="16px"
                    weight={700}
                    color="#000000"
                  >
                    {modalTract.name}
                  </Text>
                  <Box style={{ borderBottom: "1px solid #e9e5e2" }}>
                    <VulnerablePopulationsTable
                      tableData={modalTract.data}
                      detailPlaceId={vulnerablePopulations.selectedModalTract}
                      mode="modal"
                    />
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default VulnerablePopulationsComponent;
