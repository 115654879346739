import { VulnerablePopulationTractInfo, BaseMapLayer, NormalizedTractId } from "../models";

export enum ActionTypes {
  SET_SELECTED_VULNERABLE_POPULATIONS = "SET_SELECTED_VULNERABLE_POPULATIONS",
  VULNERABLE_POPULATIONS_FETCH = "VULNERABLE_POPULATIONS_FETCH",
  VULNERABLE_POPULATIONS_FETCH_SUCCESS = "VULNERABLE_POPULATIONS_FETCH_SUCCESS",
  VULNERABLE_POPULATIONS_FETCH_FAILURE = "VULNERABLE_POPULATIONS_FETCH_FAILURE",
  SET_SELECTED_BASE_MAP_LAYER = "SET_SELECTED_BASE_MAP_LAYER",
  SET_SELECTED_MODAL_TRACT = "SET_SELECTED_MODAL_TRACT",
  SET_SELECTED_PANEL_TRACT = "SET_SELECTED_PANEL_TRACT",
  SET_DISPLAY_EXPANDED_MENU = "SET_DISPLAY_EXPANDED_MENU"
}

// There are separate actions for making the API request and handling both its
// success and failure scenarios
export type VulnerablePopulationsCollectionAction =
  | {
      readonly type: ActionTypes.SET_SELECTED_VULNERABLE_POPULATIONS;
      readonly selectedVulnerablePopulations: ReadonlyArray<string>;
    }
  | {
      readonly type: ActionTypes.VULNERABLE_POPULATIONS_FETCH;
      readonly geoId: string;
    }
  | {
      readonly type: ActionTypes.VULNERABLE_POPULATIONS_FETCH_SUCCESS;
      readonly tractInfo: VulnerablePopulationTractInfo;
    }
  | {
      readonly type: ActionTypes.VULNERABLE_POPULATIONS_FETCH_FAILURE;
      readonly errorMessage: string;
    }
  | {
      readonly type: ActionTypes.SET_SELECTED_BASE_MAP_LAYER;
      readonly selectedBaseMapLayer: BaseMapLayer;
    }
  | {
      readonly type: ActionTypes.SET_SELECTED_MODAL_TRACT;
      readonly selectedModalTract: NormalizedTractId;
    }
  | {
      readonly type: ActionTypes.SET_SELECTED_PANEL_TRACT;
      readonly selectedPanelTract: NormalizedTractId;
    }
  | {
      readonly type: ActionTypes.SET_DISPLAY_EXPANDED_MENU;
      readonly displayExpandedMenu: boolean;
    };

export function setSelectedVulnerablePopulations(
  selectedVulnerablePopulations: ReadonlyArray<string>
): VulnerablePopulationsCollectionAction {
  return {
    type: ActionTypes.SET_SELECTED_VULNERABLE_POPULATIONS,
    selectedVulnerablePopulations
  };
}

export function vulnerablePopulationsFetch(geoId: string): VulnerablePopulationsCollectionAction {
  return {
    geoId,
    type: ActionTypes.VULNERABLE_POPULATIONS_FETCH
  };
}

export function vulnerablePopulationsFetchSuccess(
  tractInfo: VulnerablePopulationTractInfo
): VulnerablePopulationsCollectionAction {
  return { tractInfo, type: ActionTypes.VULNERABLE_POPULATIONS_FETCH_SUCCESS };
}

export function vulnerablePopulationsFetchFailure(
  errorMessage: string
): VulnerablePopulationsCollectionAction {
  return { errorMessage, type: ActionTypes.VULNERABLE_POPULATIONS_FETCH_FAILURE };
}
export function setSelectedBaseMapLayer(
  selectedBaseMapLayer: BaseMapLayer
): VulnerablePopulationsCollectionAction {
  return {
    type: ActionTypes.SET_SELECTED_BASE_MAP_LAYER,
    selectedBaseMapLayer
  };
}

export function setSelectedModalTract(
  selectedModalTract: NormalizedTractId
): VulnerablePopulationsCollectionAction {
  return {
    type: ActionTypes.SET_SELECTED_MODAL_TRACT,
    selectedModalTract
  };
}

export function setSelectedPanelTract(
  selectedPanelTract: NormalizedTractId
): VulnerablePopulationsCollectionAction {
  return {
    type: ActionTypes.SET_SELECTED_PANEL_TRACT,
    selectedPanelTract
  };
}

export function setDisplayExpandedMenu(
  displayExpandedMenu: boolean
): VulnerablePopulationsCollectionAction {
  return {
    type: ActionTypes.SET_DISPLAY_EXPANDED_MENU,
    displayExpandedMenu
  };
}
