import React from "react";
import { Box } from "grommet";
import { RiskClassLevel } from "../models";
import { riskFillColors } from "../constants";

interface Props {
  readonly riskLevel: RiskClassLevel | undefined;
}

const RiskIcon = ({ riskLevel }: Props) => {
  if (!riskLevel) {
    return <></>;
  }

  const grayFill = "#E0E0E0";
  const riskFill = riskFillColors[riskLevel];
  return (
    <Box className="risk-icon" a11yTitle={`Risk level icon: ${riskLevel}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82">
        <g fill="none" fillRule="evenodd">
          <path
            d="M30.9836 0V3.00182C45.1002 3.51579 56.4515 14.8671 56.9655 28.9836H59.9673C59.4507 13.21 46.7573 0.516614 30.9836 0Z"
            id="NE segment"
            fill={riskFill}
          />
          <path
            d="M59.9673 31H56.9655C56.4515 45.1166 45.1002 56.4679 30.9836 56.9818V59.9836C46.7573 59.467 59.4507 46.7736 59.9673 31Z"
            id="SE segment"
            fill={riskLevel === RiskClassLevel.Low ? grayFill : riskFill}
          />
          <path
            d="M28.9836 59.9836V56.9818C14.8671 56.4679 3.51579 45.1166 3.00182 31H0C0.516614 46.7736 13.21 59.467 28.9836 59.9836Z"
            id="SW segment"
            fill={
              riskLevel === RiskClassLevel.Low || riskLevel === RiskClassLevel.Medium
                ? grayFill
                : riskFill
            }
          />
          <path
            d="M0 29H3.00182C3.51579 14.8834 14.8671 3.53215 28.9836 3.01818V0.0163574C13.21 0.532972 0.516614 13.2264 0 29Z"
            id="NW segment"
            fill={riskLevel === RiskClassLevel.VeryHigh ? riskFill : grayFill}
          />
        </g>
        <text
          x="38%"
          y="44%"
          fontSize="medium"
          text-align="center"
          textAnchor="middle"
          stroke={riskFill}
          fill={riskFill}
        >
          {
            {
              [RiskClassLevel.Low]: "Low",
              [RiskClassLevel.Medium]: "Med",
              [RiskClassLevel.High]: "High",
              [RiskClassLevel.VeryHigh]: (
                <>
                  <tspan y="25">Very</tspan>
                  <tspan x="30" y="45">
                    High
                  </tspan>
                </>
              )
            }[riskLevel]
          }
        </text>
      </svg>
    </Box>
  );
};

export default RiskIcon;
