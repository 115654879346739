import { riskToHomesColors } from "../models";

export const defaultMapOptions: Partial<mapboxgl.MapboxOptions> = {
  style: "mapbox://styles/headmin/clmjaigty03z601phgs95ayyn",
  dragRotate: false,
  touchZoomRotate: false,
  minZoom: 2,
  maxZoom: 12,
  // The map should load straight to the selected geography's bounding box, hopefully
  // without flashing the defaults, but the map needs some boundary to initialize, so this is
  // a rough box around all 50 states plus PR.
  bounds: [-125, 24, -66, 50]
};

export const miniMapOptions: Partial<mapboxgl.MapboxOptions> = {
  style: "mapbox://styles/headmin/clprhb8ka008z01qm2fe8d5nw",
  dragRotate: false,
  touchZoomRotate: false,
  bounds: [-125, 24, -66, 50],
  interactive: false
};

export const defaultLineOptions: Partial<mapboxgl.LineLayer> = {
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round"
  }
};

export const populatedAreasMaskStyles: Partial<mapboxgl.Layer> = {
  paint: {
    "raster-opacity": 1
  }
};

const RPS_ATTR = "RPS";
// Since the polygonization process required reclassifying the raster into integer values,
// the attributes in the tileset are 1,000,000 times their original values. So we need a
// multiplier to scale the breakpoints to match the tile attributes.
const RPS_MULTIPLIER = 1000000;
const RPS_PERCENTILES: { readonly [state: string]: ReadonlyArray<number> } = {
  // 40, 70, 90, and 95th percentiles. No need to define the 100th, we can just use a high number.
  AK: [0.009035, 0.076778, 0.442008, 0.790832],
  AL: [0.015694, 0.036439, 0.077807, 0.104582],
  AR: [0.01498, 0.035342, 0.060194, 0.079468],
  AZ: [0.026487, 0.164202, 0.574207, 0.94877],
  CA: [0.067132, 0.347076, 1.42156, 2.342241],
  CO: [0.036024, 0.103113, 0.284198, 0.455023],
  CT: [0.002144, 0.003749, 0.006116, 0.007584],
  DC: [0.000767, 0.001237, 0.001899, 0.00235],
  DE: [0.003611, 0.009676, 0.024968, 0.039866],
  FL: [0.062901, 0.184653, 0.71319, 1.767818],
  GA: [0.018789, 0.029754, 0.048654, 0.066053],
  HI: [0.076648, 0.185043, 0.381522, 0.564761],
  IA: [0.006807, 0.0269, 0.040836, 0.05029],
  ID: [0.186729, 0.594117, 1.352063, 1.97868],
  IL: [0.000867, 0.002352, 0.005011, 0.006672],
  IN: [0.000784, 0.001303, 0.002111, 0.003078],
  KS: [0.01561, 0.063554, 0.35313, 0.568965],
  KY: [0.00271, 0.013653, 0.140543, 0.184335],
  LA: [0.006728, 0.020579, 0.082588, 0.156838],
  MA: [0.001052, 0.001736, 0.003449, 0.004767],
  MD: [0.002156, 0.009227, 0.034815, 0.077772],
  ME: [0.000381, 0.000579, 0.001092, 0.001743],
  MI: [0.001059, 0.002959, 0.013607, 0.029419],
  MN: [0.004835, 0.019479, 0.132379, 0.279142],
  MO: [0.018389, 0.029635, 0.043198, 0.052512],
  MS: [0.019893, 0.039307, 0.096241, 0.148781],
  MT: [0.055921, 0.170348, 0.418051, 0.600166],
  NC: [0.007467, 0.024736, 0.071623, 0.115808],
  ND: [0.010076, 0.026902, 0.077239, 0.104383],
  NE: [0.035802, 0.065313, 0.141841, 0.214824],
  NH: [0.000651, 0.00092, 0.001563, 0.002283],
  NJ: [0.005283, 0.040228, 0.208843, 0.456359],
  NM: [0.036622, 0.148149, 0.529983, 0.855108],
  NV: [0.062915, 0.303154, 0.916979, 1.582458],
  NY: [0.000511, 0.001118, 0.003064, 0.004899],
  OH: [0.002115, 0.003858, 0.006357, 0.008999],
  OK: [0.118331, 0.267065, 0.494106, 0.607284],
  OR: [0.131842, 0.332069, 0.732055, 1.103548],
  PA: [0.002623, 0.006187, 0.013329, 0.017862],
  RI: [0.000897, 0.001864, 0.003365, 0.004263],
  SC: [0.020805, 0.035788, 0.078052, 0.123994],
  SD: [0.034642, 0.063705, 0.174573, 0.251025],
  TN: [0.006677, 0.030753, 0.066143, 0.089314],
  TX: [0.043763, 0.143207, 0.464479, 0.661045],
  UT: [0.027163, 0.155173, 0.624531, 1.168664],
  VA: [0.005442, 0.018212, 0.050383, 0.083019],
  VT: [0.000634, 0.00081, 0.001081, 0.001316],
  WA: [0.044575, 0.3491, 1.086698, 1.828759],
  WI: [0.001045, 0.002408, 0.005088, 0.007862],
  WV: [0.007858, 0.024671, 0.120788, 0.178878],
  WY: [0.037172, 0.092338, 0.21749, 0.342033],
  US: [0.018848, 0.094043, 0.408197, 0.722298]
};

const rpsColors = riskToHomesColors.map(c => c.value);

// Composes step function, to be set as the `paint.fill-color` property on a layer, that assigns
// the colors from the wireframes to the percentiles for the selected state.
// See https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#step
export const getRPSFillSteps = (state: string) => {
  const steps = RPS_PERCENTILES[state].flatMap((breakpoint: number, idx: number) => [
    breakpoint * RPS_MULTIPLIER,
    rpsColors[idx + 1]
  ]);
  return ["step", ["get", RPS_ATTR], rpsColors[0], ...steps];
};

// This is Mapbox Studio layer "community-labels".
// To make changes, update it in Studio and copy the updated JSON here
export const communityLabelStyles: Partial<mapboxgl.SymbolLayer> = {
  minzoom: 4,
  layout: {
    "text-font": [
      "match",
      ["get", "scalerank"],
      [1, 0, 2, 3, 4, 6, 7, 8],
      ["literal", ["Lato Bold", "Arial Unicode MS Regular"]],
      ["literal", ["Lato Regular", "Arial Unicode MS Regular"]]
    ],
    "text-anchor": "bottom",
    "text-offset": ["step", ["zoom"], ["literal", [0, -0.3]], 6, ["literal", [0, 0]]],
    "text-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      0,
      [
        "case",
        ["match", ["get", "scalerank"], [0, 1], true, false],
        13,
        ["match", ["get", "scalerank"], [2, 3], true, false],
        13,
        ["match", ["get", "scalerank"], [4, 6], true, false],
        13,
        ["match", ["get", "scalerank"], [7, 8], true, false],
        13,
        ["==", ["get", "scalerank"], 0],
        10,
        10
      ],
      4,
      [
        "case",
        ["match", ["get", "scalerank"], [0, 1], true, false],
        13,
        ["match", ["get", "scalerank"], [2, 3], true, false],
        13,
        ["match", ["get", "scalerank"], [4, 6], true, false],
        13,
        ["match", ["get", "scalerank"], [7, 8], true, false],
        13,
        ["==", ["get", "scalerank"], 0],
        10,
        8
      ],
      10,
      [
        "case",
        ["match", ["get", "scalerank"], [0, 1], true, false],
        21,
        ["match", ["get", "scalerank"], [2, 3], true, false],
        18,
        ["==", ["get", "scalerank"], 0],
        10,
        ["match", ["get", "scalerank"], [4, 6], true, false],
        16,
        ["match", ["get", "scalerank"], [7, 8], true, false],
        14,
        13
      ]
    ],
    "symbol-sort-key": [
      "match",
      ["get", "scalerank"],
      [0],
      0,
      [1],
      1,
      [2],
      2,
      [3],
      3,
      [4],
      4,
      [6],
      6,
      [7],
      7,
      [8],
      8,
      9
    ],
    "text-field": [
      "step",
      ["zoom"],
      ["case", ["==", ["get", "scalerank"], 0], "", ""],
      4,
      ["match", ["get", "scalerank"], [1, 0], ["to-string", ["get", "name"]], ""],
      5,
      [
        "match",
        ["get", "scalerank"],
        [1, 0],
        ["to-string", ["get", "name"]],
        [2, 3],
        ["to-string", ["get", "name"]],
        ""
      ],
      6,
      [
        "match",
        ["get", "scalerank"],
        [1, 0],
        ["to-string", ["get", "name"]],
        [2, 3],
        ["to-string", ["get", "name"]],
        [4, 6],
        ["to-string", ["get", "name"]],
        ""
      ],
      7,
      [
        "match",
        ["get", "scalerank"],
        [1, 0],
        ["to-string", ["get", "name"]],
        [2, 3],
        ["to-string", ["get", "name"]],
        [4, 6],
        ["to-string", ["get", "name"]],
        [7, 8],
        ["to-string", ["get", "name"]],
        ""
      ],
      8,
      [
        "match",
        ["get", "scalerank"],
        [1, 0],
        ["to-string", ["get", "name"]],
        [2, 3],
        ["to-string", ["get", "name"]],
        [4, 6],
        ["to-string", ["get", "name"]],
        [7, 8],
        ["to-string", ["get", "name"]],
        ""
      ],
      9,
      [
        "match",
        ["get", "scalerank"],
        [1, 0],
        ["to-string", ["get", "name"]],
        [2, 3],
        ["to-string", ["get", "name"]],
        [4, 6],
        ["to-string", ["get", "name"]],
        [7, 8],
        ["to-string", ["get", "name"]],
        ["to-string", ["get", "name"]]
      ]
    ]
  },
  paint: {
    "text-halo-color": "hsl(0, 0%, 100%)",
    "text-halo-width": 1.5
  }
};

// This is Mapbox Studio layer "tribal-labels".
// To make changes, update it in Studio and copy the updated JSON here
export const tribalLabelStyles: Partial<mapboxgl.SymbolLayer> = {
  minzoom: 7,
  layout: {
    "text-size": ["interpolate", ["linear"], ["zoom"], 7, 12, 10, 14],
    "text-font": ["Lato Regular", "Arial Unicode MS Regular"],
    "text-field": ["to-string", ["get", "name"]]
  },
  paint: {
    "text-halo-color": "hsl(0, 0%, 100%)",
    "text-halo-width": 1.5
  }
};

// This is Mapbox Studio layer "county-labels".
// To make changes, update it in Studio and copy the updated JSON here
export const countyLabelStyles: Partial<mapboxgl.SymbolLayer> = {
  minzoom: 7,
  maxzoom: 9,
  paint: {
    "text-halo-color": "hsl(220, 0%, 100%)",
    "text-halo-width": 1,
    "text-color": "hsl(46, 0%, 0%)",
    "text-opacity": 0.9
  },
  layout: {
    "text-line-height": 1.1,
    "text-size": ["interpolate", ["linear"], ["zoom"], 6, 9, 9, 16],
    "text-transform": "uppercase",
    "text-font": ["Lato Regular", "Arial Unicode MS Regular"],
    "text-padding": 3,
    "text-field": ["slice", ["get", "name"], 0, ["-", 4]],
    "text-letter-spacing": ["match", ["get", "type"], "suburb", 0.15, 0.05],
    "text-max-width": 7
  }
};

// This is Mapbox Studio layer "state-labels".
// To make changes, update it in Studio and copy the updated JSON here
export const stateLabelStyles: Partial<mapboxgl.SymbolLayer> = {
  minzoom: 3,
  maxzoom: 9,
  layout: {
    "text-size": [
      "interpolate",
      ["cubic-bezier", 0.85, 0.7, 0.65, 1],
      ["zoom"],
      4,
      ["step", ["get", "symbolrank"], 10, 6, 9.5, 7, 9],
      9,
      ["step", ["get", "symbolrank"], 21, 6, 16, 7, 13]
    ],
    "text-transform": "uppercase",
    "text-font": ["Lato Bold", "Arial Unicode MS Bold"],
    "text-field": [
      "step",
      ["zoom"],
      [
        "step",
        ["get", "symbolrank"],
        ["coalesce", ["get", "name_en"], ["get", "name"]],
        5,
        ["coalesce", ["get", "abbr"], ["get", "name_en"], ["get", "name"]]
      ],
      5,
      ["coalesce", ["get", "name_en"], ["get", "name"]]
    ],
    "text-letter-spacing": 0.15,
    "text-max-width": 6
  },
  filter: [
    "all",
    [
      "match",
      ["get", "class"],
      ["state", "disputed_state"],
      ["match", ["get", "worldview"], ["all", "US"], true, false],
      false
    ],
    ["match", ["get", "iso_3166_1"], ["US"], true, false]
  ],
  paint: {
    "text-color": "hsl(0, 0%, 0%)",
    "text-halo-color": "hsl(185, 1%, 100%)",
    "text-halo-width": 1.5,
    "text-opacity": 0.9
  }
};
