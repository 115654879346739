import React from "react";
import { Box, Grid, Grommet, Text } from "grommet";
import CustomGrommetTheme from "../grommet-theme";
import { createRoot } from "react-dom/client";
import { ReactIControl } from "./Map";
import { RampLegend, ClassLegend } from "../models";
import styled from "styled-components";
import MapClassLegend from "./MapClassLegend";

const LegendHeading = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: #555555;
`;

const LegendText = styled(Text)`
  font-weight: 400;
  font-size: 12px;
  color: #666666;
`;

// Not all legends will be present at all times, so they're all optional
export interface MapLegendComponentProps {
  readonly rampLegend?: RampLegend;
  readonly classLegend?: ClassLegend;
  readonly isVulPopNarrowScreen?: boolean;
  readonly noPad?: boolean;
  readonly noLabel?: boolean;
  readonly compact?: boolean;
}

export const MapLegendComponent = (props: MapLegendComponentProps) => {
  const { rampLegend, classLegend, noPad, noLabel, compact } = props;

  return (
    <Grommet theme={CustomGrommetTheme} style={{ backgroundColor: "white", borderRadius: "4px" }}>
      {rampLegend && (
        <Box pad={noPad ? { top: "xsmall" } : "xsmall"}>
          {!noLabel && (
            <LegendHeading style={{ marginBottom: "-5px" }}>{rampLegend.legendLabel}</LegendHeading>
          )}
          <Box
            direction="row"
            style={{ height: "10px", marginTop: "14px", marginBottom: "6px", minWidth: "120px" }}
            fill={true}
            border={true}
            margin={{ top: "small" }}
          >
            <Grid
              rows={["fill"]}
              columns={{ count: rampLegend.colors.length, size: "auto" }}
              fill={true}
              gap="none"
            >
              {rampLegend.colors.map((color, idx) => (
                <Box key={idx} background={color} />
              ))}
            </Grid>
          </Box>
          <Grid rows={["fill"]} columns={["1/2", "1/2"]} gap="none">
            <Box direction="row">
              <LegendText>{rampLegend.lowLabel}</LegendText>
            </Box>
            <Box direction="row" justify="end">
              <LegendText>{rampLegend.highLabel}</LegendText>
            </Box>
          </Grid>
        </Box>
      )}
      {classLegend && <MapClassLegend classes={classLegend.classes} compact={compact || false} />}
    </Grommet>
  );
};

const MapLegend = (el: HTMLDivElement, props: MapLegendComponentProps): ReactIControl => {
  const root = createRoot(el);
  return {
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      root.render(<MapLegendComponent {...props} />);
      return el;
    },
    onRemove: () => {
      root.render(undefined);
    },
    // This is not part of the IControl interface; this is here so we can re-render when the props
    // change.
    render: (props: MapLegendComponentProps) => {
      // the legend is given a larger top margin if the vulnerable populations map is on a narrow
      // screen to avoid collision with the vulnerable populations widget
      /* tslint:disable:no-object-mutation */
      props.isVulPopNarrowScreen ? (el.style.marginTop = "50px") : (el.style.marginTop = "10px");
      /* tslint:enable:no-object-mutation */
      root.render(<MapLegendComponent {...props} />);
      return el;
    }
  };
};

export default MapLegend;
