import { Button } from "grommet";
import React from "react";
import { createRoot } from "react-dom/client";
import { State } from "../reducers";
import { connect, Provider } from "react-redux";

import store from "../store";
import { setPopulatedAreaMaskVisibility } from "../actions/populatedAreaMask";

interface StateProps {
  readonly showPopulatedAreaMask: boolean;
}

// This is just a regular function component, there's nothing fancy going on here.
const ToggleControl = ({ showPopulatedAreaMask }: StateProps) => {
  const showAllAreas = () => {
    store.dispatch(setPopulatedAreaMaskVisibility(false));
  };

  const showPopulatedOnly = () => {
    store.dispatch(setPopulatedAreaMaskVisibility(true));
  };

  return (
    <>
      <Button
        onClick={showAllAreas}
        className={showPopulatedAreaMask ? "toggle-deselected" : "toggle-selected"}
      >
        Show all lands
      </Button>
      <Button
        onClick={showPopulatedOnly}
        className={showPopulatedAreaMask ? "toggle-selected" : "toggle-deselected"}
      >
        Show populated areas only
      </Button>
    </>
  );
};

// We wouldn't normally connect such a small component directly to the Redux State, but in this
// case, because the props are being passed in via useEffect() and ReactDOM.render, it makes more
// sense to get showPopulatedAreaMask from the Redux State, because otherwise we would have to put a
// dependency in the calling useEffect on the showPopulatedAreaMask prop. That useEffect() is
// intended as an initialization stage that runs only once, so it wouldn't be ideal to have it
// re-execute whenever someone toggled the populated areas layer. Doing this allows the toggle to
// receive the information it needs directly from the state, so once it has been added to the map,
// it can continue to update independently of the functions which were used to construct it.
const mapStateToProps = (state: State): StateProps => ({
  showPopulatedAreaMask: state.populatedAreaMask
});

const ToggleControlComponent = connect(mapStateToProps)(ToggleControl);

// This is NOT a React Component. It has been structured to mimic the shape of this project's React
// Components to some extent, but it is in fact a wrapper to provide an IControl interface, which is
// Mapbox GL JS's interface for adding new map controls, around the ToggleControlComponent defined
// above.  The general approach was drawn from:
// https://reactjs.org/docs/integrating-with-other-libraries.html#replacing-string-based-rendering-with-react
const PopulatedAreaMaskToggleControl = (el: HTMLDivElement): mapboxgl.IControl => {
  const root = createRoot(el);
  return {
    /* tslint:disable:no-object-mutation */
    onAdd: (map: mapboxgl.Map): HTMLDivElement => {
      // Reset display in case it was hidden earlier by onRemove
      el.style.display = "";
      root.render(
        <Provider store={store}>
          <ToggleControlComponent />
        </Provider>
      );
      return el;
    },

    onRemove: () => {
      // Hide the element completely so it doesn't affect the spacing of other controls
      el.style.display = "none";
      root.render(undefined);
    }
  };
};

export default PopulatedAreaMaskToggleControl;
