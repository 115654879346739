import { Box, Grid, Text } from "grommet";
import React, { useMemo } from "react";

interface StateProps {
  readonly colors: ReadonlyArray<string>;
  readonly colorsLabel: string;
  readonly minBubbleSize: number;
  readonly maxBubbleSize: number;
}

const ChartLegends = (props: StateProps) => {
  const { colors, colorsLabel, minBubbleSize, maxBubbleSize } = props;

  const bubbleLegendSizes: ReadonlyArray<number> = useMemo(() => {
    // Show four bubbles, with the sizes of the middle two interpolated between the min and max
    const sizeIncrement = Math.floor((maxBubbleSize - minBubbleSize) / 3);
    return [
      minBubbleSize,
      minBubbleSize + sizeIncrement,
      maxBubbleSize - sizeIncrement,
      maxBubbleSize
    ];
  }, [minBubbleSize, maxBubbleSize]);

  return (
    <Grid columns={["140px", "100px"]} gap="large">
      <Box>
        <Text textAlign="start" weight="bold" style={{ marginBottom: "-5px" }}>
          {colorsLabel}
        </Text>
        <Box
          direction="row"
          style={{ height: "7px", marginTop: "14px", marginBottom: "6px" }}
          fill={true}
          border={true}
          margin={{ top: "small" }}
        >
          <Grid
            rows={["fill"]}
            columns={{ count: colors.length, size: "auto" }}
            fill={true}
            gap="none"
          >
            {colors.map((color, idx) => (
              <Box key={idx} background={color} />
            ))}
          </Grid>
        </Box>
        <Grid rows={["fill"]} columns={["1/4", "1/2", "1/4"]} gap="none">
          <Box direction="row">
            <Text size="small">Lower</Text>
          </Box>
          <Box direction="row" />
          <Box direction="row" justify="end">
            <Text size="small">Higher</Text>
          </Box>
        </Grid>
      </Box>
      <Box>
        <Text textAlign="start" weight="bold" style={{ marginBottom: "-5px" }}>
          Population
        </Text>
        <Box direction="row" alignSelf="start" alignContent="center" height="27px">
          {bubbleLegendSizes.map((size, idx) => (
            <div
              key={idx}
              style={{
                border: "solid 1px #555556",
                borderRadius: 20,
                flexShrink: 0,
                width: size,
                height: size,
                marginLeft: 0,
                marginRight: 15,
                marginTop: (maxBubbleSize - size) / 2 + 8
              }}
            />
          ))}
        </Box>
        <Box direction="row" alignSelf="center">
          <Text size="small">Lower</Text>
          <Box width="xsmall" />
          <Text size="small">Higher</Text>
        </Box>
      </Box>
    </Grid>
  );
};

export default ChartLegends;
